import React from 'react';
import { SvgIconInterface } from '../AeroLogo';

const DeleteIcon = ({ fill, height, width}: SvgIconInterface) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <path fill={fill} d="M17 8a1 1 0 011 1v10a3 3 0 01-3 3H9a3 3 0 01-3-3V9a1 1 0 011-1h10zm-1 2H8v9a1 1 0 001 1h6a1 1 0 001-1v-9zM9 3a1 1 0 011-1h4a1 1 0 011 1v1h4a1 1 0 010 2H5a1 1 0 110-2h4V3z"/>
  </svg>
)

export default DeleteIcon;
