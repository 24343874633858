// Array containing all 50 states and DC
export const states = [
  'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 
  'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 
  'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 
  'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 
  'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 
  'VA', 'VT', 'WA', 'WI', 'WV', 'WY',
]

// Interface for the address object
export interface IAddress {
  zipCode?: string;
  city?: string;
  state?: string;
  addressLine1?: string;
  addressLine2?: string;
}
