import API from './aero-api';
import { API_NAMES } from './api-names';

/**
 * Gets payment sources for a patient.
 * @param patientId id of the patient to get payment sources for.
 */
export async function addPaymentSource(patientId: string, customerId: string, paymentSourceToken: string) {
  const path = `/v1/billings/patients/${patientId}/paymentSources`;
  const myInit = {
    body: { customerId, paymentSourceToken, isDefaultSource: true },
  }
  const response = await API.post(API_NAMES.BILLINGS, path, myInit);
  return response;
}

/**
 * Gets payment sources for a patient.
 * @param patientId id of the patient to get payment sources for.
 */
export async function deletePaymentSource(patientId: string, paymentSourceId: string) {
  const path = `/v1/billings/patients/${patientId}/paymentSources/${paymentSourceId}`;
  const response = await API.del(API_NAMES.BILLINGS, path, {});
  return response;
}

/**
 * Adds payment source for company. It's going to set this source as the default.
 * @param companyId id of the company to add a payment source for.
 */
export async function addCompanyPaymentSource(companyId: string, customerId: string, paymentSourceToken: string) {
  const path = `/v1/billings/companies/${companyId}/paymentSources`;
  const myInit = {
    body: { customerId, paymentSourceToken, isDefaultSource: true },
  }
  const response = await API.post(API_NAMES.BILLINGS, path, myInit);
  return response;
}

/**
 * Exchange Plaid public token for a stripe token.
 * @param entityId
 */
export async function exchangePlaidLink(entityId: string, plaidPublicKey: string, accountId: string) {
  const path = `/v1/billings/plaid/${entityId}/link/token?plaidPublicKey=${plaidPublicKey}&accountId=${accountId}`;
  const response = await API.get(API_NAMES.BILLINGS, path, null);
  return response.accountToken;
}

/**
 * Gets a plaid link so that a patient can link their bank account information.
 * @param practiceId
 * @param patientId
 */
export async function getPlaidLink(patientId: string, practiceId?: string, language?: string) {
  const path = `/v1/billings/plaid/${patientId}/link/token?practiceId=${practiceId}${language && language !== 'default' ? `&language=${language}` : ''}`;
  const response = await API.get(API_NAMES.BILLINGS, path, null);
  return response.linkToken;
}

/**
 * Used to create a stripe subscription for a patient; as well as any patients
 * @param patientId patient id for the patient you are creating a subscription for
 */
export async function createPatientSubscription(patientId: string) {
  const path = `/v1/billings/subscriptions/patients/${patientId}`;
  if (!patientId) throw new Error('Patient Id is required in createPatientSubscription');

  const response = await API.post(API_NAMES.BILLINGS, path, {});
  console.log(response);
  return response;
}
