import React from "react";
import { Row } from "react-bootstrap";

interface NewPatientHeadingProps {
  heading?: string;
  withLine?: boolean;
}

export const NewPatientHeading = ({ heading, withLine }: NewPatientHeadingProps) => (
  <>
    {withLine && <span style={{ marginTop: 30 }} className={'k-form-separator'} />}
    <Row style={{ paddingTop: withLine ? 18 : 30 }}>
      <h5>{heading}</h5>
    </Row>
  </>
)
