import React from 'react';
import { ReactComponent as Looking } from '../assets/magnifying-glass.svg';

const AeroError = (props: any) => {
  const itemNotFound = props.error.response?.status === 404;

  let displayMsg = itemNotFound ? 'No data found' : props.error?.toString && props.error?.toString();
  return (
    <div className='d-flex justify-content-center align-items-center flex-column'>
      <h2>{displayMsg}</h2>
      {itemNotFound && <Looking style={{width: 300}} />}
    </div>
  );
}

export default AeroError;
