import { selectors } from '@keenhp/website-common';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn, GridItemChangeEvent, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import React from 'react';
import { SupportedLanguages } from '../../hooks/use-language';
import { signup } from '../../intl/signup';
import { EditGridMoYearDateInput } from '../EditGridDateInput';
import { NewPatientHeading } from './NewPatientHeading';


const defaultData = { diagnosis: '', date: undefined, description: '' };
const defaultSurgery = { diagnosis: 'Surgery', date: undefined, description: '' };

export const medicalHistoryField = 'medicalHistory';

export const NewPatientMedicalHistory = ({ disabled, ...formRenderProps }: any) => {
  // form "state"
  const data: any[] = formRenderProps.valueGetter(medicalHistoryField) || [];
  const setData = (value: any) => formRenderProps.onChange(medicalHistoryField, { value });

  const addDiagnosis = (e: any) => {
    e.preventDefault();
    setData([...data, {
      ...defaultData,
    }]);
  }

  const addSurgery = (e: any) => {
    e.preventDefault();
    setData([...data, {
      ...defaultSurgery,
    }]);
  }

  const itemChange = (isSurgery: boolean, event: GridItemChangeEvent) => {
    if (isSurgery) {
      const inEditID = event.dataItem.index;
      const newData = surgeries.map((item, index) =>
        index === inEditID ? { ...item, [event.field!]: event.value } : item
      );
      setData([...diagnoses, ...newData]);
    } else {
      const inEditID = event.dataItem.index;
      const newData = diagnoses.map((item, index) =>
        index === inEditID ? { ...item, [event.field!]: event.value } : item
      );
      setData([...newData, ...surgeries]);
    }
  }
  const removeItem = (e: any, index: number, isSurgery: boolean) => {
    if (isSurgery) {
      e.preventDefault();
      surgeries.splice(index, 1);
      setData([...diagnoses, ...surgeries]);
    } else {
      e.preventDefault();
      diagnoses.splice(index, 1);
      setData([...diagnoses, ...surgeries]);
    }
  }

  const surgeries: any[] = [];
  const diagnoses: any[] = [];
  data.forEach(x => x.diagnosis === 'Surgery' ? surgeries.push(x) : diagnoses.push(x));

  const language = formRenderProps.valueGetter('language') as SupportedLanguages;
  return (
    <div className="mobile-grid-wrapper">
      <NewPatientHeading heading={signup.personalMedHistory[language]} />
      <Grid
        className={selectors.patients.diagnosisGridClass}
        data={diagnoses.map((item, index) => ({ ...item, inEdit: true && !disabled, index }))}
        editField="inEdit"
        onItemChange={e => itemChange(false, e)}
      >
        <GridNoRecords>
          <span>{signup.noRecords[language]}</span>
        </GridNoRecords>
        <GridToolbar>
          <Button onClick={addDiagnosis} icon="plus" disabled={disabled} data-test={selectors.patients.addDiagnosisBtn} >
            {signup.addRow[language]}
          </Button>
        </GridToolbar>
        <GridColumn field="diagnosis" title={signup.medicalHistory.diagnosis[language]} editable={!disabled} editor="text" />
        <GridColumn field="date" title={signup.date[language]} editable={!disabled} cell={EditGridMoYearDateInput} />
        <GridColumn field="description" title={signup.medicalHistory.description[language]} editor="text" editable={!disabled} />
        {/* column for X to remove row */}
        {!disabled && <GridColumn editable={false} width={80} cell={props => <td>
          <Button onClick={(e) => removeItem(e, props.dataIndex, false)} icon="trash" look="flat" className="text-danger" disabled={disabled} data-test={selectors.common.editGridRemoveBtn} />
        </td>} />}
      </Grid>
      <NewPatientHeading heading={signup.personalSurgicalHistory[language]} />
      <Grid
        className={selectors.patients.surgeryGridClass}
        data={surgeries.map((item, index) => ({ ...item, inEdit: true && !disabled, index }))}
        editField="inEdit"
        onItemChange={e => itemChange(true, e)}
      >
        <GridNoRecords>
          <span>{signup.noRecords[language]}</span>
        </GridNoRecords>
        <GridToolbar>
          <Button onClick={addSurgery} icon="plus" disabled={disabled} data-test={selectors.patients.addSurgeryBtn}>
            {signup.addRow[language]}
          </Button>
        </GridToolbar>
        <GridColumn field="description" title={signup.medicalHistory.surgery[language]} editor="text" editable={!disabled} />
        <GridColumn field="date" title={signup.date[language]} editable={!disabled} cell={EditGridMoYearDateInput} />
        {/* <GridColumn field="diagnosis" title="Surgeries" editable={false} /> */}
        {/* column for X to remove row */}
        {!disabled && <GridColumn editable={false} width={80} cell={props => <td>
          <Button onClick={(e) => removeItem(e, props.dataIndex, true)} icon="trash" look="flat" className="text-danger" disabled={disabled} />
        </td>} />}
      </Grid>
    </div>
  );
}
