import { selectors } from '@keenhp/website-common';
import { Field, FormRenderProps } from '@progress/kendo-react-form';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { SupportedLanguages } from '../../hooks/use-language';
import { signup } from '../../intl/signup';
import { FormInput, FormMaskedTextBox, FormNumericTextBox, FormRadioGroup, FormSwitch } from '../FormComponents';

export const NewPatientSocialHistory = (formProps: FormRenderProps & { disabled?: boolean }) => {
  const language: SupportedLanguages = formProps.valueGetter('language');

  const relationshipStatusData = [
    { label: signup.socialHistory.single[language], value: 'SINGLE' },
    { label: signup.socialHistory.married[language], value: 'MARRIED' },
    { label: signup.socialHistory.divorced[language], value: 'DIVORCED' },
    { label: signup.socialHistory.relationship[language], value: 'RELATIONSHIP' },
    { label: signup.socialHistory.complicated[language], value: 'COMPLICATED' }
  ];

  const yesNoQuestions = [
    selectors.patients.socialSubstancesAlcoholId,
    selectors.patients.socialSubstancesIllicitDrugsId,
    selectors.patients.socialSubstancesMarijuanaId,
    selectors.patients.socialSubstancesSexuallyActiveId,
    selectors.patients.socialSubstancesSmokeId,
  ];

  const yesNoData = [
    { label: signup.yes[language], value: 'yes' },
    { label: signup.no[language], value: 'no' }
  ];
  const smoke = formProps.valueGetter(selectors.patients.socialSubstancesSmokeId);
  const everSmoked = formProps.valueGetter(selectors.patients.socialSubstancesEverSmokeId);
  return (
    <div>
      <Field
        disabled={formProps.disabled}
        key={'social.relationshipStatus'}
        id={'social.relationshipStatus'}
        name={'social.relationshipStatus'}
        label={'Relationship Status'}
        component={FormRadioGroup}
        data={relationshipStatusData}
        layout={'horizontal'}
      />
      <Field
        disabled={formProps.disabled}
        key={'social.occupation'}
        id={'social.occupation'}
        name={'social.occupation'}
        data-test={selectors.patients.occupation}
        label={signup.socialHistory[selectors.patients.occupation][language]}
        component={FormInput}
      />
      {yesNoQuestions.map((key, index) => (
        <Row key={`yes-no-question-${index}`} noGutters style={{ borderBottom: '1px solid lightgrey' }}>
          <Col style={{ marginTop: 16 }}>
            <span>{(signup.socialHistory.questions as any)?.[key]?.[language]}</span>
          </Col>
          <Col className="d-flex flex-row">
            <span style={{ padding: 16 }}>{signup.no[language]}</span>
            {/* TODO: fix how if you go back this loses the switch state */}
            <Field
              disabled={formProps.disabled}
              key={key}
              id={key}
              name={key}
              component={FormSwitch}
              data={yesNoData}
            />
            <span style={{ padding: 16 }}>{signup.yes[language]}</span>
          </Col>
        </Row>
      ))}
      {smoke && (
        <Field
          disabled={formProps.disabled}
          key="social.substances.packsPerDay"
          id="social.substances.packsPerDay"
          name="social.substances.packsPerDay"
          label={signup.socialHistory.questions[selectors.patients.socialSubstancesPacksPerDay][language]}
          component={FormNumericTextBox}
        />
      )}
      {!smoke && (
        <Row noGutters style={{ borderBottom: '1px solid lightgrey' }}>
          <Col style={{ marginTop: 16 }}>
            <span>{signup.socialHistory.questions[selectors.patients.socialSubstancesEverSmokeId][language]}</span>
          </Col>
          <Col className="d-flex flex-row">
            <span style={{ padding: 16 }}>{signup.no[language]}</span>
            <Field
              disabled={formProps.disabled}
              key="social.substances.everSmoked"
              id="social.substances.everSmoked"
              name="social.substances.everSmoked"
              component={FormSwitch}
            />
            <span style={{ padding: 16 }}>{signup.yes[language]}</span>
          </Col>
        </Row>
      )}
      {!smoke && everSmoked && (
        <Field
          disabled={formProps.disabled}
          key="social.substances.quit"
          id="social.substances.quit"
          name="social.substances.quit"
          label={signup.socialHistory.questions[selectors.patients.dateQuitSmoking][language]}
          placeholder="__/____"
          mask="00/0000"
          includeLiterals={false}
          component={FormMaskedTextBox}
        />
      )}
      {!smoke && everSmoked && (
        <Field
          disabled={formProps.disabled}
          key="social.substances.packsPerDay"
          id="social.substances.packsPerDay"
          name="social.substances.packsPerDay"
          label={signup.socialHistory.questions[selectors.patients.usedToSmoke][language]}
          component={FormNumericTextBox}
          data-test={selectors.patients.packsPerDayInput}
        />
      )}
    </div>
  );
}
