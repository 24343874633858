import { Button } from '@progress/kendo-react-buttons';
import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import toast from 'react-hot-toast';

export const ConfirmationToast = (text: string, confirm: { text: string; fn: Function }, cancelText: string) => toast.success(t => (
  <Row className="w-100">
    <Row className="w-100">
      <span>
        {text}
      </span>
    </Row>
    <Row className="w-100 d-flex flex" noGutters>
      <Col xs={6}>
        <Button look="flat" className="text-light" onClick={e => { e.preventDefault(); toast.dismiss(t.id) }}>
          {cancelText}
        </Button>
      </Col>
      <Col  xs={6}>
        <Button onClick={async e => {
          e.preventDefault();
          await confirm.fn(t.id);
          toast.dismiss(t.id);
        }}>
          {confirm.text}
        </Button>
      </Col>
    </Row>
  </Row>
), { icon: '', duration: 24000, className: 'dark-toast' })
