import { Button } from '@progress/kendo-react-buttons';
import React from 'react';
import { ReactComponent as Success } from '../assets/email.svg';
import useLanguage from '../hooks/use-language';
import useQuery from '../hooks/use-query';
import { signup } from '../intl/signup';
export interface PatientThankYouProps {

}

const PatientThankYou: React.SFC<PatientThankYouProps> = () => {
  const language = useLanguage();
  const query = useQuery();
  const medicalHistory = query.get('medicalHistory');
  const practiceId = query.get('practiceId');
  return (
    <div className="h-100 w-100 d-flex justify-content-center align-items-center flex-column p-3">
      <h2 className="text-center">{signup.thankyou[language]}</h2>
      <div className="mb-4" style={{ height: 300, width: 300, marginLeft: 38, marginTop: -100 }}>
        <Success />
      </div>
      <h1 className="text-center">{signup.providerWillEmail[language]}</h1>
      {medicalHistory && practiceId ? <a className="w-50" href={`/#/${practiceId}/patients/new/med-history${language !== 'default' ? `?language=${language}` : ''}`}><Button className="w-100" icon="plus">{signup.medHistoryTitle[language]}</Button></a> : null}
    </div>
  );
}

export default PatientThankYou;
