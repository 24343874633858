import { Button } from '@progress/kendo-react-buttons';
import { Cache } from 'aws-amplify';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import NewPatient from '../components/patients/NewPatient';
import { PatientProfile } from '../components/patients/PatientProfile.interface';
import { buildPatientProfile } from '../components/patients/PatientsUtil';
import { IAddress } from '../interfaces/Address.interface';
import { NEW_PATIENT_KEY, NEW_PATIENT_SAME_ADDRESS_KEY } from "./MultiPatientSetup";

export type NewMultiPatientProps = RouteComponentProps<{practiceId: string; localPatientIndex: string}> & {

}

const NewMultiPatient: React.SFC<NewMultiPatientProps> = ({ match, history }) => {
  const practiceId = match.params.practiceId;
  if (!Cache.getItem(NEW_PATIENT_KEY)?.length) Cache.setItem(NEW_PATIENT_KEY, [{}]);
  const cachedPatients: any[] = Cache.getItem(NEW_PATIENT_KEY);
  const cachedAddress: IAddress = Cache.getItem(NEW_PATIENT_SAME_ADDRESS_KEY);

  const patientIndex = parseInt(match.params.localPatientIndex);
  if (patientIndex >= cachedPatients.length) {
    return (
      <div>
        Sorry, something went wrong.
        <Button icon="chevron-left" onClick={() => history.goBack()}>
          Go Back
        </Button>
      </div>
    )
  }

  const onSubmit = async (values: {[name: string]: any}) => {
    cachedPatients[patientIndex].disabled = true;
    cachedPatients[patientIndex].data = {
      profile: buildPatientProfile(values),
      values,
    };
    Cache.setItem(NEW_PATIENT_KEY, cachedPatients);
    history.push(`/${practiceId}/patients/new/multi`);
  }

  const patient: PatientProfile = cachedPatients[patientIndex];
  return (
    <NewPatient
      firstName={patient.firstName}
      lastName={patient.lastName}
      dob={patient.dob}
      email={patient.email}
      phoneNumber={(patient as any).phoneNumber}
      address={cachedAddress}
      onLastSubmit={onSubmit}
    />
  );
}

export default withRouter(NewMultiPatient);
