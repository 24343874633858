import React from 'react';
import { AmplifyAuthenticator, AmplifySignUp } from '@aws-amplify/ui-react';
import { useWindowSize } from '@react-hook/window-size';
import Row from 'react-bootstrap/Row';
import KeenSignIn from './KeenSignIn';

const AMPLIFY_DEFINED_TABLET_WIDTH = 671;

const KeenAuthenticator = () => {
  // eslint-disable-next-line
  const [browserWidth, browserHeight] = useWindowSize();

  let authenticator = (
    <AmplifyAuthenticator id="keen-authenticator">
      {/* Customize Amplify Auth Components (https://docs.amplify.aws/ui/auth/authenticator/q/framework/react#sign-up) */}
      <KeenSignIn headerText="Welcome to the AeroDPC Patient Portal" slot="sign-in" />
      <AmplifySignUp headerText="Contact your admin to create a user" slot="sign-up" formFields={['']} />
    </AmplifyAuthenticator>
  );

  return browserWidth > AMPLIFY_DEFINED_TABLET_WIDTH ? (
    <Row sm={12} className="justify-content-center align-items-center h-100">
        {authenticator}
    </Row>
  ) : (
    <>
      {authenticator}
    </>
  )
}
export default KeenAuthenticator;
