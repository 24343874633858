import { Form, FormElement } from '@progress/kendo-react-form';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import React, { useCallback } from 'react';
import { Row } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { NewPatientProfile } from '../../components/patients/NewPatientProfile';
import { PatientProfile } from '../../components/patients/PatientProfile.interface';
import ViewPatientMedicalHistory from './view-patient-tabs/ViewMedicalHistory';
import ViewFamilyHistory from './view-patient-tabs/ViewFamilyHistory';
import ViewMedical from './view-patient-tabs/ViewMedical';
import ViewSocialHistory from './view-patient-tabs/ViewSocialHistory';

export interface ViewPatientProps {
  patient?: PatientProfile;
}
const tabs = ['profile', 'medical-history', 'family-history', 'social-history', 'medical'];

// const defaultCachedHistories = {
//   'medical-history': undefined as any,
//   familyHistory: undefined as any,
//   socialHistory: undefined as any,
//   medical: undefined as any,
// }

const ViewPatient: React.SFC<ViewPatientProps> = (props) => {
  // const [cachedHistories, setCachedHistories] = useState(defaultCachedHistories);
  const history = useHistory();
  const match = useRouteMatch<{ tabName: string }>();
  const setSelectedInUrl = useCallback((index: number) => history.push(`/portal/${tabs[index]}`), [history]);

  const tabName = match.params?.tabName;
  const tabIndex = tabs.findIndex(tab => tab === tabName);
  return (
    <TabStrip selected={tabIndex} onSelect={e => setSelectedInUrl(e.selected)} className="aero-patient-tabs w-100 h-100">
      <TabStripTab key="profile" title="Profile" contentClassName="w-100 h-100 aero-patient-tab">
        <Row className="w-100 p-3 align-items-center justify-content-center">
          <Form
            initialValues={props.patient}
            render={formRenderProps => (
              <FormElement>
                <NewPatientProfile {...formRenderProps} disabled />
              </FormElement>
            )}
          />
        </Row>
      </TabStripTab>
      <TabStripTab key="medical-history" title="Medical History" contentClassName="w-100 aero-patient-tab">
        <ViewPatientMedicalHistory patientId={props.patient?.patientId} />
      </TabStripTab>
      <TabStripTab key="family-history" title="Family History" contentClassName="w-100 aero-patient-tab">
        <ViewFamilyHistory patientId={props.patient?.patientId} />
      </TabStripTab>
      <TabStripTab key="social-history" title="Social History" contentClassName="w-100 aero-patient-tab">
        <ViewSocialHistory patientId={props.patient?.patientId} />
      </TabStripTab>
      <TabStripTab key="medical" title="Allergies/Medication" contentClassName="w-100 aero-patient-tab">
        <ViewMedical patientId={props.patient?.patientId} />
      </TabStripTab>
    </TabStrip>
  );
}

export default ViewPatient;
