import { selectors } from '@keenhp/website-common';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import React from 'react';
import { UseAsyncReturn } from 'react-async-hook';
import { Col, Row } from 'react-bootstrap';
import { PaymentFrequency } from './patients/PaymentFrequency';
import './price-boxes.scss';

export type PriceBoxesProps = {
  results: UseAsyncReturn<any, any>;
  selected?: string;
  onSelect?: Function;
}
export const PriceBoxes = ({ results, selected, onSelect }: PriceBoxesProps) => {
  if (results.error) {
    console.error(results.error);
    return <span className="text-danger">Unable to load the subscription prices at this time.</span>;
  }
  if (results.loading) return <span>Loading subscription prices...</span>;
  if (results.result) return getPriceBoxes(results.result, selected, onSelect);
  return <span>Add patients and enter valid birthdays and you will see the practice prices here</span>;
}

const getPriceBoxes = (results: any, selected?: string, onSelect?: Function) => {
  const res: any = {};
  for (const frequency in results.price) {
    const checked = selected === frequency;
    const product: { total: number; productIds: string[]; } = results.price[frequency];
    const countPrices: { [key: string]: number } = {};
    product.productIds.forEach(id => {
      countPrices[id] = countPrices[id] ? countPrices[id] += 1 : 1;
    });

    const data = Object.keys(countPrices).map(id => ({
      age: `${results.billingTiers[id]?.minAge} ${results.billingTiers[id]?.maxAge < 150 ? `- ${results.billingTiers[id]?.maxAge}` : `+`}`,
      count: countPrices[id],
      price: results.billingTiers[id]?.prices?.find((x: { frequency: string }) => x.frequency === frequency)?.price,
    }));
    const title = frequency.charAt(0) + frequency.slice(1).toLowerCase();
    res[frequency] = (
      <Col
        sm={12}
        md
        key={frequency}
        className={`m-2 align-items-center text-center keenhp-price-box ${checked ? 'selected' : ''} ${frequency} ${!!selected ? 'selectable' : ''}`}
        data-test={`${selectors.patients.paymentFrequencyType}-${frequency}`}
        onClick={() => {
          if (onSelect) onSelect(frequency);
        }}
      >
        <Row className="pb-1">
          <Col className="d-flex align-items-center">
            <h3>Pay {title}ly</h3>
          </Col>
          <Col>
            <div className={`ribbon ${checked ? 'up' : 'down'} pb-2`}>
              <div className={`content ${frequency}`}>
                <h2>${product.total}</h2><h6>({product.productIds.length} subscription{product.productIds.length !== 1 && 's'})</h6>
              </div>
            </div>
          </Col>
        </Row>
        <Grid data={data}>
          <GridColumn field="count" title="Count" width={100} />
          <GridColumn field="age" title="Tier" />
          <GridColumn field="price" title="$ / Person" />
        </Grid>
      </Col>
    );
  }
  return (
    <>
      {res[PaymentFrequency.MONTH]}
      {res[PaymentFrequency.QUARTER]}
      {res[PaymentFrequency.ANNUAL]}
    </>
  );
}
