import { GridCellProps } from "@progress/kendo-react-grid";
import { MaskedTextBox } from '@progress/kendo-react-inputs';
import { Tooltip } from '@progress/kendo-react-tooltip';
import React from 'react';

export interface EditGridDateInputProps extends GridCellProps {}

export const EditGridFullDateInput = (props: EditGridDateInputProps) => {
  const { dataItem, field } = props;
  let dataValue = dataItem[field!] === null ? '' : dataItem[field!];
  if (dataValue && typeof dataValue === 'object') {
    dataValue = dataValue?.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' });
  }
  if (!dataItem.inEdit) return <td>{dataValue}</td>

  const dataDate = new Date(dataValue);
  const valid = (dataValue.length === 10 && !isNaN(dataDate.getTime()) && dataDate.getFullYear() < new Date().getFullYear() + 1 && dataDate.getFullYear() > new Date().getFullYear() - 130)
    || !dataValue;

  return (
    <td className="d-flex flex-row align-items-center">
      <MaskedTextBox
        {...props}
        key={field}
        id={field}
        name={field}
        mask="00/00/0000"
        includeLiterals={false}
        placeholder="__/__/____"
        value={dataValue}
        width="100%"
        onChange={e => props.onChange ? props.onChange({dataItem: props.dataItem, syntheticEvent: e.syntheticEvent, field: props.field, value: e.target.value}) : null}
        valid={valid}
      />
      {!valid && <Tooltip anchorElement="target" position="bottom"><span className="text-danger font-smaller k-i-warning k-icon" title="Invalid Date" /></Tooltip>}
    </td>
  );
}

export const EditGridMoYearDateInput = (props: EditGridDateInputProps) => {
  const { dataItem, field } = props;
  let dataValue = dataItem[field!] === null ? '' : dataItem[field!];
  if (dataValue && typeof dataValue === 'object') {
    dataValue = dataValue?.toLocaleDateString('en-US', { month: '2-digit', year: 'numeric' });
  }
  if (!dataItem.inEdit) return <td>{dataValue}</td>
  let [month, year] = dataValue ? dataValue.split('/') : [null, null];
  month = parseInt(month);
  year = parseInt(year);
  const valid = (!!dataValue && dataValue.length === 7 && year < new Date().getFullYear() + 1 && year > new Date().getFullYear() - 130 && month < 13 && month > 0)
    || !dataValue;

  return (
    <td className="d-flex flex-row align-items-center">
      <MaskedTextBox
        {...props}
        key={field}
        id={field}
        name={field}
        mask="00/0000"
        includeLiterals={false}
        placeholder="__/____"
        value={dataValue}
        width="100%"
        onChange={e => props.onChange ? props.onChange({dataItem: props.dataItem, syntheticEvent: e.syntheticEvent, field: props.field, value: e.target.value}) : null}
        valid={valid}
      />
      {!valid && <Tooltip anchorElement="target" position="bottom"><span className="text-danger font-smaller k-i-warning k-icon" title="Invalid Date" /></Tooltip>}
    </td>
  );
}
