import { ChunkProgressBar } from '@progress/kendo-react-progressbars';
import { Cache } from 'aws-amplify';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import NewPatientBilling from '../components/patients/NewPatientBilling';
import { PaymentFrequency } from '../components/patients/PaymentFrequency';
import { PaymentMethod } from '../components/patients/PaymentMethods';
import { PriceBoxes } from '../components/PricesBoxes';
import { createPatientBilling } from '../services/patient-service';
import { NEW_PATIENTS_BILLING, NEW_PATIENT_KEY } from "./MultiPatientSetup";

export type MultiPatientBillingProps = RouteComponentProps<{ practiceId: string }> & {}

/**
 * 1. create billing records for the first patient w/o paying patient id, and with token and stuff
 *    this happens in the NewPatientBilling component
 * 2. create billing records for the rest using paying patient id of the first one
 * 3. save the rest of the patient histories
 * 4. redirect to thankyou
 */
const MultiPatientBilling: React.SFC<MultiPatientBillingProps> = ({ match }) => {
  const practiceId = match.params.practiceId;
  const cachedPatients: any = Cache.getItem(NEW_PATIENT_KEY);
  const [progress, setProgress] = useState(0);
  const [selected, setSelected] = useState(PaymentFrequency.MONTH);

  if (cachedPatients.length === 0) {
    return (
      <div className="w-100 h-100">
        <h1>It looks like something went wrong.</h1>
        <h2>Please refresh the page, or go back and try creating the patients again.</h2>
      </div>
    );
  }
  const firstPatient = cachedPatients[0]?.saved;

  const onSuccess = async (data: any) => {
    // save billing record for all the other patients
    for (const pat of cachedPatients) {
      const patient = pat?.saved;
      if (patient.patientId !== firstPatient.patientId) {
        console.log('Keen: onSuccess -> patient', patient);
        const billing = await createPatientBilling(
          practiceId,
          patient.patientId,
          `${patient.firstName} ${patient.lastName}`,
          patient.email,
          PaymentMethod.PATIENT,
          '',
          firstPatient.patientId,
          data.subscriptionPaymentFrequency,
          data.customerId
        );
        console.debug('Keen: onSuccess -> saved billing success', billing?.patientId, billing);
      }
      setProgress(old => old + 1);
    }
    // TODO finally clear the cache
    // Cache.setItem(NEW_PATIENT_KEY, false);
    // Cache.setItem(NEW_PATIENT_SAME_ADDRESS_KEY, false);
  }

  const chunks = cachedPatients.length * 2;
  if (progress > 0) {
    return (
      <Row className="w-100 justify-content-center align-items-center">
        <ChunkProgressBar value={100 * progress / chunks} chunkCount={chunks} />
      </Row>
    )
  }
  return (
    <div className="p-4">
      <Row>
        <h2>Select your subscription payment frequency</h2>
      </Row>
      <Row>
        <PriceBoxes results={Cache.getItem(NEW_PATIENTS_BILLING)} selected={selected} onSelect={setSelected} />
      </Row>
      <Row>
        <Col>
          <NewPatientBilling
            patientId={firstPatient?.patientId}
            onSaveSuccess={onSuccess}
            patientEmail={firstPatient?.email}
            patientName={`${firstPatient?.firstName} ${firstPatient?.lastName}`}
            selectedPaymentFrequency={selected}
          />
        </Col>
      </Row>
    </div>
  );
}

export default MultiPatientBilling;
