import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export type SupportedLanguages = 'es' | 'default';
export const supportedLanguages = ['es', 'default'];

export default function useLanguage() {
  const query = new URLSearchParams(useLocation().search);
  let language = query.get('language') as SupportedLanguages;
  const [result, setResult] = useState<SupportedLanguages>('default');
  useEffect(() => {
    setResult(language);
  }, [language]);
  return (!language || !supportedLanguages.includes(language)) ? 'default' : result;
}
