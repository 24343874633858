// [external only] multi patient form
export const sameAddressSwitch = 'sameAddressSwitch';
export const saveAndContinueToBillingBtn = 'saveAndContinueToBillingBtn';
export const editableGridClass = 'editableGridClass';
export const addInfoBtn = 'addInfoBtn';

// grid
export const refreshPatientsGrid = 'refreshPatientsGrid';
export const newPatientBtn = 'newPatientBtn';
export const continuePatientBtn = 'continuePatientBtn';

// wizard
export const wizardNextBtn = 'wizardNextBtn';
export const wizardPrevBtn = 'wizardPrevBtn';
export const wizardCancelBtn = 'wizardCancelBtn';

// Patient Profile
export const firstName = 'firstName';
export const middleName = 'middleName';
export const lastName = 'lastName';
export const preferredName = 'preferredName';
export const dob = 'dob';
export const email = 'email';
export const primaryPhoneOwner = 'primaryPhoneOwner';
export const genderIdentity = 'genderIdentity';
export const allowGenderIdentity = 'allowGenderIdentity';
export const emailOwner = 'emailOwner';
export const pronouns = 'pronouns';
export const gender = 'gender';
export const genderSelectorMale = '[aria-label="Male"]';
export const genderSelectorFemale = '[aria-label="Female1"]';
export const genderSelectorOther = '[aria-label="Other"]';
export const addressLine1 = 'addressLine1';
export const addressLine2 = 'addressLine2';
export const city = 'city';
export const state = 'state';
export const zipCode = 'zipCode';
export const preferredPharmacy = 'preferredPharmacy';
export const primaryProvider = 'primaryProvider';
export const name = 'name';
export const phoneNumber = 'phoneNumber';
export const phoneNumberId = '#phoneNumber';
export const emergencyContactPhoneNumber = 'emergencyContactPhoneNumber';
export const emergencyContactName = 'emergencyContactName';
export const primaryInsurance = {
  payerName: 'primary-payerName',
  memberId: 'primary-memberId',
  groupId: 'primary-groupId',
  payerPhoneNumber: 'primary-payerPhoneNumber',
  payerAddress: {
    addressLine1: 'primary-payer-addressLine1',
    addressLine2: 'primary-payer-addressLine2',
    city: 'primary-payer-city',
    state: 'primary-payer-state',
    zipCode: 'primary-payer-zipCode',
  },
  showInsuredPerson: 'primary-showInsuredPerson',
  insurer: {
    firstName: 'primary-insurer-firstName',
    lastName: 'primary-insurer-lastName',
    gender: 'primary-insurer-gender',
    dateOfBirth: 'primary-insurer-dateOfBirth',
    employer: 'primary-insurer-employer',
    relationship: 'primary-insurer-relationship',
    phone: 'primary-insurer-phone',
    address: {
      addressLine1: 'primary-insurer-address-addressLine1',
      addressLine2: 'primary-insurer-address-addressLine2',
      city: 'primary-insurer-address-city',
      state: 'primary-insurer-address-state',
      zipCode: 'primary-insurer-address-zipCode',
    },
  }
};
export const secondaryInsurance = {
  payerName: 'secondary-payerName',
  memberId: 'secondary-memberId',
  groupId: 'secondary-groupId',
  payerPhoneNumber: 'secondary-payerPhoneNumber',
  payerAddress: {
    addressLine1: 'secondary-payer-addressLine1',
    addressLine2: 'secondary-payer-addressLine2',
    city: 'secondary-payer-city',
    state: 'secondary-payer-state',
    zipCode: 'secondary-payer-zipCode',
  },
  showInsuredPerson: 'secondary-showInsuredPerson',
  insurer: {
    firstName: 'secondary-insurer-firstName',
    lastName: 'secondary-insurer-lastName',
    gender: 'secondary-insurer-gender',
    dateOfBirth: 'secondary-insurer-dateOfBirth',
    employer: 'secondary-insurer-employer',
    relationship: 'secondary-insurer-relationship',
    phone: 'secondary-insurer-phone',
    address: {
      addressLine1: 'secondary-insurer-address-addressLine1',
      addressLine2: 'secondary-insurer-address-addressLine2',
      city: 'secondary-insurer-address-city',
      state: 'secondary-insurer-address-state',
      zipCode: 'secondary-insurer-address-zipCode',
    },
  }
};
export const uploadPatientPhoto = 'uploadPatientPhoto';

// NewPatientMedicalHistory
export const diagnosisGridClass = 'diagnosisGridClass';
export const addDiagnosisBtn = 'addDiagnosisBtn';

export const surgeryGridClass = 'surgeryGridClass';
export const addSurgeryBtn = 'addSurgeryBtn';

// Family history
export const familyHistoryGridClass = 'familyHistoryGridClass';
export const addFamilyHistoryBtn = 'addFamilyHistoryBtn';

// social
// marriage status
// aria-label="Single"
export const occupation = 'occupation';
export const socialSubstancesAlcoholId = 'social.substances.alcohol';
export const socialSubstancesIllicitDrugsId = 'social.substances.illicitDrugs';
export const socialSubstancesMarijuanaId = 'social.substances.marijuana';
export const socialSubstancesSexuallyActiveId = 'social.substances.sexuallyActive';
export const socialSubstancesSmokeId = 'social.substances.smoke';
export const socialSubstancesEverSmokeId = 'social.substances.everSmoked';
export const socialSubstancesPacksPerDay = 'social.substances.packsPerDay';
export const socialSubstancesWhenQuit = 'social.substances.quit';
export const packsPerDayInput = 'packsPerDayInput';
export const dateQuitSmoking = 'dateQuitSmoking';
export const usedToSmoke = 'usedToSmoke';

// allergies
export const allergiesGridClass = 'allergiesGridClass';
export const shotsGridClass = 'shotsGridClass';
export const addAllergyBtn = 'addAllergyBtn';

// Billing
export const paymentMethodDiv = 'paymentMethodDiv';
export const connectBankAcctBtn = 'connectBankAcctBtn';
export const stripeIframeSelector = '.__PrivateStripeElement > iframe';
export const creditCardNumberFieldSelector = '[data-elements-stable-field-name="cardNumber"]';
export const creditCardExpiryFieldSelector = '[data-elements-stable-field-name="cardExpiry"]';
export const creditCardCvcFieldSelector = '[data-elements-stable-field-name="cardCvc"]';
export const creditCardZipCodeFieldSelector = '[data-elements-stable-field-name="postalCode"]';
export const paymentFrequencyDiv = 'paymentFrequencyDiv';
export const paymentFrequencyType = 'paymentFrequencyType';
export const showCreditCardBtn = 'showCreditCardBtn';
export const savePaymentBtn = 'savePaymentBtn';

export const plaidContinueBtnSelector = 'button[id="aut-button"]';
export const plaidBanksSearch = '#search-input';
export const plaidBanksListSelector = 'ul';
export const plaidBankAccountListSelector = '[aria-labelledby="a11y-title"]';
export const plaidUsernameSelector = 'input#aut-input-0';
export const plaidPasswordSelector = 'input#aut-input-1';
export const plaidSubmitBtn = 'button[type="submit"]';
export const plaidSuccessfulLinkMsg = 'plaidSuccessfulLinkMsg';

/** External Patients */
// New Patients Dashboard
export const newPatientsGridSelector = '.new-patients';
export const newPatientFormWrapper = 'newPatientFormWrapper';
export const confirmExternalPatientsBtnDashboard = 'confirmExternalPatientsBtnDashboard';
export const addPatient = 'addPatient';
export const removePatient = 'removePatient';
export const discardExternalPatientsBtn = 'discardExternalPatientsBtn';
export const discardExternalPatientsModalBtn = 'discardExternalPatientsModalBtn';
export const cancelExternalPatientsModalBtn = 'cancelExternalPatientsModalBtn';
export const viewExternalPatientsListBtn = 'viewExternalPatientsListBtn';
export const viewArchivedPatientsListBtn = 'viewArchivedPatientsListBtn';
export const viewPatientsList = 'viewPatientsList';
export const externalPatientNameLink = 'externalPatientNameLink';

// Confirm Patients
export const confirmExternalPatientsGridClass = 'confirmExternalPatientsGrid';
export const confirmExternalPatientsChargesGridClass = 'confirmExternalPatientsChargesGrid';
export const addChargeInConfirmPatient = 'addChargeInConfirmPatient';
export const addProductInConfirmPatient = 'addProductInConfirmPatient';
export const isSettingMaxSubscriptionPriceId = 'isSettingMaxSubscriptionPrice';
export const confirmPatientsCancelBtn = 'confirmPatientsCancelBtn';
export const confirmPatientsSaveBtn = 'confirmPatientsSaveBtn';
export const confirmPatientFirstBillH3 = 'confirmPatientFirstBill';
export const confirmPatientBillingStatusTd = 'confirmPatientBillingStatus';

/** Patient Chart */
export const chartName = 'chartName';
export const invoicesGridClass = 'invoicesGrid';
export const invoicesGridRowSelector = `.${invoicesGridClass} .k-grid-container tr`;
export const invoicesGridExpanderSelector = 'td.k-hierarchy-cell.can-expand';
export const invoicesGridDetailClass = 'invoicesGridDetail';
export const invoicesGridDetailSelector = `.${invoicesGridDetailClass}`;
