import { formatNumber } from '@telerik/kendo-intl';
import React from 'react';
import './patient-billing.scss';
import { PatientOutstandingBalanceProps } from './PatientOutstandingBalanceProps.interface';

export const PatientOutstandingBalance = (props: PatientOutstandingBalanceProps) => {

  return (
    <div className="patient-billing__outstanding-balance shadow-sm">
      <div className="patient-billing__subscription-card-wrap">
        <h2>Outstanding Balance</h2>
        <p className="patient-billing__subscription-price">{formatNumber(props.outstandingBalance, 'c')}</p>
      </div>
    </div>
  );
}
