import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Editor } from '@progress/kendo-react-editor';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { PDFExport } from '@progress/kendo-react-pdf';
import React, { useState } from 'react';
import { useAsync } from 'react-async-hook';
import { Col, Row } from 'react-bootstrap';
import AeroError from '../../components/AeroError';
import KeenSpinner from '../../components/KeenSpinner';
import { PatientProfile } from '../../components/patients/PatientProfile.interface';
import VitalsForm from '../../components/VitalsForm';
import { getNotes } from '../services/notes-service';


const defaultSort: SortDescriptor[] = [{ field: 'eventTimestamp', dir: 'desc' }];
const dateConfig: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };

export interface PatientChartProps {
  patient?: PatientProfile;
}

const PatientChart: React.SFC<PatientChartProps> = (props) => {
  const [noteDialog, setNoteDialog] = useState<any>(false);
  const [vitalsDialog, setVitalsDialog] = useState<any>(false);
  const req = useAsync(getNotes, [props.patient?.patientId]);

  const notePdf = React.useRef<PDFExport>(null);
  const vitalsPdf = React.useRef<PDFExport>(null);

  if (req.loading) return <KeenSpinner />;
  if (req.error) return <AeroError error={req.error} />;

  const exportToPdf = () => { if (notePdf.current) notePdf.current.save(); }
  const exportGridToPdf = () => { if (vitalsPdf.current) vitalsPdf.current.save(); }

  return (
    <div className="w-100 p-3 aero-patient-chart">
      <div className="py-2 mobile-grid-wrapper">
        <h2>Chart Notes</h2>
        <Grid data={orderBy(req.result?.notes || [], defaultSort)} onRowClick={e => setNoteDialog(e.dataItem)}>
          <GridColumn field="eventTimestamp" title="Date" width={150} cell={(props: any) => {
            const eventTimestamp = new Date(props.dataItem.eventTimestamp);
            let text = eventTimestamp.toLocaleDateString('en-US', dateConfig);
            return (
              <td>
                {props.dataItem.files && props.dataItem.files.length > 0 && <span className="text-muted k-icon k-i-attachment" />}
                {text}
              </td>
            );
          }} />
          <GridColumn field="data.text" title="Text" />
        </Grid>
        {noteDialog && (
          <Dialog
            title={<><span className="d-flex justify-content-center align-items-center">Note from {new Date(noteDialog.eventTimestamp).toLocaleDateString('en-US', dateConfig)}</span><Button icon="print" look="flat" className="ml-2" onClick={exportToPdf} /></>}
            onClose={() => setNoteDialog(false)}
            width="75%"
            height="100%"
          >
            <PDFExport ref={notePdf} paperSize="A4" scale={0.75} fileName={`Note from ${new Date(noteDialog.eventTimestamp).toLocaleDateString('en-US', dateConfig)}.pdf`} >
              <Editor value={noteDialog?.data?.formattedText || noteDialog?.data?.text || ''} style={{ height: '100%' }} defaultEditMode="div" />
            </PDFExport>
            <DialogActionsBar>
              <Button className="k-button k-primary" onClick={() => setNoteDialog(false)}>
                Close
              </Button>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
      <div className="py-2">
        <PDFExport ref={vitalsPdf} paperSize="A4" scale={0.75} margin="2cm" fileName={`${props.patient?.preferredName || props.patient?.firstName || 'Patient'}_Vitals - ${new Date(noteDialog.eventTimestamp).toLocaleDateString('en-US', dateConfig)}.pdf`} >
          <Row noGutters>
            <Col>
              <h2>Vitals</h2>
            </Col>
            <Col className="d-flex justify-content-end">
              <Button icon="print" look="flat" className="ml-2" onClick={exportGridToPdf} />
            </Col>
          </Row>
          <Grid data={req.result?.vitals} onRowClick={e => setVitalsDialog(e.dataItem)}>
            <GridColumn field="eventTimestamp" title="Date" width={150} cell={(props: any) => {
              const eventTimestamp = new Date(props.dataItem.eventTimestamp);
              let text = eventTimestamp.toLocaleDateString('en-US', dateConfig);
              return (
                <td>
                  {props.dataItem.files && props.dataItem.files.length > 0 && <span className="text-muted k-icon k-i-attachment" />}
                  {text}
                </td>
              );
            }} />
            <GridColumn field="data.systolic" title="Systolic" />
            <GridColumn field="data.diastolic" title="Diastolic" />
            <GridColumn field="data.heartRate" title="Pulse" />
            <GridColumn field="data.spo2" title="SpO2" />
            <GridColumn field="data.temp" title="Temp" />
            <GridColumn field="data.respiratoryRate" title="Respiratory Rate" />
            <GridColumn field="data.bmi" title="BMI" />
          </Grid>
        </PDFExport>

        {vitalsDialog && (
          <Dialog
            title={`Vitals on ${new Date(vitalsDialog.eventTimestamp).toLocaleDateString('en-US', dateConfig)}`}
            onClose={() => setVitalsDialog(false)}
            width="75%"
            height="100%"
          >
            <VitalsForm data={vitalsDialog.data} />
            <DialogActionsBar>
              <Button className="k-button k-primary" onClick={() => setVitalsDialog(false)}>
                Close
              </Button>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    </div>
  );
}

export default PatientChart;
