import { Auth, Cache } from 'aws-amplify';
import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AeroLogo from '../assets/AeroLogo';

export const signOut = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  e.preventDefault();
  Cache.clear();
  await Auth.signOut();
}

export interface NavBarProps extends RouteComponentProps {
  name?: string;
}

const NavBar: React.SFC<NavBarProps> = (props) => {
  const isHome = !props.location.pathname.endsWith('chart') && !props.location.pathname.endsWith('billing');
  return (
    <Navbar expand="lg" style={{ maxHeight: 42 }}>
      <Navbar.Brand href="/#/portal" className="aero-logo">
        <AeroLogo width={132} height={27} fill="" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="aero-nav-bar" className="px-2">
        <span className="k-icon k-i-more-vertical mb-1" />
      </Navbar.Toggle>
      <Navbar.Collapse id="aero-nav-bar" className="bg-white">
        <Nav activeKey={`/#${isHome ? '/portal/profile' : props.location.pathname}`}>
          <Nav.Link href="/#/portal/profile">Home</Nav.Link>
          <Nav.Link href="/#/portal/chart">Chart</Nav.Link>
          <Nav.Link href="/#/portal/billing">Billing</Nav.Link>
        </Nav>
        <Nav className="flex-grow-1 justify-content-end" activeKey={`/#${props.location.pathname}`}>
          <Navbar.Text>
            <span>{props.name}</span>
          </Navbar.Text>
          <Nav.Link onClick={(e: any) => signOut(e)} data-test="nav-sign-out-btn">
            Sign Out
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};


export default withRouter(NavBar);
