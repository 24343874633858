/**
 * This component is used to set up billing information for a patient.
 */
import { selectors } from '@keenhp/website-common';
import { Button } from '@progress/kendo-react-buttons';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeError } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getStripePublishableKey } from '../../config';
import useLanguage from '../../hooks/use-language';
import { signup } from '../../intl/signup';
import { getPlaidLink } from '../../services/billings-service';
import { createPatientBilling } from '../../services/patient-service';
import './PatientBilling.scss';
import { PatientBillingACH } from './PatientBillingACH';
import { PatientBillingCard } from './PatientBillingCard';
import { PaymentFrequency } from './PaymentFrequency';
import { PaymentMethod } from './PaymentMethods';

type NewPatientBillingProps = RouteComponentProps<{ practiceId: string }> & {
  practiceId?: string;
  patientName?: string;
  patientEmail?: string;
  patientId: string;
  selectedPaymentFrequency: PaymentFrequency;
  onSaveSuccess?: (response: any) => Promise<void>;
  overrideSave?: Function;
  small?: boolean;
}

export const NewPatientBilling = (props: NewPatientBillingProps) => {
  const language = useLanguage();
  const practiceId = props.practiceId || props.match.params.practiceId;
  const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.ACH);
  const [paymentToken, setPaymentToken] = useState('');
  const [linkToken, setLinkToken] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [saveComplete, setSaveComplete] = useState(false);
  const [stripePromise] = React.useState(() => loadStripe(getStripePublishableKey()));

  useEffect(() => {
    async function getToken() {
      const linkToken = await getPlaidLink(props.patientId, practiceId, language);
      setLinkToken(linkToken);
    }
    getToken();
  }, [props.patientId, setLinkToken, practiceId, language]);

  /**
   * Handler passed via props for ach or card component so that when they receive the
   * stripe token it'll be passed here.
   * @param stripeToken the stripe token received from either the ach or card component.
   */
  const handleOnTokenReceived = (stripeToken: string) => {
    setPaymentToken(stripeToken);
  }

  /**
   * Handler for scenario where stripe isn't able to return a token from a credit card.
   * @param error
   */
  const handleOnTokenFailed = (error: StripeError) => {
    console.error(error);
    toast.error('There was an error getting the stripe token.');
  }

  /**
   * Check to make sure that the patient billing information can be saved.
   */
  const canSave = (token?: string): Boolean => {
    if (paymentMethod !== PaymentMethod.UNKNOWN
      && ((paymentToken || token) || paymentMethod === PaymentMethod.PATIENT)
      && !isSaving) {
      return true;
    }

    toast.error('Please make sure all required fields are present before saving.');
    return false;
  }

  /**
   * If all required fields are present then save the billing information.
   */
  const savePatientBillingInfo = async (token?: string) => {
    const canSaveInfo = canSave(token);
    if (canSaveInfo) {
      setIsSaving(true);
      if (props.overrideSave) {
        return toast.promise(props.overrideSave(token || paymentToken), {
          success: 'Successfully added payment information.',
          loading: 'Saving payment information.',
          error: e => {
            setIsSaving(false);
            const msg = 'There was an error saving the billing information.';
            const errorMsg = e.response?.data?.error?.toString?.();
            return <p>{msg} {!!errorMsg && <p>{errorMsg}</p>}</p>;
          }
        }, {
          id: 'billing-toast'
        })
      } else {
        toast.promise(createPatientBilling(practiceId, props.patientId, props.patientName!, props.patientEmail!, paymentMethod, paymentToken || token!, '', props.selectedPaymentFrequency, ''), {
          loading: 'Saving information',
          success: savedResponse => {
            setSaveComplete(true);
            if (props.onSaveSuccess) {
              toast.promise(props.onSaveSuccess(savedResponse), {
                error: e => {
                  setIsSaving(false);
                  const msg = 'There was an error saving the billing information.';
                  const errorMsg = e.response?.data?.error?.toString?.();
                  return <p>{msg} {!!errorMsg && <p>{errorMsg}</p>}</p>;
                },
                loading: 'Saving medical history',
                success: d => {
                  props.history.push(`/thankyou?medicalHistory=true&practiceId=${practiceId}${language !== 'default' ? `&language=${language}` : ''}`);
                  return 'Saved successfully!'
                }
              }, {
                id: 'billing-toast'
              });
            }
            return 'Payment info has been saved';
          },
          error: e => {
            setIsSaving(false);
            const msg = 'There was an error saving the billing information.';
            const errorMsg = e.response?.data?.error?.toString?.();
            return <p>{msg} {!!errorMsg && <p>{errorMsg}</p>}</p>;
          }
        }, {
          id: 'billing-toast'
        })
      }
    }
  }

  return (
    <div>
      <PatientBillingACH onTokenReceived={handleOnTokenReceived} patientId={props.patientId} linkToken={linkToken} onSuccessfulLink={savePatientBillingInfo} />
      <span>
        {!props.small && <h5 className="text-dark-grey text-center">{signup.creditCardExplanation[language]}</h5>}
        {paymentMethod !== PaymentMethod.CARD && (
          <Button
            look="flat"
            className="pl-lg-1 pl-0 text-grey w-100"
            onClick={() => setPaymentMethod(PaymentMethod.CARD)}
            data-test={selectors.patients.showCreditCardBtn}
          >
            {signup.creditCardBtnText[language]}
          </Button>)}
      </span>
      {!saveComplete && paymentMethod === PaymentMethod.CARD && (
        <Row>
          <Col xs={12}>
            <Elements stripe={stripePromise}>
              <PatientBillingCard onTokenReceived={handleOnTokenReceived} onTokenFailed={handleOnTokenFailed} />
            </Elements>
          </Col>
          <Col className="d-flex align-items-start keenhp-credit-card" xs={12}>
            <Button
              className='saveButton mt-sm'
              icon="save"
              disabled={isSaving}
              onClick={() => savePatientBillingInfo()}
              data-test={selectors.patients.savePaymentBtn}
            >
              Submit
            </Button>
            <Button className="mt-sm" icon="close-circle" look="flat" onClick={() => setPaymentMethod(PaymentMethod.ACH)}>
              Cancel
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default withRouter(NewPatientBilling);
