import { AmplifySignIn } from '@aws-amplify/ui-react';
import { Auth, Hub } from 'aws-amplify';
import React, { useState } from 'react';

const AUTH_CHANNEL_KEY = 'UI Auth';

export const handleAuthStateChange = (type: string, data: any) => {
  Hub.dispatch(AUTH_CHANNEL_KEY, {
    event: 'AuthStateChange',
    message: type,
    data,
  });
}

export const dispatchErrorToastHubEvent = (error: Error) => {
  Hub.dispatch(AUTH_CHANNEL_KEY, {
    event: 'ToastAuthError',
    message: error.message,
  });
}

export const handleSubmit = async (event: Event, username: string, password: string, setPassword: Function) => {
  event.preventDefault();

  Auth.signIn(username, password)
    // response type any comes from the aws docs
    .then((response: any) => {
      // clear the password
      setPassword('');

      // TODO when writing unit tests make this a switch statement + pull out strings into const
      // handle all possible responses from aws-amplify/amplify-confirm-sign-in_8.cjs.entry
      if (response.challengeName === 'SMS_MFA' || response.challengeName === 'SOFTWARE_TOKEN_MFA') {
        console.debug('confirm user with ' + response.challengeName);
        handleAuthStateChange('confirmSignIn', response);
      } else if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
        console.debug('require new password', response.challengeParam);
        handleAuthStateChange('resettingpassword', response);
      } else if (response.challengeName === 'MFA_SETUP') {
        console.debug('TOTP setup', response.challengeParam);
        handleAuthStateChange('TOTPSetup', response);
      } else if (response.challengeName === 'CUSTOM_CHALLENGE' &&
        response.challengeParam &&
        response.challengeParam.trigger === 'true') {
        console.debug('custom challenge', response.challengeParam);
        handleAuthStateChange('customConfirmSignIn', response);
      } else {
        Auth.verifiedContact(response)
          .then((d: { verified: any, unverified: any }) => {
            if (d.verified) {
              handleAuthStateChange('signedin', response);
            } else {
              response = Object.assign(response, d);
              handleAuthStateChange('verifyContact', response);
            }
          });
      }
    })
    .catch((error: any) => {
      if (error.code === 'UserNotConfirmedException') {
        console.debug(`${username} is not confirmed`);
        handleAuthStateChange('confirmSignUp', { username });
      } else if (error.code === 'PasswordResetRequiredException') {
        console.debug(`${username} requires a new password`);
        handleAuthStateChange('forgotpassword', { username });
      } else {
        dispatchErrorToastHubEvent(error);
      }
    });
}

export const KeenSignIn = (props: any) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const addArc = () => {
    const element = document.getElementById('keenhp-login-screen');
    if (!element?.classList.contains('keenhp-green-arc')) element?.classList.add('keenhp-green-arc');
  };

  return (
    <AmplifySignIn
      headerText={props.headerText}
      slot="sign-in"
      handleSubmit={(event: Event) => {
        if (!username || !password) return dispatchErrorToastHubEvent(new Error('Please enter your username and password'));
        handleSubmit(event, username, password, setPassword);
      }}
      formFields={[
        {
          type: 'username',
          placeholder: 'Username',
          value: username,
          label: '',
          handleInputChange: (e: any) => setUsername(e.target.value),
        }, {
          type: 'password',
          placeholder: 'Password',
          label: '',
          value: password,
          handleInputChange: (e: any) => setPassword(e.target.value),
        }
      ]}
    >
      {addArc()}
      <div slot="secondary-footer-content" className="" />
    </AmplifySignIn>
  );
};

export default KeenSignIn;
