import API from './aero-api';
import { API_NAMES } from './api-names';
import { redactKey } from './redact-key';

const logPath = '/v1/logs';

// TODO -- need to batch these somehow -- combination of timer and # of logs -- will do later
export const log = {
  info: (msg: LogInput) => {
    try {
      const currentPage = window.location;
      const locationPracticeId = currentPage.href?.split('/#/')[1]?.split('/')?.[0];
      let practiceId: string | undefined = undefined;
      if (locationPracticeId?.startsWith('aerodpc.pid.') || locationPracticeId.startsWith('keenhp.pid.') || locationPracticeId === 'test-practice') {
        practiceId = locationPracticeId;
      }
      redactKey(msg, 'authorization');
      return API.post(API_NAMES.LOGGER, logPath, {
        body: [{
          practiceId,
          msg,
          timestamp: new Date().toISOString(),
          level: 'info'
        }]
      });
    } catch (error) {
      console.log(error);
    }
  },
  warn: (msg: LogInput) => {
    try {
      const currentPage = window.location;
      const locationPracticeId = currentPage.href?.split('/#/')[1]?.split('/')?.[0];
      let practiceId: string | undefined = undefined;
      if (locationPracticeId?.startsWith('aerodpc.pid.') || locationPracticeId.startsWith('keenhp.pid.') || locationPracticeId === 'test-practice') {
        practiceId = locationPracticeId;
      }
      redactKey(msg, 'authorization');
      return API.post(API_NAMES.LOGGER, logPath, {
        body: [{
          practiceId,
          msg,
          timestamp: new Date().toISOString(),
          level: 'warn'
        }]
      });
    } catch (error) {
      console.log(error);
    }
  },
  error: (msg: LogInput) => {
    try {
      const currentPage = window.location;
      const locationPracticeId = currentPage.href?.split('/#/')[1]?.split('/')?.[0];
      let practiceId: string | undefined = undefined;
      if (locationPracticeId?.startsWith('aerodpc.pid.') || locationPracticeId.startsWith('keenhp.pid.') || locationPracticeId === 'test-practice') {
        practiceId = locationPracticeId;
      }
      redactKey(msg, 'authorization');
      return API.post(API_NAMES.LOGGER, logPath, {
        body: [{
          practiceId,
          msg,
          timestamp: new Date().toISOString(),
          level: 'error'
        }]
      });
    } catch (error) {
      console.log(error);
    }
  }
};

interface LogInput extends Object { }
