import React from 'react';
import { SvgIconInterface } from '../AeroLogo';

const VisaIcon = ({ fill, height, width}: SvgIconInterface) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 41.09 13.3">
    <path fill={fill} d="M11.7.24L8.44 9.02l-.36-1.78-1.16-5.91C6.73.51 6.15.27 5.42.24H.06L0 .5c1.31.33 2.48.81 3.5 1.42L6.46 13.1h3.52L15.22.24H11.7zM26.6 5.4c-1.17-.59-1.88-.99-1.88-1.59.01-.55.6-1.11 1.91-1.11a5.63 5.63 0 012.48.49l.3.14.46-2.78A8.24 8.24 0 0026.88 0c-3.29 0-5.6 1.76-5.62 4.26-.02 1.85 1.66 2.88 2.92 3.5 1.28.63 1.72 1.04 1.72 1.6-.02.86-1.05 1.26-2 1.26-1.33 0-2.04-.21-3.12-.69l-.44-.21-.46 2.89c.78.36 2.22.67 3.71.69 3.5.01 5.77-1.72 5.8-4.39.02-1.47-.88-2.59-2.79-3.51zM16.56.24l-2.08 12.87h3.32L19.89.24zM35.83.24c-.79 0-1.4.23-1.74 1.07l-4.95 11.8h3.5s.57-1.59.7-1.93h4.27c.1.46.4 1.93.4 1.93h3.08L38.4.24h-2.57zM34.3 8.55c.27-.74 1.33-3.6 1.33-3.6-.02.03.27-.75.44-1.23l.23 1.11s.63 3.08.77 3.73H34.3z"/>
  </svg>
)

export default VisaIcon;
