import { Form, FormElement } from '@progress/kendo-react-form';
import React from 'react';
import { useAsync } from 'react-async-hook';
import { Row } from 'react-bootstrap';
import AeroError from '../../../components/AeroError';
import KeenSpinner from '../../../components/KeenSpinner';
import { NewPatientMedicalHistory } from '../../../components/patients/NewPatientMedicalHistory';
import { getPatientMedicalHistory } from '../../services/patients-service';
import { formatMedicalHistory } from './converters';

export interface ViewMedicalHistoryProps {
  patientId?: string;
}

const ViewMedicalHistory: React.SFC<ViewMedicalHistoryProps> = (props) => {
  const req = useAsync(getPatientMedicalHistory, [props.patientId]);

  if (req.loading) return <KeenSpinner />;
  if (req.error) return <AeroError error={req.error} />;

  return (
    <Row className="w-100 p-3 align-items-center justify-content-center">
      <Form
        initialValues={formatMedicalHistory(req.result)}
        render={formRenderProps => (
          <FormElement>
            <NewPatientMedicalHistory {...formRenderProps} disabled />
          </FormElement>
        )}
      />
    </Row>
  );
}

export default ViewMedicalHistory;
