export const STRIPE_PUBLISHABLE_KEY = 'pk_test_51HHzKEJO5Cpjpf4wKHwDKIMIvcL6MeZ0FXnbfZxhcpwJdtRKRoQncSH1eBJBZA1S7GwfFJpeedHwXEZPKMkAcTVm00mvfApYpo';
export const STRIPE_PROD_PUBLISHABLE_KEY = 'pk_live_51HHzKEJO5Cpjpf4wsdqT8bN1hnX6zOxiMlIv09WqRfK0EFU2xGeeCGB8Sjp1DaCejAn6bg7jj8QtWYpNHbLCzMM600i3jHl5Fx';
export const PLAID_ENV = 'sandbox';
export const PLAID_PROD_ENV = 'production';

export function getStripePublishableKey() {
  let key = STRIPE_PUBLISHABLE_KEY;
  if (window?.location?.origin?.includes?.('patients.aerodpc')) {
    key = STRIPE_PROD_PUBLISHABLE_KEY;
  }
  return key;
}

export function getPlaidEnvironment() {
  let env = PLAID_ENV;
  if (window?.location?.origin?.includes?.('patients.aerodpc')) {
    env = PLAID_PROD_ENV;
  }
  return env;
}
