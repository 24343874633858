import { COMMON_DRUGS, selectors } from '@keenhp/website-common';
import { Button } from '@progress/kendo-react-buttons';
import { Form, FormElement, FormRenderProps, FormSubmitClickEvent } from '@progress/kendo-react-form';
import { Stepper } from '@progress/kendo-react-layout';
import { ChunkProgressBar } from '@progress/kendo-react-progressbars';
import React, { useCallback, useState } from 'react';
import { Row } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { RouteComponentProps, withRouter } from 'react-router';
import useLanguage, { SupportedLanguages } from '../../hooks/use-language';
import { signup } from '../../intl/signup';
import { savePatientFamilyHistory, savePatientMedical, savePatientMedicalHistory, savePatientSocialHistory } from '../../services/patient-service';
import { buildPatientFamilyHistory, buildPatientMedical, buildPatientMedicalHistory, buildPatientSocial } from './PatientsUtil';

const scrollToTop = () => document.getElementById('external-app-wrapper')?.scrollTo(0, 0);

export type SimpleMedHistoryWizardProps = RouteComponentProps & {
  steps: any[];
  stepPages: any[];
  practiceId: string;
  patientId: string;
  onLastSubmit: Function;
}

const CHUNK_COUNT = 6;

export const SimpleMedHistoryWizard = (props: SimpleMedHistoryWizardProps) => {
  const { onLastSubmit, practiceId } = props;
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [saveProgress, setSaveProgress] = useState(1);
  const [formState, setFormState] = useState<any>({
    topDrugs: COMMON_DRUGS,
  });
  const [steps, setSteps] = useState(props.steps);

  let frp: FormRenderProps;
  const language = useLanguage();

  React.useEffect(() => {
    if (frp) {
      frp.onChange('language', { value: language });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const reviewIndex = steps.length - 1;
  const isReviewStep = reviewIndex === step;
  const isPreviousStepsValid = steps
    .slice(0, step)
    .findIndex(currentStep => currentStep.isValid === false) === -1;

  const onStepSubmit = useCallback(async (event: FormSubmitClickEvent) => {
    const { isValid, values } = event;

    const currentSteps = steps.map((currentStep, index) => ({
      ...currentStep,
      isValid: index === step ? isValid : currentStep.isValid
    }));

    // set the form values
    setFormState(values);
    console.debug(values);
    setSteps(currentSteps);

    if (!currentSteps[step].isValid) {
      return toast.error('Please fill out each required field.');
    };

    if (isReviewStep && isPreviousStepsValid && isValid) {
      console.log('isReviewStep && isPreviousStepsValid && isValid');
      setLoading(true);
      try {
        setSaveProgress(2);
        const patientMedical = await savePatientMedical(buildPatientMedical({ sk: props.patientId, practiceId: practiceId }, values));
        console.debug('Keen: onStepSubmit -> patientMedical', patientMedical);
        setSaveProgress(3);
        const medicalHistory = await savePatientMedicalHistory(buildPatientMedicalHistory({ sk: props.patientId, practiceId: practiceId }, values));
        console.debug('Keen: onStepSubmit -> medicalHistory', medicalHistory);
        setSaveProgress(4);
        const social = await savePatientSocialHistory(buildPatientSocial({ sk: props.patientId, practiceId: practiceId }, values));
        console.debug('Keen: onStepSubmit -> social', social);
        setSaveProgress(5);
        const family = await savePatientFamilyHistory(buildPatientFamilyHistory({ sk: props.patientId, practiceId: practiceId }, values));
        console.debug('Keen: onStepSubmit -> family', family);
        setSaveProgress(6);
        setLoading(false);
        console.debug('done saving all patient apis');
        onLastSubmit();
      } catch (error) {
        console.error(error);
        setLoading(false);
        return toast.error('There was an error saving the patient. ' + error);
      }
    }
    // finally increment the step
    scrollToTop();
    setStep(() => Math.min(step + 1, reviewIndex + 1));
  },
    [steps, step, isReviewStep, isPreviousStepsValid, props.patientId, practiceId, onLastSubmit, reviewIndex]
  );

  const onPrevClick = useCallback((event) => {
    event.preventDefault();
    scrollToTop();
    setStep(() => Math.max(step - 1, 0));
  },
    [step, setStep]
  );

  return (
    <div className="d-flex flex-column w-100">
      <div className="d-flex flex-column w-100 pt-2 pb-3" style={{ backgroundColor: '#e6eaed', zIndex: 100 }}>
        <Stepper value={step} items={props.steps} />
      </div>
      <div className="d-flex flex-column w-100">
        {loading && (
          <>
            <Row className="w-100 justify-content-center align-items-center">
              <h4>Saving {formState.firstName}{formState.middleName ? ` ${formState.middleName}` : ''} {formState.lastName} Information</h4>
            </Row>
            <Row className="w-100 justify-content-center align-items-center">
              <ChunkProgressBar value={100 * saveProgress / CHUNK_COUNT} chunkCount={CHUNK_COUNT} />
            </Row>
          </>
        )}
        {!loading && step <= reviewIndex &&
          <Form
            initialValues={formState}
            onSubmitClick={onStepSubmit}
            render={(formRenderProps) => {
              frp = formRenderProps;
              return (
                <div className="align-self-center" id="new-patient-form-content">
                  <FormElement>
                    {typeof props.stepPages[step] === 'function'
                      ? props.stepPages[step](formRenderProps)
                      : props.stepPages[step]
                    }
                    <span style={{ marginTop: '40px' }} className={'k-form-separator'} />
                    <div className="k-form-buttons k-buttons-end justify-content-between align-content-center mb-4">
                      <span style={{ alignSelf: 'center' }}>
                        {signup.step[frp.valueGetter('language') as SupportedLanguages]} {step + 1} {signup.of[frp.valueGetter('language') as SupportedLanguages]} {steps.length}
                      </span>
                      <div>
                        {
                          step !== 0 ? (
                            <Button style={{ marginRight: '16px' }} onClick={onPrevClick} look="flat" icon="chevron-left" data-test={selectors.patients.wizardPrevBtn}>
                              {signup.back[frp.valueGetter('language') as SupportedLanguages]}
                            </Button>
                          ) : null
                        }
                        <Button
                          disabled={isReviewStep ? !isPreviousStepsValid : false}
                          onClick={formRenderProps.onSubmit}
                          data-test={selectors.patients.wizardNextBtn}
                          icon={isReviewStep ? 'save' : 'chevron-right'}
                        >
                          {isReviewStep ? signup.save[frp.valueGetter('language') as SupportedLanguages] : signup.next[frp.valueGetter('language') as SupportedLanguages]}
                        </Button>
                      </div>
                    </div>
                  </FormElement>
                </div>
              )
            }}
          />
        }
      </div>
    </div>
  );
}
export default withRouter(SimpleMedHistoryWizard);
