import { selectors } from '@keenhp/website-common';
import { Button, DropDownButton, DropDownButtonItem } from '@progress/kendo-react-buttons';
import { Field, Form, FormRenderProps } from '@progress/kendo-react-form';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { Cache } from 'aws-amplify';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { FormCheckbox, FormComboBox, FormDropDownList, FormInput, FormMaskedTextBox, FormRadioGroup, FormSwitch } from '../components/FormComponents';
import { dobValidator, emailValidator, requiredPhoneValidator } from '../components/patients/NewPatientProfile';
import { buildPatientProfile } from '../components/patients/PatientsUtil';
import useLanguage, { SupportedLanguages } from '../hooks/use-language';
import { states } from '../interfaces/Address.interface';
import { LANGUAGE_NAME_TO_ABRV } from '../intl/languages';
import { signup } from '../intl/signup';
import { savePatientProfile } from '../services/patient-service';
import { requiredFieldValidator } from '../util/validators';
import { NEW_PATIENT_KEY } from './MultiPatientSetup';

interface SimplePatientSignupProps { }

const SimplePatientSignup: React.FC<SimplePatientSignupProps> = () => {
  const match = useRouteMatch<{ practiceId: string }>();
  const practiceId = match.params.practiceId;
  const language = useLanguage();
  const history = useHistory();
  const formsRef = React.useRef<Array<Form | null>>([]);
  const [patients, setPatients] = React.useState<any[]>([{}]);
  const [sameAddress, setSameAddress] = React.useState(false);
  const [sameEmail, setSameEmail] = React.useState(false);
  const [samePhoneNumber, setSamePhoneNumber] = React.useState(false);
  const [sameInsuranceCompany, setSameInsuranceCompany] = React.useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const companyId = params.get('companyId') || '';
  const companyName = params.get('companyName') || '';

  React.useEffect(() => {
    Cache.clear();
  }, []);

  return (
    <div className="h-100-not-mobile w-100 py-2">
      <Row className="h-100-not-mobile w-100">
        <Col xs={{ span: 12, order: 1 }} md={{ span: 3, order: 0 }} className="h-100-not-mobile margin-top-mobile">
          <div className="px-1 mb-1 border-bottom border-primary mb-3">
            <h4>{signup.patient?.[language || 'default'] + 's'}</h4>
            {patients?.length ? patients.map((patient, i) => {
              return <div className="d-flex justify-content-between" key={`key-${i}`}>
                {patient.firstName ? <span>
                  {patient.firstName}
                  {patient.dob ? <span>{' '}({patient.dob})</span> : <span>{' '}(__/__/____)</span>}
                </span> : <span>{signup.patient[language!]} #{i + 1}</span>}
                {i > 0 ? <Button look="flat" onClick={e => { e.preventDefault(); setPatients(p => { p.splice(i, 1); return [...p] }); }} icon="trash" className="text-danger" /> : null}
              </div>
            }) : <span>{signup.patient[language]} #1</span>}
          </div>
          <div className="w-100 d-flex justify-content-between">
            <Label>{signup.sameAddressText[language]}</Label>
            <Checkbox className="pl-1" checked={sameAddress} onChange={e => setSameAddress(e.value)} id={selectors.patients.sameAddressSwitch} />
          </div>
          <div className="w-100 d-flex justify-content-between">
            <Label>{signup.sameEmailText[language]}</Label>
            <Checkbox className="pl-1" checked={sameEmail} onChange={e => setSameEmail(e.value)} />
          </div>
          <div className="w-100 d-flex justify-content-between">
            <Label>{signup.samePhoneNumberText[language]}</Label>
            <Checkbox className="pl-1" checked={samePhoneNumber} onChange={e => setSamePhoneNumber(e.value)} />
          </div>
          <div className="w-100 d-flex justify-content-between">
            <Label>{signup.sameInsuranceText[language]}</Label>
            <Checkbox className="pl-1" checked={sameInsuranceCompany} onChange={e => setSameInsuranceCompany(e.value)} />
          </div>
          <Button icon="plus" className="w-100" data-test={selectors.patients.addPatient} onClick={e => { e.preventDefault(); setPatients(p => [...formsRef.current?.filter(r => !!r)?.map(frp => frp?.values), {}]); }}>{signup[selectors.patients.addPatient][language]}</Button>
        </Col>
        <Col xs={12} md={9} className="h-100-not-mobile bg-white shadow-sm rounded pt-2" style={{ overflow: 'auto', }}>
          <h4 className="d-flex justify-content-between mb-0">
            {signup.title?.[language || 'default'] || 'Patient Registration'}
            <DropDownButton icon="globe" onItemClick={e => {
              if (companyId) {
                return history.push({
                  search: `?language=${(LANGUAGE_NAME_TO_ABRV as any)[e.item.text]}&companyId=${companyId}&companyName=${companyName}`,
                });
              }
              history.push({
                search: `?language=${(LANGUAGE_NAME_TO_ABRV as any)[e.item.text]}`,
              });
            }}>
              <DropDownButtonItem text="English" />
              <DropDownButtonItem text="Español" />
            </DropDownButton>
          </h4>
          {companyId && companyName ? <div>
            <span>{signup.employeeDescription?.[language || 'default'] || 'This first patient will be registered as an employee of '} <span className="font-weight-bold">"{companyName}"</span></span>
          </div> : null}
          {
            patients.map((pat, i) => {
              return <Form
                key={`patient-form-${pat.firstName + pat.lastName + i || i}`}
                initialValues={pat}
                onSubmit={() => null}
                onSubmitClick={() => null}
                ref={el => formsRef.current[i] = el}
                render={frp => {
                  return (
                    <div className="border-bottom border-primary pb-1 mb-2" data-test={selectors.patients.newPatientFormWrapper + i}>
                      <SimplePatientForm formRenderProps={frp} language={language} count={i} hideAddress={i > 0 && sameAddress} hideEmail={i > 0 && sameEmail} hidePhoneNumber={i > 0 && samePhoneNumber} hideInsurance={i > 0 && sameInsuranceCompany} />
                      {i > 0 ? <Button icon="trash" look="flat" className="w-100 mt-2 text-danger" onClick={e => { e.preventDefault(); setPatients(p => { p.splice(i, 1); return [...p] }); }}>{signup[selectors.patients.removePatient][language!]}</Button> : null}
                    </div>
                  );
                }}
              />
            })
          }
          <Row className="w-100 py-1">
            <Col>
              <Button
                icon="plus"
                className="w-100"
                onClick={e => { e.preventDefault(); setPatients(p => [...formsRef.current?.filter(r => !!r)?.map(frp => frp?.values), {}]); }}
              >
                {signup[selectors.patients.addPatient][language]}
              </Button>
            </Col>
            <Col>
              <Button
                icon="chevron-right"
                className="w-100 btn btn-success"
                data-test={selectors.patients.saveAndContinueToBillingBtn}
                onClick={e => {
                  e.preventDefault();
                  // check all the forms
                  const valid = formsRef.current?.filter(r => !!r)?.map(f => {
                    f?.onSubmit({} as any);
                    return f?.isValid?.();
                  }).every(x => !!x);
                  if (!valid) return alert('Some of the required fields are incorrect or missing. Please ensure you have filled out all the required fields and click Continue again.');
                  // NEXT UP: test this with multiple patients being created and make sure they are all linked
                  toast.promise(Promise.all(formsRef.current?.filter(x => !!x).map((f, i) => {
                    return savePatientProfile(practiceId, buildPatientProfile({
                      ...f?.values,
                      companyId: i === 0 ? companyId : undefined,
                      gender: f?.values?.[`gender${i}`],
                      phoneNumber: samePhoneNumber ? formsRef.current?.[0]?.values?.phoneNumber : f?.values.phoneNumber,
                      email: sameEmail ? formsRef.current?.[0]?.values?.email : f?.values.email,
                      addressLine1: sameAddress ? formsRef.current?.[0]?.values?.addressLine1 : f?.values?.addressLine1,
                      addressLine2: sameAddress ? formsRef.current?.[0]?.values?.addressLine2 : f?.values?.addressLine2,
                      state: sameAddress ? formsRef.current?.[0]?.values?.state : f?.values?.state,
                      zipCode: sameAddress ? formsRef.current?.[0]?.values?.zipCode : f?.values?.zipCode,
                      city: sameAddress ? formsRef.current?.[0]?.values?.city : f?.values?.city,
                      primaryInsurance: sameInsuranceCompany ? formsRef.current?.[0]?.values?.primaryInsurance : f?.values.primaryInsurance,
                    }));
                  })), {
                    loading: 'Loading...',
                    success: (d: any) => {
                      const savedPatients = d.map((pat: any) => {
                        pat.saved = { ...pat };
                        return pat;
                      });
                      Cache.setItem(NEW_PATIENT_KEY, savedPatients);
                      // go to the new billings page
                      history.push(`/${practiceId}/patients/new/billing${language !== 'default' ? `?language=${language}` : ''}`);
                      return 'Please enter your billing information.';
                    },
                    error: e => 'It looks like there was an issue. Please ensure you have filled out all the required fields and click Continue again',
                  })
                }}
              >
                {signup[selectors.patients.saveAndContinueToBillingBtn][language]}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default SimplePatientSignup;

const SimplePatientForm = (props: { formRenderProps: FormRenderProps; language?: SupportedLanguages; count: number; hidePhoneNumber?: boolean; hideEmail?: boolean; hideAddress?: boolean; hideInsurance?: boolean; }) => {
  const language = props.language && props.language === 'es' ? props.language : 'default';
  return (
    <Row>
      <Col xs={12}>
        <Row className="w-100 pt-1">
          <Col xs={12} md={6} className="pt-1">
            <Field
              key="firstName"
              id={"firstName" + props.count}
              name="firstName"
              autoComplete="given-name"
              data-test={selectors.patients.firstName}
              label={signup[selectors.patients.firstName][language]}
              component={FormInput}
              required
              validator={requiredFieldValidator}
            />
          </Col>
          <Col xs={12} md={6} className="pt-1">
            <Field
              key="middleName"
              id={"middleName" + props.count}
              name="middleName"
              autoComplete="additional-name"
              data-test={selectors.patients.middleName}
              label={signup[selectors.patients.middleName][language]}
              component={FormInput}
            />
          </Col>
          <Col xs={12} md={6} className="pt-1">
            <Field
              key="lastName"
              id={"lastName" + props.count}
              name="lastName"
              autoComplete="family-name"
              data-test={selectors.patients.lastName}
              label={signup[selectors.patients.lastName][language]}
              component={FormInput}
              required
              validator={requiredFieldValidator}
            />
          </Col>
          <Col xs={12} md={6} className="pt-1">
            <Field
              key="preferredName"
              id={"preferredName" + props.count}
              name="preferredName"
              autoComplete="preferred-name"
              data-test={selectors.patients.preferredName}
              label={signup[selectors.patients.preferredName][language]}
              component={FormInput}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Row className="w-100 pt-1">
          <Col xs={12} md={6} className="pt-1">
            <Field
              key="dob"
              id={"dob" + props.count}
              name="dob"
              label={signup[selectors.patients.dob][language]}
              data-test={selectors.patients.dob}
              component={FormMaskedTextBox}
              mask="00/00/0000"
              placeholder="MM/DD/YYYY"
              autoComplete="bday"
              includeLiterals={false}
              required
              validator={dobValidator}
            />
          </Col>
          <Col xs={12} md={6} className="pt-1">
            <Field
              key="gender"
              id={"gender" + props.count}
              name={"gender" + props.count}
              label={signup[selectors.patients.gender][language]}
              component={FormRadioGroup}
              data={signup.genders[language]}
              layout="horizontal"
              validator={requiredFieldValidator}
            />
          </Col>
          <Col xs={12} md={6} className="pt-1">
            <Field
              key="pronouns"
              id={"pronouns" + props.count}
              name="pronouns"
              data-test={selectors.patients.pronouns}
              label={signup[selectors.patients.pronouns][language]}
              component={FormInput}
            />
          </Col>
          <Col xs={12} md={6} className="pt-1">
            <Field
              key="allowGenderIdentity"
              id={"allowGenderIdentity" + props.count}
              name="allowGenderIdentity"
              label={signup[selectors.patients.allowGenderIdentity][language]}
              defaultChecked={!!props.formRenderProps.valueGetter('genderIdentity')}
              data-test={selectors.patients.allowGenderIdentity}
              component={FormCheckbox}
            />
            {props.formRenderProps.valueGetter('allowGenderIdentity') || (props.formRenderProps.valueGetter('allowGenderIdentity') === undefined && !!props.formRenderProps.valueGetter('genderIdentity')) ? (
              <Field
                key="genderIdentity"
                id={"genderIdentity" + props.count}
                name="genderIdentity"
                data-test={selectors.patients.genderIdentity}
                label={""}
                className="pt-2"
                component={FormComboBox}
                data={signup.genderIdentities[language]}
                allowCustom
              />
            ) : null}
          </Col>
          {!props.hideEmail ? <Col xs={12} md={6} className="pt-1">
            <Field
              key="email"
              id={"email" + props.count}
              name="email"
              autoComplete="email"
              data-test={selectors.patients.email}
              label={signup[selectors.patients.email][language]}
              component={FormInput}
              required
              validator={emailValidator}
            />
          </Col> : null}
          {!props.hidePhoneNumber ? <Col xs={12} md={6} className="pt-1">
            <Field
              key="phoneNumber"
              id={"phoneNumber" + props.count}
              name="phoneNumber"
              data-test={selectors.patients.phoneNumber}
              label={signup[selectors.patients.phoneNumber][language]}
              mask="000-000-0000"
              placeholder="###-###-####"
              includeLiterals={false}
              component={FormMaskedTextBox}
              required
              validator={requiredPhoneValidator}
            />
          </Col> : null}
        </Row>
      </Col>
      {!props.hideAddress ? <Col xs={12}>
        <Row className="w-100 pt-1">
          <Col xs={12} md={6} className="pt-1">
            <Field
              key="addressLine1"
              id={"addressLine1" + props.count}
              name="addressLine1"
              data-test={selectors.patients.addressLine1}
              label={signup[selectors.patients.addressLine1][language]}
              autoComplete="address-line1"
              component={FormInput}
              required
              validator={requiredFieldValidator}
            />
          </Col>
          <Col xs={12} md={6} className="pt-1">
            <Field
              key="addressLine2"
              id={"addressLine2" + props.count}
              name="addressLine2"
              data-test={selectors.patients.addressLine2}
              label={signup[selectors.patients.addressLine2][language]}
              autoComplete="address-line2"
              component={FormInput}
            />
          </Col>
          <Col xs={12} md={6} className="pt-1">
            <Field
              key="city"
              id={"city" + props.count}
              name="city"
              data-test={selectors.patients.city}
              label={signup[selectors.patients.city][language]}
              autoComplete="address-level2"
              component={FormInput}
              required
              validator={requiredFieldValidator}
            />
          </Col>
          <Col xs={12} md={6} lg={3} className="pt-1 state-selector-field">
            <Label
              id={"label-state" + props.count}
              editorId={"state" + props.count}
            >
              {signup[selectors.patients.state][language]}
            </Label>
            <Field
              key="state"
              id={"state" + props.count}
              name="state"
              data-test={selectors.patients.state}
              autoComplete="address-level1"
              component={FormDropDownList}
              data={states}
              defaultValue={signup.selectState[language]}
              required
              validator={requiredFieldValidator}
            />
          </Col>
          <Col xs={12} md={6} lg={3} className="pt-1">
            <Field
              key="zipCode"
              id={"zipCode" + props.count}
              name="zipCode"
              data-test={selectors.patients.zipCode}
              label={signup[selectors.patients.zipCode][language]}
              autoComplete="postal-code"
              component={FormInput}
              required
              validator={requiredFieldValidator}
            />
          </Col>
        </Row>
      </Col> : null}
      {!props.hideInsurance ? (
        <Col xs={12} className="pt-1">
          {props.formRenderProps.valueGetter('primaryInsurance') ? (
            <Row className="mt-2 pt-1 pl-3 border-top">
              <Col className="pt-1" xs={12}>
                <h5>{signup.patientInsurance[language]} <Button className="text-danger" onClick={e => { e.preventDefault(); props.formRenderProps.onChange('primaryInsurance', { value: undefined }); }} look="flat" icon="trash" /></h5>
              </Col>
              <Col className="pt-1" xs={12} lg={6}>
                <Field
                  key="primaryInsurance.payerName"
                  id={"primaryInsurance.payerName" + props.count}
                  name="primaryInsurance.payerName"
                  data-test={selectors.patients.primaryInsurance.payerName}
                  label={signup[selectors.patients.primaryInsurance.payerName][language]}
                  includeLiterals={false}
                  component={FormInput}
                  required
                  validator={requiredFieldValidator}
                />
              </Col>
              <Col className="pt-1" xs={12} lg={6}>
                <Field
                  key="primaryInsurance.memberId"
                  id={"primaryInsurance.memberId" + props.count}
                  name="primaryInsurance.memberId"
                  data-test={selectors.patients.primaryInsurance.memberId}
                  label={signup[selectors.patients.primaryInsurance.memberId][language]}
                  component={FormInput}
                  required
                  validator={requiredFieldValidator}
                />
              </Col>
              <Col className="pt-1" xs={12} lg={6}>
                <Field
                  key="primaryInsurance.groupId"
                  id={"primaryInsurance.groupId" + props.count}
                  name="primaryInsurance.groupId"
                  data-test={selectors.patients.primaryInsurance.groupId}
                  label={signup[selectors.patients.primaryInsurance.groupId][language]}
                  component={FormInput}
                />
              </Col>
              <Col className="pt-1" xs={12} lg={6}>
                <Field
                  key="primaryInsurance.payerPhoneNumber"
                  id={"primaryInsurance.payerPhoneNumber" + props.count}
                  name="primaryInsurance.payerPhoneNumber"
                  data-test={selectors.patients.primaryInsurance.payerPhoneNumber}
                  label={signup[selectors.patients.primaryInsurance.payerPhoneNumber][language]}
                  mask="000-000-0000"
                  component={FormMaskedTextBox}
                />
              </Col>
              <Col className="pt-1" xs={12} lg={6}>
                <Field
                  key="primaryInsurance.payerAddress.addressLine1"
                  id={"primaryInsurance.payerAddress.addressLine1" + props.count}
                  name="primaryInsurance.payerAddress.addressLine1"
                  data-test={selectors.patients.primaryInsurance.payerAddress.addressLine1}
                  label={signup[selectors.patients.primaryInsurance.payerAddress.addressLine1][language]}
                  component={FormInput}
                  required
                  validator={requiredFieldValidator}
                />
              </Col>
              <Col className="pt-1" xs={12} lg={6}>
                <Field
                  key="primaryInsurance.payerAddress.addressLine2"
                  id={"primaryInsurance.payerAddress.addressLine2" + props.count}
                  name="primaryInsurance.payerAddress.addressLine2"
                  data-test={selectors.patients.primaryInsurance.payerAddress.addressLine2}
                  label={signup[selectors.patients.primaryInsurance.payerAddress.addressLine2][language]}
                  component={FormInput}
                />
              </Col>
              <Col className="pt-1" xs={12}>
                <Row noGutters>
                  <Col className="pt-1" sm={12} lg={6}>
                    <Field
                      key="primaryInsurance.payerAddress.city"
                      id={"primaryInsurance.payerAddress.city" + props.count}
                      name="primaryInsurance.payerAddress.city"
                      data-test={selectors.patients.primaryInsurance.payerAddress.city}
                      label={signup[selectors.patients.primaryInsurance.payerAddress.city][language]}
                      component={FormInput}
                      required
                      validator={requiredFieldValidator}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={3} className="pt-1 pl-4 state-selector-field">
                    <Label
                      id={"primaryInsurance.payerAddress.state-label-state" + props.count}
                      editorId={"primaryInsurance.payerAddress.state-state" + props.count}
                    >
                      {signup[selectors.patients.state][language]}
                    </Label>
                    <Field
                      key="primaryInsurance.payerAddress.state"
                      id={"primaryInsurance.payerAddress.state" + props.count}
                      name="primaryInsurance.payerAddress.state"
                      data-test={selectors.patients.primaryInsurance.payerAddress.state}
                      component={FormDropDownList}
                      data={states}
                      defaultValue={signup.selectState[language]}
                      required
                      validator={requiredFieldValidator}
                    />
                  </Col>
                  <Col className="pt-1" sm={12} lg={3}>
                    <Field
                      key="primaryInsurance.payerAddress.zipCode"
                      id={"primaryInsurance.payerAddress.zipCode" + props.count}
                      name="primaryInsurance.payerAddress.zipCode"
                      data-test={selectors.patients.primaryInsurance.payerAddress.zipCode}
                      label={signup[selectors.patients.primaryInsurance.payerAddress.zipCode][language]}
                      component={FormInput}
                      required
                      validator={requiredFieldValidator}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} className="mt-2">
                <span style={{ fontSize: 20 }}>{signup.guarantor[language]}</span>
              </Col>
              <Col className="d-flex flex-row mt-1" xs={12}>
                <span style={{ padding: '0px 16px 8px 16px' }}>{signup.myself[language]}</span>
                <Field
                  key="showInsuredPerson"
                  id={"showInsuredPerson" + props.count}
                  name="showInsuredPerson"
                  defaultChecked={!!props.formRenderProps.valueGetter('primaryInsurance.insured')}
                  data-test={selectors.patients.primaryInsurance.showInsuredPerson}
                  component={FormSwitch}
                />
                <span style={{ padding: '0px 16px 8px 16px' }}>{signup.otherPatientGuarantor[language]}</span>
              </Col>
              {(props.formRenderProps.valueGetter('showInsuredPerson') || (props.formRenderProps.valueGetter('showInsuredPerson') === undefined && !!props.formRenderProps.valueGetter('primaryInsurance.insured'))) ? <Col xs={12}>
                <Row>
                  <Col className="pt-1" sm={12} lg={6}>
                    <Field
                      key="primaryInsurance.insured.firstName"
                      id={"primaryInsurance.insured.firstName" + props.count}
                      name="primaryInsurance.insured.firstName"
                      data-test={selectors.patients.primaryInsurance.insurer.firstName}
                      label={signup[selectors.patients.firstName][language]}
                      component={FormInput}
                      required
                      validator={requiredFieldValidator}
                    />
                  </Col>
                  <Col className="pt-1" sm={12} lg={6}>
                    <Field
                      key="primaryInsurance.insured.lastName"
                      id={"primaryInsurance.insured.lastName" + props.count}
                      name="primaryInsurance.insured.lastName"
                      data-test={selectors.patients.primaryInsurance.insurer.lastName}
                      label={signup[selectors.patients.lastName][language]}
                      component={FormInput}
                      required
                      validator={requiredFieldValidator}
                    />
                  </Col>
                  <Col className="pt-1" sm={12} lg={6}>
                    <Field
                      key="primaryInsurance.insured.phone"
                      id={"primaryInsurance.insured.phone" + props.count}
                      name="primaryInsurance.insured.phone"
                      label={signup[selectors.patients.phoneNumber][language]}
                      data-test={selectors.patients.primaryInsurance.insurer.phone}
                      component={FormMaskedTextBox}
                      mask="000-000-0000"
                      includeLiterals={false}
                      required
                    />
                  </Col>
                  <Col className="pt-1" sm={12} lg={3}>
                    <Field
                      key={"primaryInsurance.insured.gender"}
                      id={"primaryInsurance.insured.gender" + props.count}
                      name={"primaryInsurance.insured.gender"}
                      label={signup[selectors.patients.gender][language]}
                      component={FormRadioGroup}
                      data={signup.insuranceGender[language]}
                      layout="horizontal"
                      validator={requiredFieldValidator}
                      required
                    />
                  </Col>
                  <Col className="pt-1" sm={12} lg={3}>
                    <Field
                      key="primaryInsurance.insured.relationship"
                      id={"primaryInsurance.insured.relationship" + props.count}
                      name="primaryInsurance.insured.relationship"
                      label={signup[selectors.patients.primaryInsurance.insurer.relationship][language]}
                      data-test={selectors.patients.primaryInsurance.insurer.relationship}
                      component={FormDropDownList}
                      textField="label"
                      dataItemKey="value"
                      data={signup.relationshipData[language]}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} lg={6}>
                    <Field
                      key="primaryInsurance.insured.address.addressLine1"
                      id={"primaryInsurance.insured.address.addressLine1" + props.count}
                      name="primaryInsurance.insured.address.addressLine1"
                      data-test={selectors.patients.primaryInsurance.insurer.address.addressLine1}
                      label={signup[selectors.patients.addressLine1][language]}
                      component={FormInput}
                      required
                      validator={requiredFieldValidator}
                    />
                  </Col>
                  <Col xs={12} lg={6}>
                    <Field
                      key="primaryInsurance.insured.address.addressLine2"
                      id={"primaryInsurance.insured.address.addressLine2" + props.count}
                      name="primaryInsurance.insured.address.addressLine2"
                      data-test={selectors.patients.primaryInsurance.insurer.address.addressLine2}
                      label={signup[selectors.patients.addressLine2][language]}
                      component={FormInput}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="pt-1" sm={12} lg={6}>
                    <Field
                      key="primaryInsurance.insured.address.city"
                      id={"primaryInsurance.insured.address.city" + props.count}
                      name="primaryInsurance.insured.address.city"
                      data-test={selectors.patients.primaryInsurance.insurer.address.city}
                      label={signup[selectors.patients.city][language]}
                      component={FormInput}
                      required
                      validator={requiredFieldValidator}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={3} className="pt-1 state-selector-field">
                    <Label
                      id={"primaryInsurance.insured.address.state-label-state" + props.count}
                      editorId={"primaryInsurance.insured.address.state-state" + props.count}
                    >
                      {signup[selectors.patients.state][language]}
                    </Label>
                    <Field
                      key="primaryInsurance.insured.address.state"
                      id={"primaryInsurance.insured.address.state" + props.count}
                      name="primaryInsurance.insured.address.state"
                      data-test={selectors.patients.primaryInsurance.insurer.address.state}
                      component={FormDropDownList}
                      data={states}
                      defaultValue={signup.selectState[language]}
                      required
                      validator={requiredFieldValidator}
                    />
                  </Col>
                  <Col className="pt-1" sm={12} lg={3}>
                    <Field
                      key="primaryInsurance.insured.address.zipCode"
                      id={"primaryInsurance.insured.address.zipCode" + props.count}
                      name="primaryInsurance.insured.address.zipCode"
                      data-test={selectors.patients.primaryInsurance.insurer.address.zipCode}
                      label={signup[selectors.patients.zipCode][language]}
                      component={FormInput}
                      required
                      validator={requiredFieldValidator}
                    />
                  </Col>
                </Row>
              </Col> : null}
            </Row>
          ) : (
            <Row className="w-100 mt-2 pt-1">
              <Col>
                <Button className="w-100" onClick={e => { e.preventDefault(); props.formRenderProps.onChange('primaryInsurance', { value: {} }); }} icon="add" look="flat">{signup.patientInsurance[language]}</Button>
              </Col>
            </Row>
          )}
        </Col>
      ) : null}
    </Row>
  );
}
