import { selectors } from '@keenhp/website-common';

export const signup = {
  save: {
    default: 'Save',
    es: 'Guardar'
  },
  next: {
    default: 'Next',
    es: 'Continuar'
  },
  back: {
    default: 'Previous',
    es: 'Regresar'
  },
  step: {
    default: 'Step',
    es: 'Paso'
  },
  of: {
    default: 'of',
    es: 'de'
  },
  yes: {
    default: 'Yes',
    es: 'Sí'
  },
  no: {
    default: 'No',
    es: 'No'
  },
  complete: {
    default: 'Complete',
    es: 'Finalizado'
  },
  incomplete: {
    default: 'Incomplete',
    es: 'Incompleto'
  },
  title: {
    default: 'Patient Registration',
    es: 'Registro de Paciente',
  },
  employeeDescription: {
    default: 'This first patient will be registered as an employee of',
    es: 'Este primer paciente es empleado por la empresa',
  },
  medHistoryTitle: {
    default: 'Add Medical History',
    es: 'Ingresar Historia Médica',
  },
  personalMedHistory: {
    default: 'Personal Medical History',
    es: 'Historia Médica',
  },
  personalSurgicalHistory: {
    default: 'Personal Surgical History',
    es: 'Historia de Cirugías',
  },
  genderIdentities: {
    default: ['Female', 'Male', 'Non-Binary', 'Trans Man', 'Trans Woman', 'Not Listed'],
    es: ['Mujer', 'Hombre', 'Non-Binary', 'Trans. Mujer', 'Trans Hombre', 'Otro'],
  },
  patient: {
    default: 'Patient',
    es: 'Paciente',
  },
  sameAddressText: {
    default: 'Use the same address for all patients?',
    es: '¿Quiere usar la misma dirección para todos los pacientes?',
  },
  sameEmailText: {
    default: 'Use the same email for all patients?',
    es: '¿Quiere usar el mismo correo electronico para todos los pacientes?',
  },
  samePhoneNumberText: {
    default: 'Use the same phone number for all patients?',
    es: '¿Quiere usar el mismo número de teléfono para todos los pacientes?',
  },
  sameInsuranceText: {
    default: 'Use the same insurance for all patients?',
    es: '¿Quiere usar la misma compañía de seguros para todos los pacientes?',
  },
  almostDone: {
    default: 'To complete your signup, please enter your payment method below',
    es: 'Una cosa más',
  },
  achBtnText: {
    default: 'Add payment method',
    es: 'Ingrese su método de pago',
  },
  creditCardExplanation: {
    default: 'You will not be charged until your provider confirms your sign up. You will receive an email shortly after completing your signup.',
    es: 'Su método de pago no se cargará ahora. Recibirá un correo electrónico de su médico después de completar esta sección.',
  },
  creditCardBtnText: {
    default: 'Click here only if you have to pay with a credit card.',
    es: 'Haga clic aquí si tiene que pagar con tarjeta de crédito.',
  },
  thankyou: {
    default: 'Thanks for signing up!',
    es: '¡Gracias!',
  },
  providerWillEmail: {
    default: 'Your provider will reach out via email shortly.',
    es: 'Su médico se comunicará por correo electronico en breve.',
  },
  selectState: {
    default: "Select a State",
    es: 'Elige estado...'
  },
  guarantor: {
    default: 'Who is the guarantor?',
    es: '¿Quién es el garante?'
  },
  myself: {
    default: 'Self',
    es: 'El paciente mismo'
  },
  otherPatientGuarantor: {
    default: 'Someone else',
    es: 'Otra persona'
  },
  addRow: {
    default: 'Add Row',
    es: 'Agregar',
  },
  genders: {
    default: [
      { label: 'Male', value: 'MALE' },
      { label: 'Female', value: 'FEMALE' }
    ],
    es: [
      { label: 'Hombre', value: 'MALE' },
      { label: 'Mujer', value: 'FEMALE' }
    ]
  },
  insuranceGender: {
    default: [
      { label: 'Male', value: 'male' },
      { label: 'Female', value: 'female' }
    ],
    es: [
      { label: 'Hombre', value: 'male' },
      { label: 'Mujer', value: 'female' }
    ]
  },
  relationshipData: {
    default: [
      { label: 'Spouse', value: 'spouse' },
      { label: 'Parent', value: 'parent' },
      { label: 'Other', value: 'other' }
    ],
    es: [
      { label: 'Esposo', value: 'spouse' },
      { label: 'Padre', value: 'parent' },
      { label: 'Otra', value: 'other' }
    ]
  },
  [selectors.patients.addPatient]: {
    default: 'Add Patient',
    es: 'Agregar Paciente',
  },
  [selectors.patients.saveAndContinueToBillingBtn]: {
    default: 'Continue',
    es: 'Continuar',
  },
  [selectors.patients.removePatient]: {
    default: 'Remove Patient',
    es: 'Eliminar Paciente',
  },
  [selectors.patients.firstName]: {
    default: 'First Name *',
    es: 'Nombre de pila *',
  },
  [selectors.patients.preferredName]: {
    default: 'Preferred/Chosen Name',
    es: 'Nombre preferido/escogido',
  },
  [selectors.patients.middleName]: {
    default: 'Middle Name',
    es: 'Segundo nombre',
  },
  [selectors.patients.lastName]: {
    default: 'Last Name *',
    es: 'Apellido *',
  },
  [selectors.patients.dob]: {
    default: 'Date of Birth *',
    es: 'Fecha de nacimiento *',
  },
  [selectors.patients.gender]: {
    default: 'Sex *',
    es: 'Género al nacer *',
  },
  [selectors.patients.allowGenderIdentity]: {
    default: 'Specify gender identity',
    es: 'Specificar identidad de género',
  },
  [selectors.patients.genderIdentity]: {
    default: 'Gender Identity',
    es: 'Identidad de género',
  },
  [selectors.patients.pronouns]: {
    default: 'Pronouns',
    es: 'Pronombres',
  },
  [selectors.patients.email]: {
    default: 'Email Address *',
    es: 'Correo electronico *',
  },
  [selectors.patients.phoneNumber]: {
    default: 'Phone Number *',
    es: 'Numero de teléfono *',
  },
  [selectors.patients.addressLine1]: {
    default: 'Address Line 1 *',
    es: 'Dirección *',
  },
  [selectors.patients.addressLine2]: {
    default: 'Address Line 2',
    es: 'Dirección parte 2',
  },
  [selectors.patients.city]: {
    default: 'City *',
    es: 'Ciudad *',
  },
  [selectors.patients.state]: {
    default: 'State *',
    es: 'Estado *',
  },
  [selectors.patients.zipCode]: {
    default: 'Zip Code *',
    es: 'Código postal *',
  },
  patientInsurance: {
    default: 'Patient Insurance',
    es: 'Seguro del paciente'
  },
  [selectors.patients.primaryInsurance.payerName]: {
    default: 'Insurance Provider*',
    es: 'Nombre de companía de seguros*'
  },
  [selectors.patients.primaryInsurance.memberId]: {
    default: 'Policy # or Member ID*',
    es: 'Número de póliza de seguros*'
  },
  [selectors.patients.primaryInsurance.groupId]: {
    default: 'Group ID',
    es: 'Número de grupo de seguros'
  },
  [selectors.patients.primaryInsurance.payerPhoneNumber]: {
    default: 'Insurer Phone #*',
    es: 'Numero de teléfono de companía*'
  },
  [selectors.patients.primaryInsurance.payerAddress.addressLine1]: {
    default: 'Insurer Address Line 1*',
    es: 'Dirección de companía*'
  },
  [selectors.patients.primaryInsurance.payerAddress.addressLine2]: {
    default: 'Insurer Address Line 2',
    es: 'Dirección parte 2 de companía'
  },
  [selectors.patients.primaryInsurance.payerAddress.city]: {
    default: 'Insurer City*',
    es: 'Ciudad de companía*'
  },
  [selectors.patients.primaryInsurance.payerAddress.zipCode]: {
    default: 'Insurer Zip Code*',
    es: 'Código postal de Companía*'
  },
  [selectors.patients.primaryInsurance.insurer.relationship]: {
    default: 'Relationship*',
    es: 'Relación*'
  },
  wizardLabels: {
    'Medical History': {
      default: 'Medical History',
      es: 'Historia Medical',
    },
    'Family History': {
      default: 'Family History',
      es: 'Historia Familiar',
    },
    'Social History': {
      default: 'Social History',
      es: 'Historia Social',
    },
    'Allergies/Meds': {
      default: 'Allergies/Meds',
      es: 'Alergías/Meds',
    },
  },
  noRecords: {
    default: 'No records',
    es: '-'
  },
  family: {
    Diagnosis: {
      default: "Diagnosis",
      es: "Diagnóstico",
    },
    Brothers: {
      default: "Brothers",
      es: "Hermanos",
    },
    Sisters: {
      default: "Sisters",
      es: "Hermanas",
    },
    Mother: {
      default: "Mother",
      es: "Madre",
    },
    Father: {
      default: "Father",
      es: "Padre",
    },
    Grandparents: {
      default: "Grandparents",
      es: "Abuelos",
    },
    Description: {
      default: "Description",
      es: "Descripicción",
    },
    diagnoses: {
      'Cancer': {
        default: 'Cancer',
        es: 'Cancer',
      },
      'Heart Disease': {
        default: 'Heart Disease',
        es: 'Cardiopatía'
      },
      'Diabetes': {
        default: 'Diabetes',
        es: 'Diabetes'
      },
      'Stroke/TIA': {
        default: 'Stroke/TIA',
        es: 'Derrame Cerebral'
      },
      'High Blood Pressure': {
        default: 'High Blood Pressure',
        es: 'Hipertensión'
      },
      'High Cholesterol': {
        default: 'High Cholesterol',
        es: 'Colesterol alto'
      },
      'Liver Disease': {
        default: 'Liver Disease',
        es: 'Enfermedad del higado'
      },
      'Alcohol or Drug Abuse': {
        default: 'Alcohol or Drug Abuse',
        es: 'Alcoholismo'
      },
      'Anxiety or Depression': {
        default: 'Anxiety or Depression',
        es: 'Anciedad o depreción'
      },
      'Tuberculosis': {
        default: 'Tuberculosis',
        es: 'Tuberculosis'
      },
      'Anesthesia Complications': {
        default: 'Anesthesia Complications',
        es: 'Complicaciones de anestesia'
      },
      'Genetic Disorder': {
        default: 'Genetic Disorder',
        es: 'Trastorno genético'
      },
      'Other': {
        default: 'Other',
        es: 'Otro',
      },
    }
  },
  date: {
    default: 'Date (MM/YYYY)',
    es: 'Fecha (Mez/Año)',
  },
  medicalHistory: {
    diagnosis: {
      default: 'Diagnosis',
      es: 'Diagnosis',
    },
    description: {
      default: 'Description',
      es: 'Descripción',
    },
    surgery: {
      default: 'Surgery',
      es: 'Cirugía',
    },
  },
  socialHistory: {
    single: {
      default: 'Single',
      es: 'Soltero/a',
    },
    married: {
      default: 'Married',
      es: 'Casado/a',
    },
    divorced: {
      default: 'Divorced',
      es: 'Divorciado/a',
    },
    relationship: {
      default: 'Relationship',
      es: 'En una relacón',
    },
    [selectors.patients.occupation]: {
      default: 'Occupation',
      es: 'Ocupación',
    },
    complicated: {
      default: 'It\'s complicated',
      es: 'Es complicado',
    },
    questions: {
      [selectors.patients.socialSubstancesAlcoholId]: {
        default: 'Do you drink alcohol?',
        es: '¿Toma alcohol?',
      },
      [selectors.patients.socialSubstancesIllicitDrugsId]: {
        default: 'Any illicit or IV drug use?',
        es: '¿Toma drogas?',
      },
      [selectors.patients.socialSubstancesMarijuanaId]: {
        default: 'Any marijuana use?',
        es: '¿Toma marijuana?',
      },
      [selectors.patients.socialSubstancesSexuallyActiveId]: {
        default: 'Are you sexually active?',
        es: '¿Está activo/a sexualmente?',
      },
      [selectors.patients.socialSubstancesSmokeId]: {
        default: 'Do you smoke?',
        es: '¿Fuma usted hoy día?',
      },
      [selectors.patients.socialSubstancesEverSmokeId]: {
        default: 'Have you ever smoked?',
        es: '¿Fumaba usted?',
      },
      [selectors.patients.socialSubstancesPacksPerDay]: {
        default: 'How many packs per day?',
        es: '¿Cuántas cajetillas por día?',
      },
      [selectors.patients.dateQuitSmoking]: {
        default: 'When did you quit? (MM/YYYY)',
        es: '¿Cuándo lo dejó? (mez/año)',
      },
      [selectors.patients.usedToSmoke]: {
        default: 'When you did smoke how many packs per day?',
        es: '¿Cuántas cajetillas por día fumaba?',
      },
    }
  },
  allergiesAndMeds: {
    allergy: {
      default: 'Allergy',
      es: 'Alergía',
    },
    explain: {
      default: 'Please explain',
      es: 'Explicación',
    },
    currentMeds: {
      default: 'Enter medications you are currently taking.',
      es: 'Enumere las medicaciones que toma hoy día.',
    },
    enterCustomValues: {
      default: 'If you do not see something you are taking, you may enter in custom values.',
      es: 'Si no encuentra su medicaion, se acepta medicaciones de forma libre.',
    },
    last: {
      default: 'When was your last...',
      es: '¿Cuándo fue su última...',
    },
    vaccine: {
      default: 'Vaccine',
      es: 'Vacuna',
    },
    immunizations: {
      'Tetanus shot': {
        default: 'Tetanus shot',
        es: 'Vacuna contra el tétanos',
      },
      'Flu shot': {
        default: 'Flu shot',
        es: 'Vacuna contra la gripe',
      },
      'Pneumonia shot': {
        default: 'Pneumonia shot',
        es: 'Vacuna contra la neumonía',
      },
      'Colonoscopy': {
        default: 'Colonoscopy',
        es: 'Colonoscopia',
      },
      'Shingles Vaccine': {
        default: 'Shingles Vaccine',
        es: 'Vacuna contra la culebrilla',
      },
      'Mammogram': {
        default: 'Mammogram (if applicable)',
        es: 'Mamografía (si se aplica)',
      },
      'Pap Smear': {
        default: 'Pap Smear (if applicable)',
        es: 'Prueba de Pap. (si se aplica)',
      },
      'COVID Vaccine': {
        default: 'COVID Vaccine',
        es: 'Vacuna para el COVID-19',
      },
      upToDate: {
        default: 'Are all immunizations up to date?',
        es: '¿Están actuales todas sus inmunizaciones?'
      }
    },
    customRecord: {
      default: 'Other immunization',
      es: 'Otra inmunización'
    },
  }
};
