import { COMMON_DRUGS, selectors } from '@keenhp/website-common';
import { Button } from '@progress/kendo-react-buttons';
import { Form, FormElement, FormSubmitClickEvent } from '@progress/kendo-react-form';
import { Stepper } from '@progress/kendo-react-layout';
import { ChunkProgressBar } from '@progress/kendo-react-progressbars';
import React, { useCallback, useState } from 'react';
import { Row } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { RouteComponentProps, withRouter } from 'react-router';
import { savePatientFamilyHistory, savePatientMedical, savePatientMedicalHistory, savePatientProfile, savePatientSocialHistory } from '../../services/patient-service';
import { ConfirmationToast } from '../ConfirmationToast';
import { PatientProfile } from './PatientProfile.interface';
import { buildPatientFamilyHistory, buildPatientMedical, buildPatientMedicalHistory, buildPatientProfile, buildPatientSocial } from './PatientsUtil';

const scrollToTop = () => document.getElementById('external-app-wrapper')?.scrollTo(0, 0);

export type NewPatientWizardProps = RouteComponentProps & {
  steps: any[];
  stepPages: any[];
  practiceId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  dob?: string;
  phoneNumber?: string;
  addressLine1?: string;
  addressLine2?: string;
  state?: string;
  city?: string;
  zipCode?: string;
  onLastSubmit?: Function;
}

const CHUNK_COUNT = 6;

export const NewPatientWizard = (props: NewPatientWizardProps) => {
  const { onLastSubmit, practiceId } = props;

  const [loading, setLoading] = useState(false);
  const [newPatient, setNewPatient] = useState<PatientProfile>();
  const [step, setStep] = useState(0);
  const [saveProgress, setSaveProgress] = useState(1);
  const [formState, setFormState] = useState<any>({
    firstName: props.firstName,
    lastName: props.lastName,
    email: props.email,
    phoneNumber: props.phoneNumber,
    dob: props.dob,
    addressLine1: props.addressLine1,
    addressLine2: props.addressLine2,
    state: props.state,
    city: props.city,
    zipCode: props.zipCode,
    topDrugs: COMMON_DRUGS,
  });
  const [steps, setSteps] = useState(props.steps);

  const reviewIndex = steps.length - 2;
  const isReviewStep = reviewIndex === step;
  const isPreviousStepsValid = steps
    .slice(0, step)
    .findIndex(currentStep => currentStep.isValid === false) === -1;

  const onStepSubmit = useCallback(async (event: FormSubmitClickEvent) => {
    const { isValid, values } = event;

    const currentSteps = steps.map((currentStep, index) => ({
      ...currentStep,
      isValid: index === step ? isValid : currentStep.isValid
    }));

    // set the form values
    setFormState(values);
    console.debug(values);
    setSteps(currentSteps);

    if (!currentSteps[step].isValid) {
      return toast.error('Please fill out each required field.');
    };

    if (isReviewStep && isPreviousStepsValid && isValid) {
      console.log('isReviewStep && isPreviousStepsValid && isValid');
      setLoading(true);
      if (onLastSubmit) return await onLastSubmit(values);
      try {
        // TODO kick off a saga to do all this work bc we don't want to lose the request on navigate away from the page
        const patient = await savePatientProfile(practiceId, buildPatientProfile(values));
        setNewPatient(patient);
        console.debug('Keen: onStepSubmit -> patient', patient);
        setSaveProgress(2);
        const patientMedical = await savePatientMedical(buildPatientMedical(patient, values));
        console.debug('Keen: onStepSubmit -> patientMedical', patientMedical);
        setSaveProgress(3);
        const medicalHistory = await savePatientMedicalHistory(buildPatientMedicalHistory(patient, values));
        console.debug('Keen: onStepSubmit -> medicalHistory', medicalHistory);
        setSaveProgress(4);
        const social = await savePatientSocialHistory(buildPatientSocial(patient, values));
        console.debug('Keen: onStepSubmit -> social', social);
        setSaveProgress(5);
        const family = await savePatientFamilyHistory(buildPatientFamilyHistory(patient, values));
        console.debug('Keen: onStepSubmit -> family', family);
        setSaveProgress(6);
        setLoading(false);
        console.debug('done saving all patient apis');
      } catch (error) {
        console.error(error);
        setLoading(false);
        return toast.error('There was an error saving the patient. ' + error);
      }
    }
    // finally increment the step
    scrollToTop();
    setStep(() => Math.min(step + 1, reviewIndex + 1));
  },
    [step, steps, setSteps, setStep, setFormState, isReviewStep, isPreviousStepsValid, setSaveProgress, reviewIndex, practiceId, onLastSubmit]
  );

  const onPrevClick = useCallback((event) => {
    event.preventDefault();
    scrollToTop();
    setStep(() => Math.max(step - 1, 0));
  },
    [step, setStep]
  );

  const BillingStep = props.stepPages[step];

  return (
    <div className="d-flex flex-column w-100">
      <div className="d-flex flex-column w-100 py-3" style={{ position: 'fixed', backgroundColor: '#e6eaed', zIndex: 100 }}>
        <Stepper value={step} items={props.steps} />
      </div>
      <div className="d-flex flex-column w-100" style={{ marginTop: 95 }}>
        {loading && (
          <>
            <Row className="w-100 justify-content-center align-items-center">
              <h4>Saving {formState.firstName}{formState.middleName ? ` ${formState.middleName}` : ''} {formState.lastName} Information</h4>
            </Row>
            <Row className="w-100 justify-content-center align-items-center">
              <ChunkProgressBar value={100 * saveProgress / CHUNK_COUNT} chunkCount={CHUNK_COUNT} />
            </Row>
          </>
        )}
        {!loading && step <= reviewIndex &&
          <Form
            initialValues={formState}
            onSubmitClick={onStepSubmit}
            render={(formRenderProps) => (
              <div className="align-self-center" id="new-patient-form-content">
                <FormElement>
                  {typeof props.stepPages[step] === 'function'
                    ? props.stepPages[step](formRenderProps)
                    : props.stepPages[step]
                  }
                  <span style={{ marginTop: '40px' }} className={'k-form-separator'} />
                  <div className="k-form-buttons k-buttons-end justify-content-between align-content-center">
                    <span style={{ alignSelf: 'center' }}>
                      Step {step + 1} of {steps.length}
                      <Button onClick={e => {
                        e.preventDefault(); ConfirmationToast(
                          'Your changes will be lost if you cancel, are you sure?',
                          { text: 'Yes, discard', fn: props.history.goBack },
                          'No, don\'t cancel'
                        )
                      }} className="text-danger" look="flat" data-test={selectors.patients.wizardCancelBtn}>
                        Cancel
                      </Button>
                    </span>
                    <div>
                      {
                        step !== 0 ? (
                          <Button style={{ marginRight: '16px' }} onClick={onPrevClick} look="flat" data-test={selectors.patients.wizardPrevBtn}>
                            Previous
                          </Button>
                        ) : null
                      }
                      <Button
                        disabled={isReviewStep ? !isPreviousStepsValid : false}
                        onClick={formRenderProps.onSubmit}
                        data-test={selectors.patients.wizardNextBtn}
                      >
                        {isReviewStep
                          ? props.onLastSubmit
                            ? 'Review' : 'Submit'
                          : 'Next'}
                      </Button>
                    </div>
                  </div>
                </FormElement>
              </div>
            )}
          />
        }
        {!loading && step > reviewIndex && newPatient && (
          <>
            <BillingStep
              patientId={newPatient?.sk}
              patientName={`${newPatient?.firstName} ${newPatient?.lastName}`}
              patientEmail={newPatient?.email}
              dob={newPatient?.dob}
            />
            <span style={{ marginTop: '40px' }} className={'k-form-separator'} />
            <div className="k-form-buttons k-buttons-end justify-content-between align-content-center">
              <span style={{ alignSelf: 'center' }}>Step {step + 1} of {steps.length}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default withRouter(NewPatientWizard);
