import { Button } from '@progress/kendo-react-buttons';
import React, { useState } from 'react';
import { useAsync } from 'react-async-hook';
import toast from 'react-hot-toast';
import AmExIcon from '../../../assets/svg/AmExIcon';
import DeleteIcon from '../../../assets/svg/DeleteIcon';
import DiscoverIcon from '../../../assets/svg/DiscoverIcon';
import MastercardIcon from '../../../assets/svg/MastercardIcon';
import PlusCircleIcon from '../../../assets/svg/PlusCircleIcon';
import VisaIcon from '../../../assets/svg/VisaIcon';
import { ConfirmationToast } from '../../../components/ConfirmationToast';
import KeenSpinner from '../../../components/KeenSpinner';
import NewPatientBilling from '../../../components/patients/NewPatientBilling';
import { PaymentFrequency } from '../../../components/patients/PaymentFrequency';
import { addPaymentSource, deletePaymentSource } from '../../../services/billings-service';
import { getPaymentSources, IBankAccountSource, ICardSource, IPatientPaymentSource, PaymentSourceType } from '../../services/billings-serivce';
import './patient-billing.scss';
import { CreditCardTypes, PatientPaymentInfoProps } from './PatientPaymentInfoProps.interface';

export const PatientPaymentInfo = (props: PatientPaymentInfoProps) => {
  const [patientPaymentSource, setPatientPaymentSource] = useState<IPatientPaymentSource>();
  const [paymentIndex, setPaymentIndex] = useState(0);
  const [saving, setSaving] = useState(false);
  const [isAddingPaymentMethod, setIsAddingPaymentMethod] = useState(false);
  const selectedFrequency = PaymentFrequency.MONTH;

  const { loading, error, result, execute } = useAsync(getPaymentSources, [props.patient?.patientId], {
    onSuccess: (data: IPatientPaymentSource) => {
      setPatientPaymentSource(data);
    }
  });

  const cardIcon = (brand: string) => {
    switch (brand) {
      case CreditCardTypes.VISA:
        return <VisaIcon width={41} height={13.3} fill="#fff" />;
      case CreditCardTypes.MASTERCARD:
        return <MastercardIcon width={106} height={13} fill="#fff" />
      case CreditCardTypes.AMEX:
        return <AmExIcon width={47} height={15} fill="#fff" />
      case CreditCardTypes.DISCOVER:
        return <DiscoverIcon width={76} height={13} fill="#fff" />
      default:
        return <div className="patient-billing__brand-text">{brand}</div>
    }
  }

  const cardDisplay = (customerName: string, card: ICardSource, index: Number) => {
    return (
      <div className={`patient-billing__payment-method flex-grow-1 shadow-sm ${index === paymentIndex ? "active" : ""}`} key={`card-display-${index}`}>
        {cardIcon(card.brand)}
        <div className="patient-billing__card-number">
          <span>****</span>
          <span>****</span>
          <span>****</span>
          <span>{card.last4}</span>
        </div>
        <div className="patient-billing__payment-details">
          <div>
            <p>Card</p>
            {/* <p className="card-label">Card</p>
            <p>{customerName}</p> */}
          </div>
          <div>
            <p className="card-label">Exp</p>
            <p>{card.expMonth}/{card.expYear.toString().substring(2)}</p>
          </div>
        </div>
      </div>
    )
  }

  const bankAccountDisplay = (bankAccountInfo: IBankAccountSource, index: number) => {
    return (
      <div className={`patient-billing__payment-method flex-grow-1 ach ${index === paymentIndex ? "active" : ""}`} key={`bank-acct-display-${index}`}>
        <div className="patient-billing__bank-name">
          {bankAccountInfo.bankName}
        </div>
        <div className="patient-billing__payment-details routing">
          <div>
            <p className="card-label">Routing Number</p>
            <p>{bankAccountInfo.routingNumber}</p>
          </div>
        </div>
        <div className="patient-billing__payment-details">
          <div>
            <p className="card-label">Account Holder</p>
            <p>{bankAccountInfo.accountHolderName}</p>
          </div>
          <div>
            <p className="card-label">Account Ending</p>
            <p>{bankAccountInfo.last4}</p>
          </div>
        </div>
      </div>
    );
  }

  const handleOnTokenReceived = (stripeToken: string) => {
    if (!props.patientBilling.result || !props.patient) {
      return toast.error('Something went wrong, please refresh the page and try adding your payment method again');
    }
    setSaving(true);
    return toast.promise(addPaymentSource(props.patientBilling.result.payingPatientId || props.patient?.patientId, props.patientBilling.result.customerId, stripeToken), {
      loading: 'Saving payment method',
      success: d => {
        console.log('Keen: handleOnTokenReceived -> d', d)
        execute(props.patient?.patientId);
        setIsAddingPaymentMethod(false);
        setSaving(false);
        return 'Successfully added payment method';
      },
      error: e => {
        console.error(e);
        setSaving(false);
        const msg = 'Error adding payment method, please try again';
        const errorMsg = e.response?.data?.error?.toString?.();
        return <p>{msg} {!!errorMsg && <p>{errorMsg}</p>}</p>;
      }
    });
  }

  const handleDeletePayment = () => {
    ConfirmationToast('Are you sure you want to delete this payment method?', {
      text: 'Yes, delete it',
      fn: handleDeletePaymentConfirm
    }, 'No, cancel');
  }
  const handleDeletePaymentConfirm = () => {
    setSaving(true);
    const selectedPaymentSource = patientPaymentSource?.paymentSources[paymentIndex];
    console.debug('deleting payment method', selectedPaymentSource);
    if (selectedPaymentSource) {
      toast.promise(deletePaymentSource(props.patient?.patientId!, selectedPaymentSource?.id!), {
        loading: `Attempting to delete (${selectedPaymentSource?.sourceType}) payment method`,
        success: () => {
          setSaving(false);
          execute(props.patient?.patientId);
          setPaymentIndex(0);
          return 'Payment method successfully deleted';
        },
        error: e => {
          console.error(e);
          setSaving(false);
          return e.response?.data?.error ? `Error: ${e.response?.data?.error}` : `There was an error deleting the payment method, please try again.`;
        }
      });
    }
  }

  if (loading) return <KeenSpinner size="medium" />;

  return (
    <div>
      {error && <div>Error: {error.message}</div>}
      {result && patientPaymentSource && (
        <div className="patient-billing__payment-info">
          <div className="patient-billing__payment-info-wrap flex-grow">
            {isAddingPaymentMethod ? (
              <div className="patient-billing__add-payment">
                <div className="patient-billing__add-card">
                  <NewPatientBilling
                    patientId={props.patientBilling.result?.payingPatientId || props.patient?.patientId} // the paying patient id or the current patient
                    practiceId={props.patient?.practiceId}
                    selectedPaymentFrequency={selectedFrequency}
                    overrideSave={handleOnTokenReceived}
                    small
                  />
                </div>
                <div className="patient-billing__add-payment-actions">
                  <Button className="btn btn-outline-danger" onClick={() => setIsAddingPaymentMethod(false)} disabled={saving}>Cancel</Button>
                </div>
              </div>
            ) : (
              <>
                {patientPaymentSource?.paymentSources.map((source: any, index: number) => {
                  switch (source.sourceType) {
                    case PaymentSourceType.CARD:
                      return cardDisplay(patientPaymentSource.customerName, source as ICardSource, index)
                    case PaymentSourceType.BANKACCOUNT:
                      return bankAccountDisplay(source as IBankAccountSource, index)
                  }
                })}
                {!props.patientBilling.result?.payingPatientId && result.paymentSources.length === 0 && (
                  <div className="flex-grow-1 d-flex justify-content-center align-items-center w-100" style={{ minHeight: 250 }}>
                    <Button disabled={saving} onClick={() => setIsAddingPaymentMethod(true)} className="w-100">
                      <span style={{ fontSize: 32, whiteSpace: 'normal' }}>Please click here and add a payment method</span>
                    </Button>
                  </div>
                )}
                {!props.patientBilling.result?.payingPatientId && result.paymentSources.length > 0 && <div className="patient-billing__payment-info-actions">
                  <button disabled={saving} onClick={() => setIsAddingPaymentMethod(true)}>
                    <PlusCircleIcon width={16} height={16} fill="#fff" />
                  </button>
                  <button disabled={saving || result.paymentSources.length === 0} onClick={handleDeletePayment}>
                    <DeleteIcon width={16} height={16} fill="#052F5F" />
                  </button>
                </div>}
              </>
            )}
          </div>
          <div className="patient-billing__card-controls">
            {!isAddingPaymentMethod && patientPaymentSource?.paymentSources.map((source, index) => {
              return <button
                onClick={() => setPaymentIndex(index)}
                className={`${index === paymentIndex ? "active" : ""}`}
                key={`btn-${index}`}>
              </button>
            })}
          </div>
        </div>
      )}
    </div>
  );
}
