import { selectors } from '@keenhp/website-common';
import { Field, FormRenderProps } from '@progress/kendo-react-form';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FormComboBox, FormInput, FormMaskedTextBox, FormRadioGroup, FormSwitch } from '../../components/FormComponents';
import { states } from '../../interfaces/Address.interface';
import { NewPatientHeading } from './NewPatientHeading';

export const genders = [
  { label: 'Male', value: 'MALE' },
  { label: 'Female', value: 'FEMALE' }
];

export const genderIdentities = ['Female', 'Male', 'Non-Binary', 'Trans Man', 'Trans Woman', 'Not Listed'];

const REQUIRED_FIELD_MSG = 'This field is required';
const VALID_DOB_MSG = 'Please enter a valid date';
const requiredFieldValidator = (value: string) => !value ? REQUIRED_FIELD_MSG : '';
export const requiredPhoneValidator = (value: string) => !value || value.length !== 12 || value.endsWith('_') ? REQUIRED_FIELD_MSG : '';
export const dobValidator = (value: string) => {
  if (!value || value.includes('_') || !value.includes('/')) return VALID_DOB_MSG;
  if (isNaN(new Date(value).getTime())) return 'Please enter a valid date';

  const [month, day, year] = value.split('/');
  if (!month || !day || !year) return VALID_DOB_MSG;

  if (parseInt(month) === 0 || parseInt(month) > 12) return 'Please enter a valid month';
  if (parseInt(day) === 0 || parseInt(day) > 31) return 'Please enter a valid day';
  // not allowing years in the future, or too old
  if (parseInt(year) === 0 || parseInt(year) < 1900 || parseInt(year) > new Date().getFullYear() + 1) return 'Please enter a valid year';

  return undefined;
}

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
export const emailValidator = (value: string) => (emailRegex.test(value) ? '' : 'Please enter a valid email.');

export const NewPatientProfile = (formRenderProps: FormRenderProps & { disabled?: boolean }) => {
  return (
    <div>
      <Row noGutters>
        <Col sm lg="4">
          <Field
            disabled={formRenderProps.disabled}
            key="firstName"
            id="firstName"
            name="firstName"
            data-test={selectors.patients.firstName}
            label="First Name"
            component={FormInput}
            required
            validator={requiredFieldValidator}
          />
        </Col>
        <Col sm lg="4">
          <Field
            disabled={formRenderProps.disabled}
            key="middleName"
            id="middleName"
            name="middleName"
            data-test={selectors.patients.middleName}
            label="Middle Name"
            component={FormInput}
          />
        </Col>
        <Col sm lg="4">
          <Field
            disabled={formRenderProps.disabled}
            key="lastName"
            id="lastName"
            name="lastName"
            data-test={selectors.patients.lastName}
            label="Last Name"
            component={FormInput}
            required
            validator={requiredFieldValidator}
          />
        </Col>
      </Row>
      <Row noGutters>
        <Col sm lg="6">
          <Field
            disabled={formRenderProps.disabled}
            key="preferredName"
            id="preferredName"
            name="preferredName"
            data-test={selectors.patients.preferredName}
            label="Preferred Name"
            component={FormInput}
          />
        </Col>
        <Col sm lg="6">
          <Field
            disabled={formRenderProps.disabled}
            key="dob"
            id="dob"
            name="dob"
            label="Date of Birth"
            data-test={selectors.patients.dob}
            component={FormMaskedTextBox}
            mask="00/00/0000"
            includeLiterals={false}
            required
            validator={dobValidator}
          />
        </Col>
      </Row>
      <Row noGutters>
        <Col sm lg="6">
          <Field
            disabled={formRenderProps.disabled}
            key="phoneNumber"
            id="phoneNumber"
            name="phoneNumber"
            data-test={selectors.patients.phoneNumber}
            label="Phone Number"
            mask="000-000-0000"
            includeLiterals={false}
            component={FormMaskedTextBox}
            required
            validator={requiredPhoneValidator}
          />
        </Col>
        <Col sm lg="6">
          <Field
            disabled={formRenderProps.disabled}
            key="email"
            id="email"
            name="email"
            data-test={selectors.patients.email}
            label="Email"
            type="email"
            component={FormInput}
            required
            validator={emailValidator}
          />
        </Col>
      </Row>
      <Row noGutters className="pt-1">
        <Col sm lg="12">
          <Row noGutters>
            <Col sm lg="7" className="d-flex align-items-center">
              <span className="p-1">Is this person the primary owner of this phone number?</span>
            </Col>
            <Col className="d-flex flex-row" lg="3">
              <span style={{ padding: 16 }}>No</span>
              <Field
                disabled={formRenderProps.disabled}
                key="primaryPhoneOwner"
                id="primaryPhoneOwner"
                name="primaryPhoneOwner"
                data-test={selectors.patients.primaryPhoneOwner}
                component={FormSwitch}
              />
              <span style={{ padding: 16 }}>Yes</span>
            </Col>
          </Row>
        </Col>
        <Col sm lg="12">
          <Row noGutters>
            <Col sm lg="7" className="d-flex align-items-center">
              <span className="p-1">Is this person the primary owner of this email?</span>
            </Col>
            <Col className="d-flex flex-row" lg="3">
              <span style={{ padding: 16 }}>No</span>
              <Field
                disabled={formRenderProps.disabled}
                key="emailOwner"
                id="emailOwner"
                name="emailOwner"
                data-test={selectors.patients.emailOwner}
                component={FormSwitch}
              />
              <span style={{ padding: 16 }}>Yes</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row noGutters>
        <Col sm lg={12}>
          <Field
            disabled={formRenderProps.disabled}
            key="gender"
            id="gender"
            name="gender"
            label="Sex"
            component={FormRadioGroup}
            data={genders}
            layout="horizontal"
            validator={requiredFieldValidator}
          />
        </Col>
        <Col sm lg="12">
          <Row noGutters>
            <Col sm lg="7" className="d-flex align-items-center">
              <span className="p-1">Specify Gender Identity</span>
            </Col>
            <Col className="d-flex flex-row" lg="3">
              <span style={{ padding: '16px 16px 8px 16px' }}>No</span>
              <Field
                disabled={formRenderProps.disabled}
                key="allowGenderIdentity"
                id="allowGenderIdentity"
                name="allowGenderIdentity"
                defaultChecked={!!formRenderProps.valueGetter('genderIdentity')}
                data-test={selectors.patients.allowGenderIdentity}
                component={FormSwitch}
              />
              <span style={{ padding: '16px 16px 8px 16px' }}>Yes</span>
            </Col>
            {formRenderProps.valueGetter('allowGenderIdentity') || (formRenderProps.valueGetter('allowGenderIdentity') === undefined && !!formRenderProps.valueGetter('genderIdentity')) ? (
              <Col xs={12}>
                <Field
                  disabled={formRenderProps.disabled}
                  key="genderIdentity"
                  id="genderIdentity"
                  name="genderIdentity"
                  data-test={selectors.patients.genderIdentity}
                  label=""
                  component={FormComboBox}
                  data={genderIdentities}
                  allowCustom
                />
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col sm lg={12}>
          <Field
            disabled={formRenderProps.disabled}
            key="pronouns"
            id="pronouns"
            name="pronouns"
            data-test={selectors.patients.pronouns}
            label="Pronouns"
            component={FormInput}
          />
        </Col>
      </Row>
      <NewPatientHeading withLine heading="Address and Location Information" />
      <Field
        disabled={formRenderProps.disabled}
        key="addressLine1"
        id="addressLine1"
        name="addressLine1"
        data-test={selectors.patients.addressLine1}
        label="Address Line 1"
        component={FormInput}
        required
        validator={requiredFieldValidator}
      />
      <Field
        disabled={formRenderProps.disabled}
        key="addressLine2"
        id="addressLine2"
        name="addressLine2"
        data-test={selectors.patients.addressLine2}
        label="Address Line 2"
        component={FormInput}
      />
      <Row noGutters>
        <Col sm lg="4">
          <Field
            disabled={formRenderProps.disabled}
            key="city"
            id="city"
            name="city"
            data-test={selectors.patients.city}
            label="City"
            component={FormInput}
            required
            validator={requiredFieldValidator}
          />
        </Col>
        <Col sm lg="4">
          {/* TODO fix lowercase state not matching */}
          <Field
            disabled={formRenderProps.disabled}
            key="state"
            id="state"
            name="state"
            data-test={selectors.patients.state}
            label="State"
            component={FormComboBox}
            data={states}
            defaultValue={states[0]}
            required
            validator={requiredFieldValidator}
          />
        </Col>
        <Col sm lg="4">
          <Field
            disabled={formRenderProps.disabled}
            key="zipCode"
            id="zipCode"
            name="zipCode"
            data-test={selectors.patients.zipCode}
            label="Zip Code"
            component={FormInput}
            required
            validator={requiredFieldValidator}
          />
        </Col>
      </Row>
      <Field
        disabled={formRenderProps.disabled}
        key="preferredPharmacy"
        id="preferredPharmacy"
        name="preferredPharmacy"
        data-test={selectors.patients.preferredPharmacy}
        label="Preferred Pharmacy"
        component={FormInput}
      />
      <NewPatientHeading withLine heading="Emergency Contact Information" />
      <Row noGutters>
        <Col sm lg="6">
          <Field
            disabled={formRenderProps.disabled}
            key="emergencyContact.name"
            id="emergencyContact.name"
            name="emergencyContact.name"
            data-test={selectors.patients.emergencyContactName}
            label="Full Name"
            component={FormInput}
          />
        </Col>
        <Col sm lg="6">
          <Field
            disabled={formRenderProps.disabled}
            key="emergencyContact.phoneNumber"
            id="emergencyContact.phoneNumber"
            name="emergencyContact.phoneNumber"
            data-test={selectors.patients.emergencyContactPhoneNumber}
            label="Phone Number"
            mask="000-000-0000"
            includeLiterals={false}
            component={FormMaskedTextBox}
          />
        </Col>
      </Row>
    </div>
  );
}
