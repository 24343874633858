import { selectors } from '@keenhp/website-common';
import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { GridCellProps, GridColumnProps } from '@progress/kendo-react-grid';
import { MaskedTextBox, Switch } from '@progress/kendo-react-inputs';
import { useWindowSize } from '@react-hook/window-size';
import { Cache } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { Col, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import LoadingIcon from '../assets/svg/LoadingIcon';
import { EditGridFullDateInput } from '../components/EditGridDateInput';
import { FormComboBox, FormInput } from '../components/FormComponents';
import KeenEditableGrid from '../components/KeenEditableGrid';
import { NewPatientHeading } from '../components/patients/NewPatientHeading';
import { buildPatientFamilyHistory, buildPatientMedical, buildPatientMedicalHistory, buildPatientSocial } from '../components/patients/PatientsUtil';
import { PriceBoxes } from '../components/PricesBoxes';
import { states } from '../interfaces/Address.interface';
import { getBillingTiersForDOBs, savePatientFamilyHistory, savePatientMedical, savePatientMedicalHistory, savePatientProfile, savePatientSocialHistory } from '../services/patient-service';
import { sameDOBs } from '../util/multiple-patients-util';
import { requiredFieldValidator } from '../util/validators';
import { PATIENT_COMPANY_INFO } from './PatientSignUpLanding';


export type MultiPatientSetupProps = RouteComponentProps<{ practiceId: string }> & {};

export interface SimplePatient {
  firstName: string;
  lastName: string;
  dob?: Date | string;
  email: string;
  phoneNumber: string;
}

const defaultPatient: SimplePatient = {
  firstName: '',
  lastName: '',
  dob: '',
  email: '',
  phoneNumber: '',
};

export const NEW_PATIENTS_BILLING = 'keenhp.new-patients.billing';
export const NEW_PATIENT_KEY = 'keenhp.new-patients';
export const NEW_PATIENT_SAME_ADDRESS_KEY = 'keenhp.patients.same.address';

const allowSubmit = (patients: any): boolean => {
  if (patients.length === 0) return false;
  return patients.every((pat: any): boolean => {
    return !!pat?.data?.profile && !!pat?.data?.values;
  });
}

const getBillingTiers = async (practiceId: string, patients: SimplePatient[]) => {
  const tempDobs = patients.map(p => !p.dob ? p : ({...p, dob: new Date(p.dob)})).filter(p => !!p.dob);
  if (tempDobs.length === 0) return;
  if (tempDobs.some(x => !x.dob || isNaN(new Date(x.dob).getTime()))) return;
  const dobs = Array.from(
    new Set(
      tempDobs.map(p => new Date(p.dob!).toISOString())
    ));

  return await getBillingTiersForDOBs(practiceId, dobs);
}

const MultiPatientSetup: React.SFC<MultiPatientSetupProps> = ({ match, history }) => {
  const cachedPatients = Cache.getItem(NEW_PATIENT_KEY);
  const company = Cache.getItem(PATIENT_COMPANY_INFO);
  const practiceId = match.params.practiceId;
  const patientNewPath = `/${practiceId}/patients/new`;
  const patientMultiPath = patientNewPath + '/multi';
  let formRenderProps: FormRenderProps;

  const [browserWidth] = useWindowSize();
  const [useSameAddress, setUseSameAddress] = useState(!!Cache.getItem(NEW_PATIENT_SAME_ADDRESS_KEY));
  const [loading, setLoading] = useState(false);
  const subscriptionCosts = useAsyncCallback<any>(getBillingTiers);
  const [debouncedCallback] = useDebouncedCallback(
    () => subscriptionCosts.execute(practiceId, patients),
    1500 // wait 1.5 second before recalculating the subscription costs
  );

  const [patients, setPatients] = useState(cachedPatients ? cachedPatients.map((p: SimplePatient | any) => {
    if (p?.data?.profile) {
      const prof = p.data.profile;
      return {
        ...p,
        ...prof,
        phoneNumber: prof.primaryPhoneNumber,
      }
    }
    return p;
  }) : [defaultPatient]);

  useEffect(() => {
    const patientsWithDob = patients.filter((p: SimplePatient) => !!p.dob);
    if (patientsWithDob.length > 0) {
      subscriptionCosts.execute(practiceId, patientsWithDob);
    }
    // eslint-disable-next-line
  }, []);

  const setAndCache = (pats: any) => {
    // update the subscription costs
    // if there are some patients to calculate cost for
    // and the dates of birth are different from the previous ones
    if (pats.length > 0 && !sameDOBs(patients, pats) && pats.every((x: any) => {
      if (x.dob) {
        const d = new Date(x.dob);
        if (!isNaN(d.getTime())) {
          return d.getFullYear() > 1900;
        }
      }
      return false;
    })) debouncedCallback();
    Cache.setItem(NEW_PATIENT_KEY, pats);
    setPatients(pats);
  }

  const submit = async () => {
    if (patients.length === 0) return toast.error('You have to have at least one patient');
    setLoading(true);
    try {
      Cache.setItem(NEW_PATIENT_SAME_ADDRESS_KEY, false);
      Cache.setItem(NEW_PATIENTS_BILLING, subscriptionCosts);
      // save all the profiles
      for (const [i, pat] of patients.entries()) {
        console.debug('Keen: submit -> pat', pat);
        if (pat.isEmployee) pat.data.profile.companyId = company.companyId;
        const savedPatient = await savePatientProfile(practiceId, pat.data.profile);

        // save all the data even before they pay
        const patientMedical = await savePatientMedical(buildPatientMedical(savedPatient, pat.data.values));
        console.debug('Keen: onStepSubmit -> patientMedical', patientMedical);
        const medicalHistory = await savePatientMedicalHistory(buildPatientMedicalHistory(savedPatient, pat.data.values));
        console.debug('Keen: onStepSubmit -> medicalHistory', medicalHistory);
        const social = await savePatientSocialHistory(buildPatientSocial(savedPatient, pat.data.values));
        console.debug('Keen: onStepSubmit -> social', social);
        const family = await savePatientFamilyHistory(buildPatientFamilyHistory(savedPatient, pat.data.values));
        console.debug('Keen: onStepSubmit -> family', family);

        // add the saved patient to the cache
        patients[i].saved = savedPatient;
        Cache.setItem(NEW_PATIENT_KEY, patients);
      }
      setLoading(false);
      history.push(`${patientMultiPath}/billing`);
    } catch (error) {
      toast.error('There was an error saving your information, please try again. If the error continues, please refresh the page and try again, or reach out to your practice!', {
        duration: 10000,
      });
    }
    setLoading(false);
  }
  if (loading) return (
    <div className="w-100 h-100">
      <Row className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
        <h2>Saving Profiles...</h2>
        <LoadingIcon fill="#ccc" height={32} width={32} />
      </Row>
    </div>
  );

  const statusCell = (props: any) => {
    // this is kind of a work around -- if there is a gender, that means they at least looked at the profile form and filled it out/clicked next
    if (props.dataIndex < patients.length) {
      if (patients[props.dataIndex].data?.profile &&
        patients[props.dataIndex].data?.values) {
        // if you filled out the form, then call it good
        return <td className="d-flex flex-row justify-content-around">Medical History <span className="k-icon k-i-check-circle text-success" /></td>
      }
    }
    return <td className="p-0"><Button data-test={selectors.patients.addInfoBtn} onClick={() => {
      if (formRenderProps) {
        const addressLine1 = formRenderProps.valueGetter('addressLine1');
        const addressLine2 = formRenderProps.valueGetter('addressLine2');
        const state = formRenderProps.valueGetter('state');
        const city = formRenderProps.valueGetter('city');
        const zipCode = formRenderProps.valueGetter('zipCode');
        if (addressLine1 || addressLine2 || state || city || zipCode) {
          const address = {
            addressLine1,
            addressLine2,
            state,
            city,
            zipCode,
          };
          console.log('Keen: setting address', address);
          Cache.setItem(NEW_PATIENT_SAME_ADDRESS_KEY, address);
        }
      }
      if (!Cache.getItem(NEW_PATIENT_KEY)) {
        Cache.setItem(NEW_PATIENT_KEY, []);
      }
      history.push(`${patientMultiPath}/${props.dataIndex}`)
    }}>Add Medical History</Button></td>
  };

  const columns: GridColumnProps[] = [{
    field: 'firstName',
    title: 'First Name',
    editor: 'text',
  }, {
    field: 'lastName',
    title: 'Last Name',
    editor: 'text',
  }, {
    field: 'dob',
    title: 'Birthday (mm/dd/yyyy)',
    width: 200,
    cell: EditGridFullDateInput,
  }, {
    field: 'email',
    title: 'Email',
    editor: 'text',
  }, {
    field: 'phoneNumber',
    title: 'Phone Number',
    cell: PhoneNumberCell,
  }, {
    field: 'status',
    title: 'Status',
    width: 160,
    cell: statusCell,
  }];
  if (company?.companyId) {
    columns.unshift({
      field: 'isEmployee',
      title: `Part of ${company?.companyName}`,
      editor: 'boolean',
    });
  }
  return (
    <div className="p-3">
      <h1>Welcome to the patient sign up page!</h1>
      <Row className="pt-3" noGutters>
        <Col xs md={6}>
          <h5>Would you like to use the same address for everyone signing up today?</h5>
        </Col>
        <Col xs="1" className="justify-content-center align-items-center d-flex px-1">No</Col>
        <Col xs="1" className="justify-content-center align-items-center d-flex">
          <Switch value={useSameAddress} checked={!!useSameAddress} onChange={e => setUseSameAddress(e.value)} id={selectors.patients.sameAddressSwitch} />
        </Col>
        <Col xs="1" className="justify-content-center align-items-center d-flex px-3">Yes</Col>
      </Row>
      <Row className="pb-2">
        <Form
          onSubmitClick={() => null}
          initialValues={Cache.getItem(NEW_PATIENT_SAME_ADDRESS_KEY)}
          render={frp => {
            formRenderProps = frp;
            return (
              <div className="w-100">
                <FormElement>
                  {useSameAddress && (
                    <>
                      <NewPatientHeading withLine heading="Please enter the address to use for all the patients" />
                      <Field
                        key="addressLine1"
                        id="addressLine1"
                        name="addressLine1"
                        data-test={selectors.patients.addressLine1}
                        label="Address Line 1"
                        placeholder="123 First St."
                        component={FormInput}
                        validator={requiredFieldValidator}
                      />
                      <Field
                        key="addressLine2"
                        id="addressLine2"
                        name="addressLine2"
                        data-test={selectors.patients.addressLine2}
                        label="Address Line 2"
                        component={FormInput}
                      />
                      <Row noGutters>
                        <Col sm lg="4">
                          <Field
                            key="city"
                            id="city"
                            name="city"
                            data-test={selectors.patients.city}
                            label="City"
                            component={FormInput}
                            validator={requiredFieldValidator}
                          />
                        </Col>
                        <Col sm lg="4">
                          {/* TODO fix lowercase state not matching */}
                          <Field
                            key="state"
                            id={selectors.patients.state}
                            name="state"
                            label="State"
                            component={FormComboBox}
                            data={states}
                            defaultValue={states[0]}
                            validator={requiredFieldValidator}
                          />
                        </Col>
                        <Col sm lg="4">
                          <Field
                            key="zipCode"
                            id="zipCode"
                            name="zipCode"
                            data-test={selectors.patients.zipCode}
                            label="Zip Code"
                            component={FormInput}
                            validator={requiredFieldValidator}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </FormElement>
              </div>
            )
          }}
        />
      </Row>
      <Row className="keenhp-multi-patient-setup">
        <KeenEditableGrid
          columns={browserWidth > 992
              ? columns
              : browserWidth > 700 // this could possibly be smaller
                ? [...columns.filter(col => col.field === 'firstName' || col.field === 'lastName' || col.field === 'status' || col.field === 'dob')]
                : [...columns.filter(col => col.field === 'firstName' || col.field === 'status')]}
          data={patients}
          setData={setAndCache}
          defaultData={defaultPatient}
          editAll
          addText="Add a Patient"
        />
      </Row>
      <Row className="py-1">
        <PriceBoxes results={subscriptionCosts} />
      </Row>
      <Row className="justify-content-end">
        <Button disabled={!allowSubmit(patients)} onClick={submit} data-test={selectors.patients.saveAndContinueToBillingBtn}>
          Save and Continue to Billing
        </Button>
      </Row>
    </div>
  );
}



export const PhoneNumberCell = (props: GridCellProps) => {
  const phoneNumber = props.dataItem.phoneNumber;
  if (!props.field) throw new Error('Field is required for PhoneNumberCell');
  if (!props.dataItem.inEdit) return <td>{props.dataItem[props.field]}</td>;
  return (
    <td>
      <MaskedTextBox
        key="phoneNumber"
        id="phoneNumber"
        name="phoneNumber"
        data-test={selectors.patients.phoneNumber}
        mask="000-000-0000"
        includeLiterals={false}
        placeholder="555-555-5555"
        value={phoneNumber}
        width="100%"
        onChange={e => props.onChange ? props.onChange({dataItem: props.dataItem, syntheticEvent: e.syntheticEvent, field: props.field, value: e.value}) : null}
      />
    </td>
  );
}

export default withRouter(MultiPatientSetup);
