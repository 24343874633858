import { COMMON_DRUGS, selectors } from '@keenhp/website-common';
import { filterBy } from '@progress/kendo-data-query';
import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { Field, FormRenderProps } from '@progress/kendo-react-form';
import { Grid, GridColumn, GridItemChangeEvent, GridNoRecords } from '@progress/kendo-react-grid';
import { Input, MaskedTextBox } from '@progress/kendo-react-inputs';
import React from 'react';
import { Col } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { SupportedLanguages } from '../../hooks/use-language';
import { signup } from '../../intl/signup';
import { EditGridMoYearDateInput } from '../EditGridDateInput';
import { FormSwitch } from '../FormComponents';
import { NewPatientHeading } from './NewPatientHeading';

const trueFalse = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
];

const defaultAllergy = { allergy: '', isAllergic: true, description: '', inEdit: true };
export const allergyList = 'allergyList';
export const topDrugsKey = 'topDrugs';
export const immunizationKey = 'healthMaintenance';

interface NewPatientMedicalProps extends FormRenderProps {
  useShortTitles?: boolean;
}

export const NewPatientMedical = (formRenderProps: NewPatientMedicalProps & { disabled?: boolean }) => {
  // form "state"
  const additionalRecord = formRenderProps.valueGetter('additionalRecord');
  const additionalRecordDate = formRenderProps.valueGetter('additionalRecordDate');
  const setAdditionalRecord = (value: string) => formRenderProps.onChange('additionalRecord', { value });
  const setAdditionalRecordDate = (value: string) => formRenderProps.onChange('additionalRecordDate', { value });
  const topDrugs: any[] = formRenderProps.valueGetter(topDrugsKey) || [];
  const allergyData: any[] = formRenderProps.valueGetter(allergyList) || [];

  const language: SupportedLanguages = formRenderProps.valueGetter('language');
  const immunizationDefaultData = [
    { inEdit: true, healthMaintenance: signup.allergiesAndMeds.immunizations['Tetanus shot'][language], date: null },
    { inEdit: true, healthMaintenance: signup.allergiesAndMeds.immunizations['Flu shot'][language], date: null },
    { inEdit: true, healthMaintenance: signup.allergiesAndMeds.immunizations['Pneumonia shot'][language], date: null },
    { inEdit: true, healthMaintenance: signup.allergiesAndMeds.immunizations['Colonoscopy'][language], date: null },
    { inEdit: true, healthMaintenance: signup.allergiesAndMeds.immunizations['Shingles Vaccine'][language], date: null },
    { inEdit: true, healthMaintenance: signup.allergiesAndMeds.immunizations['Mammogram'][language], date: null },
    { inEdit: true, healthMaintenance: signup.allergiesAndMeds.immunizations['Pap Smear'][language], date: null },
  ];

  const immunizationData: any[] = formRenderProps.valueGetter(immunizationKey) || immunizationDefaultData;
  const setData = (key: string, value: any) => formRenderProps.onChange(key, { value });

  const itemAllergyChange = (event: GridItemChangeEvent) => {
    const inEditId = event.dataItem.index;
    const newData = allergyData.map((item, index) =>
      index === inEditId ? { ...item, [event.field!]: event.value } : item
    );
    setData(allergyList, [...newData]);
  }

  const itemImmunizationChange = (event: GridItemChangeEvent) => {
    const inEditId = event.dataItem.index;
    const newData = immunizationData.map((item, index) =>
      index === inEditId ? { ...item, [event.field!]: event.value } : item
    );
    setData(immunizationKey, [...newData]);
  }

  const addAllergy = (e: any) => {
    e.preventDefault();
    setData(allergyList, [...allergyData, {
      ...defaultAllergy,
    }]);
  }

  const removeItem = (e: any, index: number) => {
    e.preventDefault();
    allergyData.splice(index, 1);
    setData(allergyList, [...allergyData]);
  }

  return (
    <div className="w-100">
      <Row className="mt-2 mobile-grid-wrapper">
        <Grid
          data={allergyData.map((x, index) => ({ ...x, index }))}
          editField="inEdit"
          onItemChange={itemAllergyChange}
          className={selectors.patients.allergiesGridClass}
        >
          <GridNoRecords>
            <span>{signup.noRecords[language]}</span>
          </GridNoRecords>
          <GridColumn field="allergy" editable={!formRenderProps.disabled} title={signup.allergiesAndMeds.allergy[language]} />
          <GridColumn field="description" editable={!formRenderProps.disabled} title={signup.allergiesAndMeds.explain[language]} editor="text" />
          {!formRenderProps.disabled && <GridColumn editable={false} width={50} cell={props => <td>
            <Button onClick={(e) => removeItem(e, props.dataIndex)} icon="trash" className="text-danger" look="flat" data-test={selectors.common.editGridRemoveBtn} />
          </td>} />}
        </Grid>
        {!formRenderProps.disabled && <Button onClick={addAllergy} icon="plus" data-test={selectors.patients.addAllergyBtn}>
          {signup.addRow[language]}
        </Button>}
      </Row>
      {!formRenderProps.useShortTitles && <NewPatientHeading heading={signup.allergiesAndMeds.currentMeds[language]} withLine />}
      <h6>{signup.allergiesAndMeds.enterCustomValues[language]}</h6>
      <Field
        disabled={formRenderProps.disabled}
        key={'currentMedications'}
        id={'currentMedications'}
        name={'currentMedications'}
        component={MultiSelect}
        allowCustom
        filterable
        autoClose={false}
        data={topDrugs}
        onFilterChange={(event: any) => setData(topDrugsKey, filterBy(COMMON_DRUGS.slice(), event.filter))}
      />
      {!formRenderProps.useShortTitles && <NewPatientHeading heading={signup.allergiesAndMeds.last[language]} withLine />}
      <Grid
        data={immunizationData.map((x, index) => ({ ...x, index, inEdit: x.inEdit && !formRenderProps.disabled }))}
        editField="inEdit"
        onItemChange={itemImmunizationChange}
        className={selectors.patients.shotsGridClass}
      >
        <GridColumn field="healthMaintenance" title=" " editable={false} />
        <GridColumn field="date" title={signup.date[language]} cell={EditGridMoYearDateInput} editable={!formRenderProps.disabled} />
        {!formRenderProps.disabled && <GridColumn editable={false} width={50} cell={p => p.dataItem.allowDelete ? <td><Button icon="trash" look="flat" className="text-danger" onClick={e => {
          immunizationData.splice(p.dataIndex, 1);
          formRenderProps.onChange(immunizationKey, { value: [...immunizationData] })
        }} /></td> : <td />} />}
      </Grid>
      <Row>
        <Col xs={12} md={4}>
          <DropDownButton className="w-100" buttonClass="w-100 btn btn-secondary" text={`COVID (${signup.allergiesAndMeds.vaccine[language]})`} icon="add" disabled={formRenderProps.disabled} items={['Johnson & Johnson', 'Moderna', 'Pfizer', 'Booster', 'Other']} onItemClick={e => {
            if ((e.item as string).includes('Johnson') || (e.item as string).includes('Booster')) {
              formRenderProps.onChange(
                immunizationKey,
                {
                  value: [...immunizationData, { inEdit: true, healthMaintenance: `COVID - ${e.item}`, date: null, allowDelete: true }]
                });
            } else {
              formRenderProps.onChange(
                immunizationKey,
                {
                  value: [
                    ...immunizationData,
                    { inEdit: true, healthMaintenance: `COVID - ${e.item} (1)`, date: null, allowDelete: true },
                    { inEdit: true, healthMaintenance: `COVID - ${e.item} (2)`, date: null, allowDelete: true },
                  ]
                });
            }
          }} />
        </Col>
        <Col xs={12} md={7} className="d-flex flex-row pt-1">
          <Input value={additionalRecord} onChange={e => setAdditionalRecord(e.target.value)} placeholder={signup.allergiesAndMeds.customRecord[language]} disabled={formRenderProps.disabled} className="flex-grow-1" />
          <MaskedTextBox mask="00/0000" value={additionalRecordDate} onChange={e => setAdditionalRecordDate(e.target.value)} placeholder="__/____" disabled={formRenderProps.disabled} />
          <Button icon="add" disabled={formRenderProps.disabled} onClick={e => {
            e.preventDefault();
            formRenderProps.onChange(immunizationKey, { value: [...immunizationData, { inEdit: true, allowDelete: true, healthMaintenance: additionalRecord, date: additionalRecordDate }] });
            setAdditionalRecord('');
            setAdditionalRecordDate('');
          }}>{signup.addRow[language]}</Button>
        </Col>
      </Row>
      <Row noGutters>
        <Col style={{ marginTop: 16 }}>
          <span>{signup.allergiesAndMeds.immunizations.upToDate[language]}</span>
        </Col>
        <Col className="d-flex flex-row">
          <span style={{ padding: 16 }}>{signup.no[language]}</span>
          <Field
            disabled={formRenderProps.disabled}
            key="immunizationUpToDate"
            id="immunizationUpToDate"
            name="immunizationUpToDate"
            component={FormSwitch}
            data={trueFalse}
          />
          <span style={{ padding: 16 }}>{signup.yes[language]}</span>
        </Col>
      </Row>
    </div>
  );
}
