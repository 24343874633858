import { PatientProfile } from "../components/patients/PatientProfile.interface";

export function getPatientName(patient?: PatientProfile) {
  if (!patient) return 'Patient Undefined';
  const name = `${patient.preferredName || patient.firstName} ${patient.lastName}`;
  if (!name) return 'Missing patient names';
  return name;
}


export function alphabeticalByKey(key: string, reverse?: boolean) {
  return (a: any, b: any) => reverse ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
}
