export const noteTypeDropdownId = 'noteTypeDropdownId';
export const patientDropdownId = 'patientDropdownId';
export const noteTypeOptionPrefix = 'noteTypeOptionPrefix-';

export const noteInputBody = 'noteInputBody';
export const saveNoteBtn = 'saveNoteBtn';
export const replyEmailBtn = 'replyEmailBtn';
export const saveNoteDraftBtn = 'saveNoteDraftBtn';
export const logSmsBtn = 'logSmsBtn';
export const addendNoteBtn = 'addendNoteBtn';
export const printRxBtn = 'printRxBtn';


export const salesItemNameId = 'salesItemName-';
export const salesItemQuantityId = 'salesItemQuantity-';
export const salesItemPriceId = 'salesItemPrice-';
export const billPatientBtn = 'billPatientBtn';
export const addLineItemBtn = 'addLineItemBtn';
export const totalSalesPrice = 'totalSalesPrice';
export const totalInventoryItems = 'totalInventoryItems';
