import React from 'react';
import { Row } from 'react-bootstrap';
import Logo from '../AeroLogo';

export interface HomeProps {}

const Home: React.SFC<HomeProps> = () => (
  <Row className="h-100 w-100 justify-content-center align-items-center flex-column">
    <Logo height={150} width={500} />
    <h2>Welcome to the AeroDPC patient website.</h2>
    <h3>Please click the link your provider gave you to go to their sign up page.</h3>
    <h3>Or <a href="/#/portal">Sign in</a> to the Patient Portal</h3>
  </Row>
);

export default Home;
