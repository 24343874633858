import { API_NAMES } from './services/api-names';

export const AUTH = {
  identityPoolId: 'us-east-1:d2caf66d-fa4c-4d2a-a994-76f6582ccf31',
  region: 'us-east-1',
  userPoolId: 'us-east-1_CTzdBoTBl',
  userPoolWebClientId: '7etdtvpqdod6sslojlu7pok96r',
};

export const API = {
  // appsync setup
  aws_appsync_graphqlEndpoint: 'https://n5i3rdy22valjnnhb7grinld5u.appsync-api.us-east-1.amazonaws.com/graphql', // the dev endpoint
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',

  endpoints: [
    // Authenticated endpoints
    {
      name: API_NAMES.PATIENTS,
      endpoint: 'https://sh39wzjflk.execute-api.us-east-1.amazonaws.com/prod',
    },
    {
      name: API_NAMES.NOTES,
      endpoint: 'https://2h4rxzhwlk.execute-api.us-east-1.amazonaws.com/prod',
    },
    {
      name: API_NAMES.COMPANIES,
      endpoint: 'https://1ydemexp40.execute-api.us-east-1.amazonaws.com/prod',
    },
    // Mixed auth endpoints
    {
      name: API_NAMES.BILLINGS,
      endpoint: 'https://znttliwkxj.execute-api.us-east-1.amazonaws.com/prod',
    },
    // Unauthenticated endpoints
    {
      name: API_NAMES.PATIENTS_EXTERNAL,
      // endpoint: 'http://localhost:3014',
      endpoint: 'https://piarar60va.execute-api.us-east-1.amazonaws.com/prod',
    },
    {
      name: API_NAMES.LOGGER,
      endpoint: 'https://gj2d5s5xpl.execute-api.us-east-1.amazonaws.com/prod',
    }
  ]
};
