import React from 'react';
import { SvgIconInterface } from '../AeroLogo';

const PlusCircleIcon = ({ fill, height, width}: SvgIconInterface) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
          <path fill={fill} d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2a8 8 0 100 16 8 8 0 000-16zm0 2a1 1 0 011 1v4h4a1 1 0 010 2h-4v4a1 1 0 01-2 0v-4H7a1 1 0 010-2h4V7a1 1 0 011-1z"/>
        </svg>
)

export default PlusCircleIcon;
