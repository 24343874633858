import React from 'react';
import { SvgIconInterface } from '../AeroLogo';

const DiscoverIcon = ({ fill, height, width}: SvgIconInterface) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 342.8 58.6">
    <path fill={fill} d="M15.7 1.6H0v55h15.7c8.3 0 14.3-2 19.6-6.3 6.3-5.2 10-13 10-21.1-.1-16.3-12.2-27.6-29.6-27.6zM28.3 43c-3.4 3-7.7 4.4-14.7 4.4h-2.9V11h2.9c6.9 0 11.1 1.2 14.7 4.4 3.7 3.3 5.9 8.4 5.9 13.7 0 5.3-2.2 10.6-5.9 13.9z"/>
    <path fill={fill} d="M50.2 1.6h10.7v55H50.2z" />
    <path fill={fill} d="M87.1 22.7c-6.4-2.4-8.3-4-8.3-6.9 0-3.5 3.4-6.1 8-6.1 3.2 0 5.9 1.3 8.6 4.5l5.6-7.3C96.4 2.9 90.9.8 84.8.8c-9.7 0-17.2 6.8-17.2 15.8 0 7.6 3.5 11.5 13.5 15.1 4.2 1.5 6.3 2.5 7.4 3.1 2.1 1.4 3.2 3.4 3.2 5.7 0 4.5-3.5 7.8-8.3 7.8-5.1 0-9.2-2.6-11.7-7.3l-6.9 6.7c4.9 7.3 10.9 10.5 19 10.5 11.1 0 19-7.4 19-18.1.2-8.9-3.5-12.9-15.7-17.4z"/>
    <path fill={fill} d="M106.3 29.2c0 16.2 12.7 28.7 29 28.7 4.6 0 8.6-.9 13.4-3.2V42.1c-4.3 4.3-8.1 6-12.9 6-10.8 0-18.5-7.8-18.5-19 0-10.6 7.9-18.9 18-18.9 5.1 0 9 1.8 13.4 6.2V3.8C144 1.4 140.1.4 135.5.4c-16.1 0-29.2 12.8-29.2 28.8z"/>
    <path fill={fill} d="M233.9 38.6l-14.7-37h-11.7L230.8 58h5.8l23.7-56.4h-11.6z"/>
    <path fill={fill} d="M265.2 56.6h30.4v-9.3h-19.7V32.5h19v-9.3h-19V11h19.7V1.6h-30.4z"/>
    <path fill={fill} d="M338.1 17.9c0-10.3-7.1-16.2-19.5-16.2h-15.9v55h10.7V34.6h1.4l14.8 22.1h13.2l-17.3-23.2c8.1-1.7 12.6-7.2 12.6-15.6zM316.5 27h-3.1V10.3h3.3c6.7 0 10.3 2.8 10.3 8.2 0 5.5-3.6 8.5-10.5 8.5z"/>
    <circle fill={fill} cx="181.6" cy="29.3" r="29.3"/>
  </svg>
)

export default DiscoverIcon;
