import { selectors } from '@keenhp/website-common';
import { Button } from '@progress/kendo-react-buttons';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { usePlaidLink } from 'react-plaid-link';
import { ReactComponent as BankLogo } from '../../assets/bank.svg';
import { getPlaidEnvironment } from '../../config';
import useLanguage from '../../hooks/use-language';
import { signup } from '../../intl/signup';
import { exchangePlaidLink } from '../../services/billings-service';
import { PatientBillingACHProps } from './PatientBillingACHProps.interface';

export const PatientBillingACH = (props: PatientBillingACHProps) => {
  const language = useLanguage();
  const [bankAccountName, setBankAccountName] = useState('');
  const [showSuccessMessage, setShowSuccess] = useState(false);
  const [saving, setSaving] = useState(false);

  /**
 * Gets fired once a user has successfully selected their bank account information.
 * A token is generated which needs to be exchanged for a stripe token.
 */
  const onSuccess = async (token: string, metadata: any) => {
    const exchangeToken = async (plaidPublicToken: string, accountId: string) => {
      const stripeToken = await exchangePlaidLink(props.patientId, plaidPublicToken, accountId);
      // props.onTokenReceived(stripeToken);
      setShowSuccess(true);
      if (props.onSuccessfulLink) props.onSuccessfulLink(stripeToken);
    };
    setSaving(true);
    // Need to exchange this token for a stripe token.
    toast.promise(exchangeToken(token, metadata.account.id), {
      loading: 'Securely linking your account',
      success: () => {
        setSaving(false);
        setBankAccountName(metadata.institution.name);
        return 'Account linked';
      },
      error: e => {
        setSaving(false);
        const msg = 'There was an error linking your account.';
        const errorMsg = e.response?.data?.error?.toString?.();
        return <p>{msg} {!!errorMsg && <p>{errorMsg}</p>}</p>;
      },
    });
  };

  /**
   * Set the Plaid config. the link token is required so it comes in from props.
   */
  const plaidConfig = {
    token: props.linkToken,
    onSuccess,
    env: getPlaidEnvironment()
  };

  const { open, error, ready } = usePlaidLink(plaidConfig);
  return (
    <Row className="h-100 w-100 p-2">
      {error && (
        <span>There was an error configuring Plaid!</span>
      )}
      {showSuccessMessage && (
        <>
          <Col xs={4} sm={3} md={2}>
            <BankLogo />
          </Col>
          <Col className="d-flex align-items-center justify-content-center text-wrap flex-row">
            <span className="k-icon k-i-check-circle text-success" />
            <h4 className="mb-0" data-test={selectors.patients.plaidSuccessfulLinkMsg}>The <b>{bankAccountName}</b> account works! We are securely connecting to it now.</h4>
          </Col>
        </>
      )}
      {!showSuccessMessage && !error && (
        <>
          <Button className="h-100 w-100 bg-white text-primary shadow-sm" onClick={() => open()} disabled={!ready || saving} data-test={selectors.patients.connectBankAcctBtn}>
            <Row className="h-100 w-100">
              <div style={{ width: 75, height: 75 }}>
                <BankLogo />
              </div>
              <Col className="d-flex align-items-center justify-content-center text-wrap">
                <h4 className="m-0">{signup.achBtnText[language]}</h4>
              </Col>
            </Row>
          </Button>
        </>
      )}
    </Row>
  );
}
