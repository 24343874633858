import { Button } from '@progress/kendo-react-buttons';
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import NewPatient from '../components/patients/NewPatient';

export type PatientSignUpProps = RouteComponentProps & {};

const PatientSignUp: React.SFC<PatientSignUpProps> = ({ history }) => {
  return (
    <Container fluid>
      <Button onClick={() => history.goBack()} className="position-absolute" style={{marginTop: 95}}>
        Cancel
      </Button>
      <Row>
        <NewPatient />
      </Row>
    </Container>
  );
}
 
export default withRouter(PatientSignUp);
