import React, { useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { Row, Col } from 'react-bootstrap';
import { PatientProfile } from '../../components/patients/PatientProfile.interface';
import { getSubscriptionInfo } from '../services/billings-serivce';
import { PatientBillingHistory } from './billing/PatientBillingHistory';
import { PatientOutstandingBalance } from './billing/PatientOutstandingBalance';
import { PatientPaymentInfo } from './billing/PatientPaymentInfo';
import { PatientSubscription } from './billing/PatientSubscription';
import './billing/patient-billing.scss';
import { getPatientBilling } from '../services/patients-service';
import { getPatientName } from '../util';

export interface PatientBillingProps {
  patient?: PatientProfile;
}

const PatientBilling: React.SFC<PatientBillingProps> = (props) => {
  const [outstandingBalance, setOutstandingBalance] = useState(0);

  /**
   * Since the outstanding balance comes back from the patient billing history, this handler
   * is called once that balance has been received.
   * @param balance the amount of money the patient shows as a balance.
   */
  const handleOutstandingBalanceReceived = (balance: number) => {
    setOutstandingBalance(balance);
  }

  const subscription = useAsyncCallback(getSubscriptionInfo);
  const patientBilling = useAsyncCallback(getPatientBilling);

  if (!props.patient?.patientId) return <span>Missing patient ID</span>;

  return (
    <div className="patient-billing p-3">
      <h1 className="keen-section-title col-md-12">Billing</h1>
      <Row className="patient-billing__top-section">
        {!!patientBilling.result?.payingPatientId && <Col xs={12}>Hi {getPatientName(props.patient)}, a different patient pays for you. If you need to edit the payment information please log in as the paying patient.</Col>}
        <Col sm={12} lg={4} className="px-sm-0 px-md-1">
          <PatientPaymentInfo patient={props.patient} subscription={subscription} patientBilling={patientBilling} />
        </Col>
        <Col sm={12} lg={4} className="px-sm-0 px-md-1">
          <PatientSubscription patientId={props.patient.patientId} patientEmail={props.patient.email} dob={props.patient.dob} subscription={subscription} patientBilling={patientBilling} />
        </Col>
        <Col sm={12} lg={4} className="px-sm-0 px-md-1">
          <PatientOutstandingBalance outstandingBalance={outstandingBalance} patientId={props.patient.patientId} />
        </Col>
      </Row>
      <h1 className="keen-section-title col-md-12 mt-1">Billing History</h1>
      <PatientBillingHistory onOutstandingBalanceReceived={handleOutstandingBalanceReceived} patientId={props.patient.patientId} />
    </div>
  );
}

export default PatientBilling;
