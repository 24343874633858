import { selectors } from '@keenhp/website-common';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn, GridColumnProps, GridItemChangeEvent, GridRowClickEvent } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { NewPatientHeading } from './patients/NewPatientHeading';


export interface KeenEditableGridProps {
  addText?: string;
  columns: GridColumnProps[];
  data: any[];
  defaultData: any;
  editAll?: boolean;
  heading?: string;
  setData: Function;
}

const KeenEditableGrid: React.SFC<KeenEditableGridProps> = ({ columns, data, defaultData, editAll, heading, setData, addText }) => {
  const [editId, setEditId] = useState(0);

  const addRow = (e: any) => {
    e.preventDefault();
    setEditId(data.length);
    setData([...data, { ...defaultData }]);
  }

  // grid actions
  const rowClick = (e: GridRowClickEvent) => setEditId(e.dataItem.index);
  const itemChange = (event: GridItemChangeEvent) => {
    const inEditID = event.dataItem.index;
    const newData = data.map((item, index) =>
      index === inEditID ? { ...item, [event.field!]: event.value } : item
    );
    setData([...newData]);
  }
  const removeItem = (e: any, index: number) => {
    e.preventDefault();
    const tempData = [...data];
    tempData.splice(index, 1);
    setEditId(-1);
    setData(tempData);
  }

  return (
    <div className="mobile-grid-wrapper">
      {heading && <NewPatientHeading heading={heading} />}
      <Grid
        data={data.map((item, index) => {
          let inEdit = editAll || index === editId;
          if (item.disabled) inEdit = false;
          return { ...item, inEdit, index };
        })}
        editField="inEdit"
        onRowClick={rowClick}
        onItemChange={itemChange}
        className={selectors.patients.editableGridClass}
      >
        {columns.map(col => <GridColumn key={col.field} {...col} />)}
        <GridColumn editable={false} width={38} cell={props => (
          <td className="p-0">
            <Button onClick={(e) => removeItem(e, props.dataIndex)} icon="trash" className="text-danger" look="flat" data-test={selectors.common.editGridRemoveBtn} />
          </td>
        )} />
      </Grid>
      <Button onClick={addRow} icon="plus" data-test={selectors.common.editGridAddBtn} >
        {addText || 'Add'}
      </Button>
    </div>
  );
}

export default KeenEditableGrid;
