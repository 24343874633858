import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { Cache } from 'aws-amplify';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import MedicalHistoryWizard from '../components/patients/MedicalHistoryWizard';
import useLanguage from '../hooks/use-language';
import { signup } from '../intl/signup';
import { NEW_PATIENT_KEY } from './MultiPatientSetup';

const SimpleMedicalHistory = () => {
  const match = useRouteMatch<{ practiceId: string }>();
  const practiceId = match.params.practiceId;
  const history = useHistory();
  const language = useLanguage();
  const patients: any[] = Cache.getItem(NEW_PATIENT_KEY);

  const [savedPatients, setSavedPatients] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (savedPatients.length === patients.length) {
      history.push(`/thankyou?practiceId=${practiceId}${language !== 'default' ? `&language=${language}` : ''}`);
      // clear the cache
      // Cache.clear();
    }
  }, [history, language, patients.length, practiceId, savedPatients.length]);
  if (!patients || patients?.length === 0) {
    return (
      <div className="w-100 h-100">
        <h2>The patient information is missing. Please <a href={`/#/${practiceId}/patients/new`}>click here</a> to start entering information again.</h2>
      </div>
    );
  }
  return (
    <div className="w-100 py-2 bg-white h-100-not-mobile">
      <h1 className="px-2">{signup.medHistoryTitle[language]} ({savedPatients.length}/{patients.length})</h1>
      <PanelBar expandMode="single">
        {patients?.map((patient, i) => {
          const isCompleted = savedPatients.includes(patient.patientId);
          return (
            <PanelBarItem
              expanded={i === 0}
              selected={i === 0}
              key={patient.patientId}
              disabled={isCompleted}
              title={<div className="w-100 d-flex justify-content-between">
                <span>{patient.firstName} ({patient.dob})</span>
                <span className="pr-4">{isCompleted ? `✅ ${signup.complete[language]}` : signup.incomplete[language]}</span>
              </div>}
            >
              <MedicalHistoryWizard practiceId={practiceId} patientId={patient.patientId} onSave={() => setSavedPatients(p => [...p, patient.patientId])} />
            </PanelBarItem>
          )
        })}
      </PanelBar>
    </div>
  );
}

export default SimpleMedicalHistory;
