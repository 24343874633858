import { Form, FormElement } from '@progress/kendo-react-form';
import React from 'react';
import { useAsync } from 'react-async-hook';
import { Row } from 'react-bootstrap';
import AeroError from '../../../components/AeroError';
import KeenSpinner from '../../../components/KeenSpinner';
import { NewPatientFamilyHistory } from '../../../components/patients/NewPatientFamilyHistory';
import { getPatientFamilyHistory } from '../../services/patients-service';
import { formatFamilyHistoryData } from './converters';

export interface ViewFamilyHistoryProps {
  patientId?: string;
}

const ViewFamilyHistory: React.SFC<ViewFamilyHistoryProps> = (props) => {
  const req = useAsync(getPatientFamilyHistory, [props.patientId]);

  if (req.loading) return <KeenSpinner />;
  if (req.error) return <AeroError error={req.error} />;

  return (
    <Row className="w-100 p-3 align-items-center justify-content-center">
      <Form
        initialValues={formatFamilyHistoryData(req.result)}
        render={formRenderProps => (
          <FormElement>
            <NewPatientFamilyHistory {...formRenderProps} disabled />
          </FormElement>
        )}
      />
    </Row>
  );
}

export default ViewFamilyHistory;
