import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IAddress } from '../../interfaces/Address.interface';
import NewPatientBilling from './NewPatientBilling';
import { NewPatientFamilyHistory } from './NewPatientFamilyHistory';
import { NewPatientMedical } from './NewPatientMedical';
import { NewPatientMedicalHistory } from './NewPatientMedicalHistory';
import { NewPatientProfile } from './NewPatientProfile';
import { NewPatientSocialHistory } from './NewPatientSocialHistory';
import NewPatientWizard from './NewPatientWizard';


const stepPages: any[] = [
  NewPatientProfile,
  NewPatientMedicalHistory,
  NewPatientFamilyHistory,
  NewPatientSocialHistory,
  NewPatientMedical,
  NewPatientBilling
];

type NewPatientProps = RouteComponentProps<{practiceId: string}> & {
  firstName?: string;
  lastName?: string;
  email?: string;
  dob?: string;
  phoneNumber?: string;
  address?: IAddress;
  onLastSubmit?: Function;
}
// use async callback for directly calling to service
export const NewPatient = ({address, firstName, lastName, dob, email, phoneNumber, match, onLastSubmit}: NewPatientProps) => {
  const steps = [
    { label: firstName || 'Patient Profile', isValid: undefined, icon: 'k-i-track-changes' },
    { label: 'Medical History', isValid: undefined, icon: 'k-i-heart-outline' },
    { label: 'Family History', isValid: undefined, icon: 'k-i-myspace' },
    { label: 'Social History', isValid: undefined, icon: 'k-i-tell-a-friend' },
    { label: 'Allergies/Meds', isValid: undefined, icon: 'k-i-heart' },
    { label: !!onLastSubmit ? 'Review' : 'Billing', isValid: undefined, icon: 'k-i-shopping-cart'}
  ];

  return (
    <NewPatientWizard
      stepPages={stepPages}
      steps={steps}
      onLastSubmit={onLastSubmit}
      practiceId={match.params.practiceId}
      firstName={firstName}
      lastName={lastName}
      dob={dob}
      email={email}
      phoneNumber={phoneNumber}
      addressLine1={address?.addressLine1}
      addressLine2={address?.addressLine2}
      state={address?.state}
      city={address?.city}
      zipCode={address?.zipCode}
    />
  );
}

export default withRouter(NewPatient);
