import { selectors } from '@keenhp/website-common';
import { process, State } from '@progress/kendo-data-query';
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridCell, GridColumn as Column, GridDetailRow } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { useAsync } from 'react-async-hook';
import DownloadIcon from '../../../assets/svg/DownloadIcon';
import KeenSpinner from '../../../components/KeenSpinner';
import { getInvoices, getPatientInvoiceItems, IBankAccountSource, ICardSource, IPatientInvoice, IPatientInvoiceItem, IPatientInvoices, IPaymentSource, PaymentSourceType } from '../../services/billings-serivce';
import './patient-billing.scss';
import { PatientBillingHistoryProps } from './PatientBillingHistoryProps.interface';

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const PatientBillingHistory = (props: PatientBillingHistoryProps) => {
  const initialDataState: State = {
    sort: [{ field: 'amount', dir: 'asc' }],
  };
  const [patientInvoices, setPatientInvoices] = useState<IPatientInvoice[]>([]);
  const [dataState, dataStateChanged] = useState(initialDataState);
  const [, setOutstandingBalance] = useState(0);

  /**
   * Will try to get the details of an invoice, but if isExpanded is false or if the details are already displayed
   * then this function will just return.
   * @param dataItem record to try and get details for.
   * @param isExpanded flag that says whether or not the details should be displayed.
   */
  const tryGetDetails = async (dataItem: any, isExpanded: boolean) => {
    dataItem.expanded = isExpanded;
    setPatientInvoices([...patientInvoices]);

    if (!isExpanded || dataItem.details) return;

    const invoiceId: string = dataItem.id;
    const lineItems = await getPatientInvoiceItems(props.patientId, invoiceId);
    dataItem.details = lineItems;
    setPatientInvoices([...patientInvoices]);
  }

  /**
   * Converts a timestamp to a friendly readable string.
   * @param timestamp timestamp that needed to be converted to a local time string.
   */
  const convertToLocalString = (timestamp: number) => {
    if (!timestamp) return '-';

    // Convert timestamp to date.
    const date = new Date(timestamp * 1000);
    const localDateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleString('en-Us', localDateOptions);
  }

  /**
   * Displays credit card information.
   * @param card card payment source.
   */
  const cardDisplay = (card: ICardSource) => {
    return (
      <div>
        <span>{card.brand}</span> -
        <span className="light"> {card.last4}</span>
      </div>
    )
  }

  /**
   * Displays bank account information.
   * @param bankAccountInfo bank account payment source.
   */
  const bankAccountDisplay = (bankAccountInfo: IBankAccountSource) => {
    return (
      <div>
        <span>{bankAccountInfo.bankName}</span>
        <span>{bankAccountInfo.last4}</span>
      </div>
    );
  }

  /**
   * Renders the information for the payment method used to pay the invoice.
   * @param paymentMethod payment method used to pay the invoice.
   */
  const renderPaymentMethod = (paymentMethod: IPaymentSource) => {
    switch (paymentMethod.sourceType) {
      case PaymentSourceType.CARD:
        return cardDisplay(paymentMethod as ICardSource);
      case PaymentSourceType.BANKACCOUNT:
        return bankAccountDisplay(paymentMethod as IBankAccountSource);
    }
  }

  /**
   * Custom cell with a plus to see details, a minus to collapse details,
   * or nothing if the invoice cannot be expanded.
   */
  const cellWithExpand = () => {
    return class extends GridCell {
      render() {
        const invoice: IPatientInvoice = this.props.dataItem;
        if (invoice.stripeStatus === 'draft') {
          return (
            <td className="k-hierarchy-cell can-expand">
              {this.props.dataItem.expanded ?
                // eslint-disable-next-line
                <a onClick={() => tryGetDetails(this.props.dataItem, false)} className="k-icon k-minus" tabIndex={-1} /> :
                // eslint-disable-next-line
                <a onClick={() => tryGetDetails(this.props.dataItem, true)} className="k-icon k-plus" tabIndex={-1} />
              }
            </td>
          )
        } else {
          return <td className="k-hierarchy-cell" />
        }
      }
    };
  }

  /**
   * Custom cell that displays date.
   */
  const cellWithTimestamp = () => {
    return class extends GridCell {
      render() {
        const invoice: IPatientInvoice = this.props.dataItem;
        return (
          <td>
            {convertToLocalString(invoice.paidAtTimestamp)}
          </td>
        );
      }
    };
  }

  /**
   * Custom cell that displays invoice status, allows a user to mark an invoice as paid and
   * issue a refund.
   */
  const cellWithStatusRefundAndMarkPaid = () => {
    return class extends GridCell {
      render() {
        const invoice: IPatientInvoice = this.props.dataItem;
        let cellClass = '';
        switch (invoice.stripeStatus) {
          case 'open':
            cellClass = 'red'
            break;
          case 'paid':
            cellClass = 'green'
            break;
          case 'draft':
            cellClass = 'yellow'
            break;
          default:
            cellClass = ''
        };
        return (
          <td className="cell-pill">
            <span className={cellClass}>{invoice.stripeStatus}</span>
          </td>
        );
      }
    };
  }

  /**
   * Custom cell that displays cost/price.
   */
  const cellWithPaymentMethod = () => {
    return class extends GridCell {
      render() {
        const invoice: IPatientInvoice = this.props.dataItem;
        return (
          <td>
            {invoice.paymentSource &&
              <div>
                {renderPaymentMethod(invoice.paymentSource)}
              </div>
            }
            {invoice.stripeStatus === 'paid' && !invoice.paymentSource &&
              <div>
                Cash/Check
              </div>
            }
          </td>
        );
      }
    };
  }

  /**
   * Custom cell that allows a user to download the pdf.
   */
  const cellWithDownload = () => {
    return class extends GridCell {
      render() {
        const invoice: IPatientInvoice = this.props.dataItem;
        return (
          <td>
            {invoice.invoicePdfUrl && (
              <div>
                {<a href={invoice.invoicePdfUrl}>
                  <DownloadIcon width={15} height={15} fill="#052F5F" />
                </a>}
              </div>
            )}
          </td>
        );
      }
    };
  }

  /**
   * Custom cell that displays the invoice amount and refund amount if there was one.
   */
  const cellWithAmounts = () => {
    return class extends GridCell {
      render() {
        const invoice: IPatientInvoice = this.props.dataItem;
        return (
          <td>
            <span>{currencyFormatter.format(invoice.amountPaid)}</span>
            {invoice.refundAmount &&
              <span className="refund-amount">(-{currencyFormatter.format(invoice.refundAmount)})</span>
            }
          </td>
        );
      }
    };
  }

  /**
   * Custom detail cell that displays date.
   */
  const detailCellWithTimestamp = () => {
    return class extends GridCell {
      render() {
        const invoiceItem: IPatientInvoiceItem = this.props.dataItem;
        return (
          <td>
            {convertToLocalString(invoiceItem.timestamp)}
          </td>
        );
      }
    };
  }

  /**
   * Responsible for rendering a detail row.
   */
  const invoiceDetailGrid = () => {
    return class extends GridDetailRow {
      render() {
        const data: IPatientInvoiceItem[] = this.props.dataItem.details;
        if (data) {
          return (
            <Grid data={data} className={selectors.patients.invoicesGridDetailClass}>
              <Column field="amount" title="Amount" format='{0:c}' />
              <Column field="description" title="Description" />
              <Column field="timestamp" title="Date" cell={detailCellWithTimestamp()} />
            </Grid>
          );
        }
        return (
          <div style={{ height: "50px", width: '100%' }}>
            <div style={{ position: 'absolute', width: '100%' }}>
              <div className="k-loading-image" />
            </div>
          </div>
        );
      }
    };
  }

  /**
   * Leverage useAsync to get data.
   */
  const { loading, error, result, execute } = useAsync(getInvoices, [props.patientId], {
    onSuccess: (data: IPatientInvoices) => {
      setPatientInvoices(data.invoices);
      setOutstandingBalance(data.outstandingBalance);
      props.onOutstandingBalanceReceived(data.outstandingBalance);
    }
  });

  return (
    <div className="patient-billing__billing-history col-md-12">
      {loading && <KeenSpinner size="medium" />}
      {error && <div>Error: {error.message} <div><Button icon="refresh" onClick={() => execute(props.patientId)}>Reload Billing History</Button></div></div>}
      {result &&
        <div style={{ overflowX: 'auto' }} className="mobile-grid-wrapper">
          <Grid
            sortable
            pageable={{ buttonCount: 4, pageSizes: false }}
            data={process(patientInvoices, dataState)}
            {...dataState}
            onDataStateChange={(e) => {
              dataStateChanged(e.dataState)
            }}
            detail={invoiceDetailGrid()}
            expandField="expanded"
            className={selectors.patients.invoicesGridClass}
            style={{ minWidth: 750 }}
          >
            <Column cell={cellWithExpand()} width={20} />
            <Column title="Amount Paid" cell={cellWithAmounts()} />
            <Column title="Date" cell={cellWithTimestamp()} />
            <Column field="stripeStatus" title="Status" cell={cellWithStatusRefundAndMarkPaid()} />
            <Column title="Method" cell={cellWithPaymentMethod()} />
            <Column cell={cellWithDownload()} width={40} />
          </Grid>
        </div>
      }
    </div>
  );
}
