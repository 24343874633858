/**
 * This component is used to capture ACH information.
 */
import React, { useCallback, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import Button from 'react-bootstrap/Button';
import toast from 'react-hot-toast';
import { exchangePlaidLink } from '../services/billings-service';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getPlaidEnvironment } from '../config';

type PatientBillingACHProps = RouteComponentProps & {
  onTokenReceived: (token: string) => Promise<void>;
  patientId: string;
  linkToken: string;
  loading: boolean;
  setLoading: Function;
}

export const PatientBillingACH = (props: PatientBillingACHProps) => {

  const [bankAccountName, setBankAccountName] = useState('');

  /**
   * Gets fired once a user has successfully selected their bank account information.
   * A token is generated which needs to be exchanged for a stripe token.
   */
  const onSuccess = useCallback((token, metadata) => {
    // Need to exchange this token for a stripe token.
    exchangeToken(token, metadata.account.id)
    setBankAccountName(metadata.institution.name);
    // eslint-disable-next-line
  }, []);

  /**
   * Takes the Plaid Public Token and accountId and calls the Keen api to exchange that
   * for a stripe token.
   * @param plaidPublicToken
   * @param accountId
   */
  const exchangeToken = async (plaidPublicToken: string, accountId: string) => {
    props.setLoading(true);
    const response = await exchangePlaidLink(props.patientId, plaidPublicToken, accountId);
    await toast.promise(props.onTokenReceived(response), {
      loading: 'Securely linking your bank account.',
      success: `${bankAccountName || 'Bank'} account linked!`,
      error: e => {
        props.setLoading(false);
        const msg = 'There was an error linking your bank account, please try again.'
        const errorMsg = e.response?.data?.error?.toString?.();
        return <p>{msg} {!!errorMsg && <p>{errorMsg}</p>}</p>;
      },
    }, {
      duration: 6000,
    });
    props.setLoading(false);
    props.history.push('thankyou');
  };

  /**
   * Set the Plaid config. the link token is required so it comes in from props.
   */
  const plaidConfig = {
    token: props.linkToken,
    onSuccess,
    env: getPlaidEnvironment(),
  };

  const { open, error, ready } = usePlaidLink(plaidConfig);
  return (
    <div>
      {error && (
        <span>There was an error configuring Plaid</span>
      )}
      {!error && (
        <Button onClick={() => {
          try {
            open();
          } catch (e) {
            toast.error('There was an error linking your bank account, please try again.');
          }
        }} disabled={!ready || props.loading}>
          Pay with a bank account
        </Button>
      )}
    </div>
  );
}

export default withRouter(PatientBillingACH);
