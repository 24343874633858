export function redactKey(obj?: any, searchKey?: string) {
  if (!obj || !searchKey) return;
  Object.keys(obj).forEach(key => {
    if (key.toLocaleLowerCase() === searchKey.toLocaleLowerCase()) {
      const redacted = JSON.stringify(obj[key]);
      if (typeof obj[key] === 'string') obj[key] = redacted.slice(1, 10) + '...';
      else obj[key] = redacted.slice(0, 10) + '...';
    } else if (typeof obj[key] === 'object') {
      redactKey(obj[key], searchKey);
    }
  });
}
