import React from 'react';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Col, Row } from 'react-bootstrap';


export interface VitalsFormProps {
  data?: any;
}

const VitalsForm: React.SFC<VitalsFormProps> = ({ data }) => {
  return (
    <>
      <Row className="keen-note__vitals-row align-items-center">
        <Col xs={12} md={3}>
          <NumericTextBox spinners={false} disabled className="w-100" id="systolic" label="Systolic" value={data?.systolic} />
        </Col>
        <Col xs={12} md={3}>
          <NumericTextBox spinners={false} disabled className="w-100" id="diastolic" label="Diastolic" value={data?.diastolic} />
        </Col>
        <Col xs={12} md={3}>
          <NumericTextBox spinners={false} disabled className="w-100" id="heartRate" label="Pulse" value={data?.heartRate} />
        </Col>
        <Col xs={12} md={2}>
          <NumericTextBox spinners={false} disabled className="w-100" id="spo2" label="SpO2" value={data?.spo2} />
        </Col>
      </Row>
      <Row className="keen-note__vitals-row align-items-center">
        <Col xs={12} md={3}>
          <NumericTextBox spinners={false} disabled className="w-100" id="temp" label="Temp °F" value={data?.temp} />
        </Col>
        <Col xs={12} md={3}>
          <NumericTextBox spinners={false} disabled className="w-100" id="respiratoryRate" label="Respirations" value={data?.respiratoryRate} />
        </Col>
      </Row>
      <Row className="keen-note__vitals-row align-items-center">
        {/* measurements h, w, bmi */}
        <Col xs={12} md={3}>
          <NumericTextBox spinners={false} disabled className="w-100" id="height" label="Height (in)" value={data?.height} />
        </Col>
        <Col xs={12} md={3}>
          <NumericTextBox spinners={false} disabled className="w-100" id="weight" label="Weight (lb)" value={data?.weight} />
        </Col>
        <Col xs={12} md={3}>
          <NumericTextBox spinners={false} disabled className="w-100" id="bmi" label="BMI" value={Number(data?.bmi)} />
        </Col>
      </Row>
    </>
  );
}

export default VitalsForm;
