import { COMMON_DRUGS } from '@keenhp/website-common';
import { unConvertSubstances } from '../../../services/patient-service';
import { alphabeticalByKey } from '../../util';
const familyData: any[] = [
  { diagnosis: 'Cancer', field: 'cancer', editable: true },
  { diagnosis: 'Heart Disease', field: 'heartDisease', editable: true },
  { diagnosis: 'Diabetes', field: 'diabetes', editable: true },
  { diagnosis: 'Stroke/TIA', field: 'stroke', editable: true },
  { diagnosis: 'High Blood Pressure', field: 'highBloodPressure', editable: true },
  { diagnosis: 'High Cholesterol', field: 'highCholesterol', editable: true },
  { diagnosis: 'Liver Disease', field: 'liverDisease', editable: true },
  { diagnosis: 'Alcohol or Drug Abuse', field: 'alcoholOrDrugAbuse', editable: true },
  { diagnosis: 'Anxiety or Depression', field: 'anxietyOrDepression', editable: true },
  { diagnosis: 'Tuberculous', field: 'tuberculous', editable: true },
  { diagnosis: 'Anesthesia Complications', field: 'anesthesiaComplications', editable: true },
  { diagnosis: 'Genetic Disorder', field: 'geneticDisorder', editable: true },
  { diagnosis: 'Other', field: 'other', editable: true },
];

export const immunizationDefaultData = [
  { inEdit: true, healthMaintenance: 'Tetanus shot', date: null },
  { inEdit: true, healthMaintenance: 'Flu shot', date: null },
  { inEdit: true, healthMaintenance: 'Pneumonia shot', date: null },
  { inEdit: true, healthMaintenance: 'Colonoscopy', date: null },
  { inEdit: true, healthMaintenance: 'Shingles Vaccine', date: null },
  { inEdit: true, healthMaintenance: 'Mammogram (if applicable)', date: null },
  { inEdit: true, healthMaintenance: 'Pap Smear (if applicable)', date: null },
];
export const allergyList = 'allergyList';
export const topDrugsKey = 'topDrugs';
export const immunizationKey = 'healthMaintenance';

export const formatMedical = (patientMedical: any) => ({
  [topDrugsKey]: COMMON_DRUGS,
  [allergyList]: patientMedical?.data?.allergies?.map((hm: any) => ({
    inEdit: true,
    isAllergic: true,
    description: hm.description,
    allergy: hm.allergy,
  })),
  [immunizationKey]: (patientMedical?.data?.healthMaintenance?.map((hm: any) => ({
    healthMaintenance: hm.name,
    date: typeof hm.date === 'string'
      ? hm.date.length === 7
        ? hm.date : new Date(hm.date).toLocaleDateString('en-US', { month: '2-digit', year: 'numeric' })
      : new Date(hm.date).toLocaleDateString('en-US', { month: '2-digit', year: 'numeric' }),
    inEdit: true,
  })) || immunizationDefaultData).sort(alphabeticalByKey('healthMaintenance', true)),
  currentMedications: patientMedical?.data?.currentMedications,
  immunizationUpToDate: patientMedical?.data?.immunizationUpToDate,
});


export const formatMedicalHistory = (patientMedicalHistory: any) => {
  const medicalHistory: any[] = patientMedicalHistory?.data?.medicalHistory;
  return {
    medicalHistory: medicalHistory ? medicalHistory.map(mh => {
      let date = mh.date;
      if (typeof date === 'string') {
        if (date.length !== 7) date = new Date(mh.date).toLocaleDateString('en-US', { month: '2-digit', year: 'numeric' })
      } else {
        date = new Date(mh.date).toLocaleDateString('en-US', { month: '2-digit', year: 'numeric' });
      }
      return {
        ...mh,
        date,
      }
    })
      : []
  }
}


export const formatFamilyHistoryData = (patientFamilyHistory: any) => {
  // merge with family data
  const famFromApi: any[] = patientFamilyHistory?.data?.familyMedicalHistory;
  const familyHistory = familyData.map(fam => {
    const exists = famFromApi?.find(x => fam.field === x.field);
    return exists ? { ...fam, ...exists } : fam;
  });
  if (famFromApi) {
    famFromApi.forEach(data => {
      const other = familyHistory.find(x => x.field === data.field);
      if (!other) familyHistory.push({
        ...data,
        diagnosis: 'Other',
        editable: true,
      });
    });
  }
  return { familyHistory };
}


export const formatSocialHistory = (patientSocialHistory: any) => ({
  social: {
    relationshipStatus: patientSocialHistory?.data?.relationshipStatus,
    occupation: patientSocialHistory?.data?.currentJob,
    substances: unConvertSubstances(patientSocialHistory?.data?.substances),
  }
});
