export function sameDOBs(a: any[], b: any[]): boolean {
  const dobA = a.map(pat => new Date(pat.dob).getTime()).sort();
  const dobB = b.map(pat => new Date(pat.dob).getTime()).sort();

  if (dobA.length !== dobB.length) return false;

  for (var i = 0; i < dobA.length; ++i) {
    if (dobA[i] !== dobB[i]) return false;
  }
  return true;
}
