import { Button } from '@progress/kendo-react-buttons';
import { Tooltip } from '@progress/kendo-react-tooltip';
import React, { useEffect, useState } from 'react';
import AeroError from '../../../components/AeroError';
import KeenSpinner from '../../../components/KeenSpinner';
import { IPatientSubscription } from '../../services/billings-serivce';
import './patient-billing.scss';
import { PatientSubscriptionProps } from './PatientSubscriptionProps.interface';

export enum PaymentFrequency {
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  ANNUAL = 'ANNUAL'
}

export const PatientSubscription = (props: PatientSubscriptionProps) => {
  const [subscriptionInfo, setSubscriptionInfo] = useState<IPatientSubscription>();
  const [resumeDate, setResumeDate] = useState<number>();
  const [isSubscriptionPaused, setIsSubscriptionPaused] = useState(false);

  const displayFrequency = (frequency: PaymentFrequency) => {
    let displayText = '';
    switch (frequency) {
      case PaymentFrequency.MONTH:
        displayText = 'Monthly';
        break;
      case PaymentFrequency.QUARTER:
        displayText = 'Quarterly';
        break;
      case PaymentFrequency.ANNUAL:
        displayText = 'Annually';
        break;
    }
    return displayText;
  }
  const displayDate = (timeStamp: number | undefined) => {
    if (!timeStamp) return;

    let date = new Date(timeStamp * 1000);
    return date.toLocaleDateString('en-US');
  }

  const { loading, error, result, execute } = props.subscription;

  useEffect(() => {
    execute(props.patientId);
    props.patientBilling.execute(props.patientId);
    // eslint-disable-next-line
  }, [props.patientId]);

  useEffect(() => {
    if (result) {
      setSubscriptionInfo(result);
      if (result.isPaused) {
        setIsSubscriptionPaused(true);
        setResumeDate(result.resumesAt);
      }
    }
  }, [result]);

  if (loading || props.patientBilling.loading) return <KeenSpinner size="medium" />;
  if (error || props.patientBilling.error) return <AeroError error={error || props.patientBilling.error} />;
  if (!subscriptionInfo?.id) return null
  return (
    <>
      {result && subscriptionInfo && props.patientBilling.result && (
        <div className="patient-billing__subscription shadow-sm">
          {(subscriptionInfo.status !== 'canceled' && subscriptionInfo.status !== 'incomplete_expired') ? (
            <span className="patient-billing__floating-icon">
              {!isSubscriptionPaused && <span>Active</span>}
              {isSubscriptionPaused && <span>Paused</span>}
            </span>
          ) : (
            <div>
              <h2 className="text-warning">Subscription Canceled</h2>
              <Tooltip anchorElement="target" position="bottom">
                <Button icon="refresh" disabled className="btn btn-warning mb-2" title="This will activate the patient subscription. You should only click this button if the patient has updated their payment method.">
                  Renew
                </Button>
              </Tooltip>
              <Button icon="share" disabled className="btn btn-secondary">Update Payment Method</Button>
            </div>
          )}
          <div className="patient-billing__subscription-wrap">
            {subscriptionInfo.status !== 'canceled' && subscriptionInfo.status !== 'incomplete_expired' && <h2 style={{ paddingRight: 30 }}>Current subscription</h2>}
            <p className="patient-billing__subscription-price">${subscriptionInfo.totalSubscriptionCost?.total}<span> - {displayFrequency(subscriptionInfo.subscriptionPaymentFrequency)}</span></p>
            {subscriptionInfo.status !== 'canceled' && subscriptionInfo.status !== 'incomplete_expired' && <p className="patient-billing__scheduled-date">payment scheduled {displayDate(subscriptionInfo.currentPeriodEndDate)}</p>}
            {isSubscriptionPaused && <p className="patient-billing__schedule-paused">subscription is paused until {displayDate(resumeDate)}</p>}
          </div>
        </div>
      )}
    </>
  );
}
