import API from '../../services/aero-api';
import { API_NAMES } from '../../services/api-names';
import { PaymentFrequency } from '../pages/billing/PatientSubscription';

/**
 * Gets the subscription information of a patient.
 * @param patientId id of the patient to get a subscription for.
 */
export async function getSubscriptionInfo(patientId: string) {
  const path = `/v1/billings/subscriptions/patients/${patientId}`;
  const response = await API.get(API_NAMES.BILLINGS, path, null);
  return response;
}

/**
 * Gets payment sources for a patient.
 * @param patientId id of the patient to get payment sources for.
 */
export async function getPaymentSources(patientId?: string) {
  if (!patientId) throw new Error('missing patient Id');
  const path = `/v1/billings/patients/${patientId}/paymentSources`;
  const response = await API.get(API_NAMES.BILLINGS, path, null);
  return response;
}

/**
 * Gets invoices as well as outstanding balance for a patient.
 * @param patientId id of the patient to get invoice information for.
 */
export async function getInvoices(patientId: string) {
  const path = `/v1/billings/patients/${patientId}/invoices`;
  const response = await API.get(API_NAMES.BILLINGS, path, null);
  return response;
}

/**
 * Gets all line items on an invoice.
 * @param patientId id of the patient this invoice belongs to.
 * @param invoiceId id of the invoice to get line items for.
 */
export async function getPatientInvoiceItems(patientId: string, invoiceId: string) {
  const path = `/v1/billings/patients/${patientId}/invoices/${invoiceId}/items`;
  const response = await API.get(API_NAMES.BILLINGS, path, {});
  return response;
}

/**
 * Gets all billing tiers for a practice.
 */
export async function getBillingTiers() {
  const path = `/v1/billings/tiers`;
  const response = await API.get(API_NAMES.BILLINGS, path, null);
  return response;
}


// Interfaces

export interface PayingPatient {
  patientId: string;
  customerId: string;
  patientFirstName: string;
  patientLastName: string;
  patientFullName: string;
  subscriptionPaymentFrequency: string;
}

export interface IPatientInvoices {
  outstandingBalance: number;
  invoices: IPatientInvoice[];
}

export interface IPatientInvoice {
  id: string;
  amountDue: number;
  amountPaid: number;
  amountRemaining: number;
  hasBeenPaid: boolean;
  isDelinquent: boolean;
  paymentSourceId: string;
  invoicePdfUrl: string;
  stripeStatus: string;
  paymentSource: IPaymentSource;
  paidAtTimestamp: number;
  refundAmount?: number;
  details?: IPatientInvoiceItem[]; // UI only
  isMarkingPaid?: boolean; // UI only
  isInitiatingRefunding?: boolean; // UI only
  isSavingRefund?: boolean; // UI only
  newRefundAmount?: number; //UI only
  refundReason?: string; // UI only
}

export interface IPatientPaymentSource {
  customerName: string;
  paymentSources: IPaymentSource[];
}

export interface IPaymentSource {
  sourceType: PaymentSourceType;
  id: string;
}

export interface ICardSource extends IPaymentSource {
  brand: string;
  expMonth: number;
  expYear: number;
  addressZip: string;
  last4: string;
}

export interface IBankAccountSource extends IPaymentSource {
  last4: string;
  accountHolderName: string;
  accountHolderType: string;
  bankName: string;
  routingNumber: string;
}

export interface IPatientSubscription {
  id: string;
  collectionMethod: string;
  createdDate: number;
  currentPeriodEndDate: number;
  currentPeriodStartDate: number;
  customerId: string;
  subscriptionPaymentFrequency: PaymentFrequency;
  totalSubscriptionCost: SubscriptionPrice;
  status: string;
  isPaused: boolean;
  resumesAt: number;
}

export interface SubscriptionPrice {
  total: number;
  subtotal: number;
  discount: number;
}

export interface ISalesItem {
  isInventoryItem?: boolean; //UI only
  id?: number; // UI only
  genericName?: string; // UI only
  inventoryId?: string;
  itemName: string;
  quantity: number;
  price: number;
  pricePerUnit?: number; // UI only
  quantityInStock?: number; // UI only
  priceOverridden?: boolean; // UI only
  nameIsValid?: boolean; // UI only
  quantityIsValid?: boolean; // UI only
  priceIsValid?: boolean; // UI only
}

export interface IPatientInvoiceItem {
  invoiceItemId: string;
  description: string;
  amount: number;
  timestamp: number;
  relatedChargeItemId?: string;
  isSubscriptionItem: boolean;
  isReversing: boolean; // UI only
}

export interface IPatientSubscriptionPause {
  patientId: string;
  resumeAt: number;
}

export interface IPatientRefund {
  refundAmount: number;
  refundReason: string;
}

// Enums

// Enum for the different payment source types.
export enum PaymentSourceType {
  BANKACCOUNT = 'BANKACCOUNT',
  CARD = 'CARD',
}
