import Amplify from 'aws-amplify';
import React from 'react';
import { Container } from 'react-bootstrap';
import { Toaster } from 'react-hot-toast';
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { API, AUTH } from './amplify.config';
import './app.scss';
import CompanyBilling from './pages/CompanyBilling';
import Home from './pages/Home';
import MultiPatientBilling from './pages/MultiPatientBilling';
import MultiPatientSetup from './pages/MultiPatientSetup';
import NewMultiPatient from './pages/NewMultiPatient';
import StripeRedirect from './pages/PatientPay';
import PatientSignUp from './pages/PatientSignUp';
import PatientSignUpLanding from './pages/PatientSignUpLanding';
import PatientThankYou from './pages/PatientThankYou';
import SimpleMedicalHistory from './pages/SimpleMedicalHistory';
import SimplePatientBilling from './pages/SimplePatientBilling';
import SimplePatientSignup from './pages/SimplePatientSignup';
import PatientPortal from './portal';


// configure amplify
Amplify.configure({ Auth: AUTH, API });

const App = () => {
  return (
    <div className="w-100 h-100" id="external-app-wrapper" style={{ overflowY: 'auto' }}>
      <Toaster position="top-right" />
      <Container fluid>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/portal" component={PatientPortal} />
            <Route exact path="/:practiceId/patients/new" component={PatientSignUpLanding} />
            <Route exact path="/:practiceId/patients/new/signup" component={SimplePatientSignup} />
            <Route exact path="/:practiceId/patients/new/billing" component={SimplePatientBilling} />
            <Route exact path="/:practiceId/patients/new/med-history" component={SimpleMedicalHistory} />
            <Route exact path="/:practiceId/patients/new/patient" component={PatientSignUp} />
            <Route exact path="/:practiceId/patients/new/multi" component={MultiPatientSetup} />
            <Route exact path="/:practiceId/patients/new/multi/billing" component={MultiPatientBilling} />
            <Route exact path="/:practiceId/patients/new/multi/:localPatientIndex" component={NewMultiPatient} />
            <Route exact path="/pay" component={StripeRedirect} />
            <Route exact path="/companybilling" component={CompanyBilling} />
            <Route exact path="/thankyou" component={PatientThankYou} />
            <Route exact path="/canceled" component={() => <span>Looks like you canceled. Please click your link again to set up your payment information!</span>} />
            {/* Default */}
            <Redirect to="/" />
          </Switch>
        </Router>
      </Container>
    </div>
  )
}

export default App;
