import { Loader } from '@progress/kendo-react-indicators';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as React from 'react';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { BillingCard } from '../components/BillingCard';
import PlaidACH from '../components/PlaidACH';
import { getStripePublishableKey } from '../config';
import { addCompanyPaymentSource } from '../services/billings-service';

/**
 * This expects the following query params in the url.
 *   1. plaid token
 *   2. stripe customer id
 *   3. company id
 */
const CompanyBilling = ({ location, history }: RouteComponentProps) => {
  const [loading, setLoading] = useState(false);
  const [cardSourceToken, setCardSourceToken] = useState('');
  const [stripePromise] = React.useState(() => loadStripe(getStripePublishableKey()));

  const params = new URLSearchParams(location?.search);
  const customerId = params.get('customerId') || '';
  const plaid = params.get('plaid') || '';
  const companyId = params.get('companyId') || '';

  /**
   * Handles token received event from Plaid.
   * @param tokenId tokenId that was returned from Plaid.
   */
  const handlePlaidTokenReceived = async (tokenId: string) => {
    await addCompanyPaymentSource(companyId, customerId, tokenId);
  }

  /**
   * Handles token received event from Stripe when the card information was submitted.
   * @param tokenId tokenId that was returned from striped.
   */
  const handleCardTokenReceived = async (tokenId: string) => {
    setCardSourceToken(tokenId);
  }

  /**
   * Handles token failed event from Stripe when the card information was submitted.
   */
  const handleCardTokenFailed = async () => {
    toast.error('It looks like there was an issues with that card.');
  }

  /**
   * Saves card payment source as a payment source for the company.
   */
  const saveCardPaymentSource = async () => {
    if (!cardSourceToken) return toast.error('It looks like there was an issues with that card.');

    setLoading(true);
    await toast.promise(addCompanyPaymentSource(companyId, customerId, cardSourceToken), {
      loading: 'Securely saving your card information.',
      success: `Card information saved!`,
      error: e => {
        setLoading(false);
        const msg = 'There was an error saving card info, please try again.'
        const errorMsg = e.response?.data?.error?.toString?.();
        return <p>{msg} {!!errorMsg && <p>{errorMsg}</p>}</p>;
      }
    }, {
      duration: 6000,
    });
    setLoading(false);
    history.push('thankyou');
  }

  return (
    <Row className="flex-column align-items-center h-100 pt-3">
      {loading && <Loader size="large" type="infinite-spinner" />}
      {!loading && <>
        <Row>
          <h1>Select Payment Method</h1>
        </Row>
        <Row>
          <Col style={{ padding: 130, border: '2px white solid' }}>
            <h4>Enter credit card here.</h4>
            <Elements stripe={stripePromise}>
              <BillingCard onTokenReceived={handleCardTokenReceived} onTokenFailed={handleCardTokenFailed} />
            </Elements>
            <Button variant="secondary" onClick={saveCardPaymentSource}>Pay with Credit Card</Button>
          </Col>
          <Col style={{ padding: 130, border: '2px white solid' }}>
            <h4>Select this method to connect your bank account.</h4>
            <PlaidACH
              onTokenReceived={handlePlaidTokenReceived}
              patientId={companyId}
              linkToken={plaid}
              loading={loading}
              setLoading={setLoading} />
          </Col>
        </Row>
      </>}
    </Row>
  );
}

export default withRouter(CompanyBilling);
