import API from '../../services/aero-api';
import { Auth } from 'aws-amplify';
import { PatientProfile } from '../../components/patients/PatientProfile.interface';
import { API_NAMES } from "../../services/api-names";

export const patientsBasePath = '/v1/patients/';
export const billingPath = '/billing';
export const medicalPath = '/medical';
export const familyPath = '/family';
export const socialPath = '/social';
export const historyPath = '/history';

export async function getPatient() {
  const user = await Auth.currentUserInfo();
  const patientId = user.attributes['custom:patientId'];
  console.log('Keen: getPatient -> patientId', patientId)
  const patient: PatientProfile = await API.get(API_NAMES.PATIENTS, `/v1/patients/${patientId}`, {});
  return patient;
}
export async function getPatientMedical(patientId?: string) {
  if (!patientId) throw new Error('Missing patient Id');
  const path = `${patientsBasePath}${patientId}${medicalPath}`;
  return await API.get(API_NAMES.PATIENTS, path, {});
}

export async function getPatientMedicalHistory(patientId?: string) {
  if (!patientId) throw new Error('Missing patient Id');
  const path = `${patientsBasePath}${patientId}${historyPath}${medicalPath}`;
  return await API.get(API_NAMES.PATIENTS, path, {});
}

export async function getPatientSocialHistory(patientId?: string) {
  if (!patientId) throw new Error('Missing patient Id');
  const path = `${patientsBasePath}${patientId}${historyPath}${socialPath}`;
  return await API.get(API_NAMES.PATIENTS, path, {});
}

export async function getPatientFamilyHistory(patientId?: string) {
  if (!patientId) throw new Error('Missing patient Id');
  const path = `${patientsBasePath}${patientId}${historyPath}${familyPath}`;
  return await API.get(API_NAMES.PATIENTS, path, {});
}

export async function getPatientBilling(patientId?: string) {
  if (!patientId) throw new Error('Missing patient Id');
  const path = `${patientsBasePath}${patientId}${billingPath}`;
  return await API.get(API_NAMES.PATIENTS, path, {});
}
