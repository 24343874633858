import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import React, { useEffect } from 'react';
import KeenAuthenticator from './KeenAuthenticator';
import AuthenticatedPortal from './AuthenticatedPortal';
import './portal.scss';

export interface PatientPortalProps { }

const PatientPortal: React.SFC<PatientPortalProps> = () => {
  const [authState, setAuthState] = React.useState<AuthState>();
  const [user, setUser] = React.useState<object | undefined>();


  useEffect(() => {
    document.title = 'Patient Portal';
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return !(authState === AuthState.SignedIn && user)
  ? <div id="keenhp-login-screen" className="h-100 w-100"><KeenAuthenticator /></div>
  : <AuthenticatedPortal />;
}

export default PatientPortal;
