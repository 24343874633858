/** Fax */
export const goToNewFaxBtn = 'goToNewFaxBtn';
// new fax form
export const practiceFaxNumber = 'practiceFaxNumber';
export const toFaxNumber = 'toFaxNumber';
export const senderName = 'senderName';
export const recipientName = 'recipientName';
export const faxSubject = 'faxSubject';
export const faxNotes = 'faxNotes';
export const faxFiles = 'faxFiles';
export const useContact = 'useContact';
