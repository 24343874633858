export function createPatientsUrl(paths?: string[]): string {
  let path = '/patients';
  if (!paths) return path;

  paths.forEach(token => {
    path += `/${token}`;
  });

  return path;
}

export function createNewPatientUrl(): string {
  return '/patients/new';
}

export function getAge(dateString: string): number {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export const trim = (s?: string) => s ? s.trim() : s;

export function buildPatientProfile(values: any, patientId?: string) {
  const address = {
    zipCode: values.zipCode,
    state: values.state,
    city: values.city,
    addressLine1: values.addressLine1,
    addressLine2: values.addressLine2
  }
  const result = {
    practiceId: values.practiceId,
    firstName: values.firstName,
    middleName: values.middleName,
    lastName: values.lastName,
    primaryPhoneNumber: values.phoneNumber,
    email: values.email,
    gender: values.gender,
    genderIdentity: values.allowGenderIdentity ? values.genderIdentity : '',
    pronouns: values.pronouns,
    preferredPharmacy: values.preferredPharmacy,
    dob: values.dob,
    companyId: values.companyId,
    address,
    preferredName: values.preferredName,
    primaryPhoneOwner: values.primaryPhoneOwner,
    emailOwner: values.emailOwner,
    emergencyContact: {
      name: values.emergencyContact?.name,
      phoneNumber: values.emergencyContact?.phoneNumber,
    },
    primaryInsurance: values.primaryInsurance || null,
    secondaryInsurance: values.secondaryInsurance || null,
  } as any;
  if (patientId) result.patientId = patientId;
  if (result.primaryInsurance && result.primaryInsurance?.insured?.relationship?.value) {
    result.primaryInsurance.insured.relationship = result.primaryInsurance.insured.relationship.value;
  }
  return result;
}


export const buildPatientMedical = (patient: { practiceId: string; sk: string }, values: any): any => ({
  patientId: patient.sk,
  practiceId: patient.practiceId,
  allergies: values.allergyList,
  currentMedications: values.currentMedications,
  immunizationUpToDate: values.immunizationUpToDate,
  healthMaintenance: values.healthMaintenance,
});

export const buildPatientMedicalHistory = (patient: { practiceId: string; sk: string }, values: any): any => ({
  patientId: patient.sk,
  practiceId: patient.practiceId,
  patientMedicalHistory: values.medicalHistory,
});

export const buildPatientSocial = (patient: { practiceId: string; sk: string }, values: any): any => ({
  patientId: patient.sk,
  practiceId: patient.practiceId,
  patientSocialHistory: values.social,
})

export const buildPatientFamilyHistory = (patient: { practiceId: string; sk: string }, values: any): any => ({
  patientId: patient.sk,
  practiceId: patient.practiceId,
  patientFamilyHistory: values.familyHistory,
});



/**
 * Calculates the billing age of a patient.
 * If the patient will have a birthday during this invoice period then they'll already be considered to be their new age.
 * @param dateOfBirth the month day year the patient was born in MM/DD/YYYY format.
 * @param paymentFrequency how often the patient will be paying on their subscription.
 */
export const calcBillingAge = (dateOfBirth: string, paymentFrequency: string): number => {
  const frequencyToMonthsMap: { [key: string]: number } = {
    'MONTH': 1,
    'QUARTER': 3,
    'ANNUAL': 12
  };
  const monthsToAdd = frequencyToMonthsMap[paymentFrequency];

  let dateWhenBillingPeriodEnds = new Date();

  // Based on payment frequency add the correct amount of months to todays date.
  // This will allow us to fast forward to see if there is an upcoming birthday during this billing period.
  dateWhenBillingPeriodEnds.setMonth(dateWhenBillingPeriodEnds.getMonth() + monthsToAdd);

  const birthDate = new Date(dateOfBirth);
  const birthMonthDiff = dateWhenBillingPeriodEnds.getMonth() - birthDate.getMonth();
  const birthMonthHasNotPassed = birthMonthDiff < 0;
  const currentMonthIsBirthMonth = birthMonthDiff === 0;
  const birthDay = birthDate.getDate();
  const dayOfBirthHasNotPassed = dateWhenBillingPeriodEnds.getDate() < birthDay;

  let billingAge = dateWhenBillingPeriodEnds.getFullYear() - birthDate.getFullYear();

  // If the birthday hasn't happened yet for this year then subtract one. Since we've already accounted for
  // this billing cycle above this will work out just fine.
  if (birthMonthHasNotPassed || (currentMonthIsBirthMonth && dayOfBirthHasNotPassed)) {
    billingAge = billingAge - 1;
  }

  console.log(`Patients birthday is on ${dateOfBirth.toString()} they will be billed as if they were ${billingAge}`);
  return billingAge;
}

export function getTierForPatient(age: number, products: any[]): string {
  return products.find((product: any) => {
    const minAge = product.minimumTierAge;
    const maxAge = product.maximumTierAge;
    if (!maxAge && age > minAge) return true;
    return age >= minAge && age <= maxAge;
  });
}
