import React from 'react';
import useLanguage from '../../hooks/use-language';
import { signup } from '../../intl/signup';
import { NewPatientFamilyHistory } from './NewPatientFamilyHistory';
import { NewPatientMedical } from './NewPatientMedical';
import { NewPatientMedicalHistory } from './NewPatientMedicalHistory';
import { NewPatientSocialHistory } from './NewPatientSocialHistory';
import SimpleMedHistoryWizard from './SimpleMedHistoryWizard';

interface MedicalHistoryWizardProps {
  practiceId: string;
  patientId: string;
  onSave: () => void;
}


const stepPages: any[] = [
  NewPatientMedicalHistory,
  NewPatientFamilyHistory,
  NewPatientSocialHistory,
  NewPatientMedical
];

const MedicalHistoryWizard: React.FC<MedicalHistoryWizardProps> = (props) => {
  const language = useLanguage();
  const steps = [
    { label: <span className='small-text-on-mobile'>{signup.wizardLabels['Medical History'][language]}</span>, isValid: undefined, icon: 'k-i-heart-outline' },
    { label: <span className='small-text-on-mobile'>{signup.wizardLabels['Family History'][language]}</span>, isValid: undefined, icon: 'k-i-myspace' },
    { label: <span className='small-text-on-mobile'>{signup.wizardLabels['Social History'][language]}</span>, isValid: undefined, icon: 'k-i-tell-a-friend' },
    { label: <span className='small-text-on-mobile'>{signup.wizardLabels['Allergies/Meds'][language]}</span>, isValid: undefined, icon: 'k-i-heart' },
  ];
  return (
    <SimpleMedHistoryWizard
      stepPages={stepPages}
      steps={steps}
      practiceId={props.practiceId}
      patientId={props.patientId}
      onLastSubmit={props.onSave}
    />
  );
}

export default MedicalHistoryWizard;
