import React from 'react';
import { Grid, GridColumn, GridItemChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { FormRenderProps } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { selectors } from '@keenhp/website-common';
import { SupportedLanguages } from '../../hooks/use-language';
import { signup } from '../../intl/signup';

export interface FamilyHistoryDiagnoses {
  diagnosis: string;
  field: string;
  editable: boolean;
};

const familyHistoryField = 'familyHistory';

export const NewPatientFamilyHistory = (formRenderProps: FormRenderProps & { disabled?: boolean }) => {
  const familyData: FamilyHistoryDiagnoses[] = [
    { diagnosis: signup.family.diagnoses['Cancer'][formRenderProps.valueGetter('language') as SupportedLanguages], field: 'cancer', editable: true },
    { diagnosis: signup.family.diagnoses['Heart Disease'][formRenderProps.valueGetter('language') as SupportedLanguages], field: 'heartDisease', editable: true },
    { diagnosis: signup.family.diagnoses['Diabetes'][formRenderProps.valueGetter('language') as SupportedLanguages], field: 'diabetes', editable: true },
    { diagnosis: signup.family.diagnoses['Stroke/TIA'][formRenderProps.valueGetter('language') as SupportedLanguages], field: 'stroke', editable: true },
    { diagnosis: signup.family.diagnoses['High Blood Pressure'][formRenderProps.valueGetter('language') as SupportedLanguages], field: 'highBloodPressure', editable: true },
    { diagnosis: signup.family.diagnoses['High Cholesterol'][formRenderProps.valueGetter('language') as SupportedLanguages], field: 'highCholesterol', editable: true },
    { diagnosis: signup.family.diagnoses['Liver Disease'][formRenderProps.valueGetter('language') as SupportedLanguages], field: 'liverDisease', editable: true },
    { diagnosis: signup.family.diagnoses['Alcohol or Drug Abuse'][formRenderProps.valueGetter('language') as SupportedLanguages], field: 'alcoholOrDrugAbuse', editable: true },
    { diagnosis: signup.family.diagnoses['Anxiety or Depression'][formRenderProps.valueGetter('language') as SupportedLanguages], field: 'anxietyOrDepression', editable: true },
    { diagnosis: signup.family.diagnoses['Tuberculosis'][formRenderProps.valueGetter('language') as SupportedLanguages], field: 'tuberculous', editable: true },
    { diagnosis: signup.family.diagnoses['Anesthesia Complications'][formRenderProps.valueGetter('language') as SupportedLanguages], field: 'anesthesiaComplications', editable: true },
    { diagnosis: signup.family.diagnoses['Genetic Disorder'][formRenderProps.valueGetter('language') as SupportedLanguages], field: 'geneticDisorder', editable: true },
    { diagnosis: signup.family.diagnoses['Other'][formRenderProps.valueGetter('language') as SupportedLanguages], field: 'other', editable: true },
  ];


  // form "state"
  const data: any[] = formRenderProps.valueGetter(familyHistoryField) || familyData;
  const setData = (value: any) => formRenderProps.onChange(familyHistoryField, { value });

  // grid actions
  const itemChange = (event: GridItemChangeEvent) => {
    const diagnosis = event.dataItem.field;
    const newData = data.map(item =>
      item.field === diagnosis ? { ...item, [event.field!]: event.value } : item
    );
    setData([...newData]);
  }
  const addRow = (e: any) => {
    e.preventDefault();
    setData([...data, {
      diagnosis: 'Other',
      field: `extra-other-${data.length - familyData.length}`,
      editable: true,
    }]);
  }
  return (
    <>
      <Grid
        data={data}
        editField="editable"
        onItemChange={itemChange}
        className={selectors.patients.familyHistoryGridClass}
      >
        <GridColumn field="diagnosis" title={signup.family.Diagnosis[formRenderProps.valueGetter('language') as SupportedLanguages]} editable={false} width='200px' locked />
        <GridColumn field="brothers" title={signup.family.Brothers[formRenderProps.valueGetter('language') as SupportedLanguages]} width='100px' editable={!formRenderProps.disabled} editor="boolean" />
        <GridColumn field="sisters" title={signup.family.Sisters[formRenderProps.valueGetter('language') as SupportedLanguages]} width='100px' editable={!formRenderProps.disabled} editor="boolean" />
        <GridColumn field="mother" title={signup.family.Mother[formRenderProps.valueGetter('language') as SupportedLanguages]} width='100px' editable={!formRenderProps.disabled} editor="boolean" />
        <GridColumn field="father" title={signup.family.Father[formRenderProps.valueGetter('language') as SupportedLanguages]} width='100px' editable={!formRenderProps.disabled} editor="boolean" />
        <GridColumn field="grandparents" title={signup.family.Grandparents[formRenderProps.valueGetter('language') as SupportedLanguages]} width='125px' editable={!formRenderProps.disabled} editor="boolean" />
        <GridColumn field="description" title={signup.family.Description[formRenderProps.valueGetter('language') as SupportedLanguages]} editable={!formRenderProps.disabled} />
      </Grid>
      {!formRenderProps.disabled && <GridToolbar>
        <Button onClick={addRow} icon="plus" data-test={selectors.patients.addFamilyHistoryBtn} disabled={formRenderProps.disabled}>
          {signup.addRow[formRenderProps.valueGetter('language') as SupportedLanguages]}
        </Button>
      </GridToolbar>}
    </>
  );
}
