import { UseAsyncReturn } from 'react-async-hook';
import { PatientProfile } from '../../../components/patients/PatientProfile.interface';
import { IPatientSubscription } from '../../services/billings-serivce';

export interface PatientPaymentInfoProps {
  patient?: PatientProfile;
  subscription: UseAsyncReturn<IPatientSubscription>;
  patientBilling: UseAsyncReturn<any>;
}

export enum CreditCardTypes {
  VISA = 'VISA',
  MASTERCARD = 'MasterCard',
  AMEX = 'American Express',
  DISCOVER = 'Discover',
}
