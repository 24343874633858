export const COMMON_DRUGS = [
  'Accuneb',
  'Acetaminophen ',
  'Acuprin',
  'Adderall',
  'Advil',
  'Albuterol ',
  'Alprazolam',
  'Ambien',
  'Amlodipine',
  'Amoxicilin',
  'Amoxil',
  'amphetamine',
  'Amvaz ',
  'Aplenzin',
  'Aspir-81',
  'Aspirin',
  'Atenolol',
  'Atorvastatin',
  'Azithromycin',
  'Basaglar',
  'Bayer Aspirin',
  'Budeprion',
  'Buffex',
  'Buproban',
  'Bupropion',
  'Carvedilol',
  'Cedaprin ',
  'Celexa',
  'Citalopram',
  'Clonazepam',
  'Clopidogrel',
  'Concerta',
  'Coreg',
  'Coumadin',
  'Cozaar ',
  'Crestor',
  'Cutivate ',
  'Cyclobenzaprine',
  'Cymbalta',
  'Deltasone',
  'Desyrel ',
  'Dextroamphetamine',
  'Dispermax',
  'Duloxetine',
  'Ecotrin',
  'Effexor',
  'Escitalopram',
  'Exidrix ',
  'FeverAll',
  'Flexeril',
  'Flomax',
  'Flonase ',
  'Flovent ',
  'Fluoxentine',
  'Fluticasone',
  'Forfivo',
  'Fortamet ',
  'Furosemide ',
  'Gabapentin',
  'Glipizide',
  'Glucophage ',
  'Glucotrol',
  'Glumetza ',
  'Gralise',
  'Horizant',
  'Hycet',
  'Hydrochlorothizaide ',
  'Hydrocodone',
  'Hydrodiuril',
  'Ibuprofen',
  'Inderal',
  'InnoPran',
  'Insulin Glargine',
  'Intermezzo',
  'K-Dur',
  'Katerzia',
  'Klonopin',
  'Klor-Con',
  'Klotrix',
  'Lantus ',
  'Larotid',
  'Lasix ',
  'Levothroid ',
  'Levothyroxine ',
  'Levoxyl',
  'Lexapro',
  'Lipitor',
  'LIsinopril ',
  'Lopressor ',
  'Lorcet',
  'Lortab ',
  'Losartan',
  'Mapap',
  'Maxidone',
  'Meloxicam',
  'Metadate',
  'Metformin',
  'Methylphenidate',
  'Metoprolol ',
  'Micro-K',
  'Microzide',
  'Midol',
  'Mobic',
  'Montelukast',
  'Motrin',
  'Moxtag',
  'NeoProfen',
  'Neurontin',
  'Norco',
  'Norvasc',
  'Ofirmev',
  'Omeprazole ',
  'Oretic',
  'Pantoprazole',
  'Plavix',
  'Polymox ',
  'Potassium',
  'Pravachol',
  'Pravastatin',
  'Prednisone',
  'Prilosec',
  'Prinivil ',
  'Proair',
  'Pronol',
  'Propranolol',
  'Proprinal',
  'Protonix',
  'Proventil ',
  'Prozac',
  'Ranitidine',
  'Rapiflux',
  'Riomet ',
  'Ritalin',
  'Rosuvastatin',
  'Sarafem',
  'Selfemra',
  'Sertraline',
  'Simvastatin',
  'Singulair',
  'Slow-K',
  'Soliqua',
  'Synthroid ',
  'T-Panol',
  'Tamsulosin',
  'Tenormin',
  'Toprol XL ',
  'Toujeo',
  'Tramadol',
  'Trazodone',
  'Trimox ',
  'Tylenol ',
  'Ultram',
  'Unithroid ',
  'Venlafaxine',
  'Ventolin',
  'Vicodin',
  'Vicodin',
  'Warfarin',
  'Wellbutrin',
  'Wymax',
  'Xanax',
  'Xhance',
  'Xodol',
  'Zantac',
  'Zestril ',
  'Zide',
  'Zithromax',
  'Zocor',
  'Zoloft ',
  'Zolpidem',
  'Zorprin',
  'Zyban'
]
