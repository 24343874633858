/**
 * This PatientBillingService class is used to interact with any external services
 * required to get the patient billing feature to work.
 */
import API from './aero-api';
import { PaymentFrequency } from '../components/patients/PaymentFrequency';
import { PaymentMethod } from '../components/patients/PaymentMethods';
import { IAddress } from '../interfaces/Address.interface';
import { SubscriptionPrice } from '../portal/services/billings-serivce';
import { API_NAMES } from './api-names';

/**
 * Creates billing information for this patient.
 * @param practiceId the id of the practice.
 * @param patientId the id of the patient.
 * @param paymentMethod how this patient will make payments.
 * @param patientName name of this patient.
 * @param patientEmail email of this patient.
 * @param paymentSourceToken payment token.
 * @param subscriptionPaymentFrequency how often this patient will pay their subscription.
 * @param payingPatientId the id of the patient responsible for paying this patients bills.
 * @param payingPatientCustomerId the customer id of the patient responsible for paying this patients bills.
 */
export async function createPatientBilling(practiceId: string, patientId: string, patientName: string, patientEmail: string,
  paymentMethod: PaymentMethod, paymentSourceToken: string, payingPatientId: string,
  subscriptionPaymentFrequency: PaymentFrequency, payingPatientCustomerId?: string) {

  const myInit = {
    body: {
      patientId, paymentMethod, patientName, patientEmail,
      paymentSourceToken, subscriptionPaymentFrequency, payingPatientId,
      payingPatientCustomerId
    }
  };

  const path = `/v1/practices/${practiceId}/patients/${patientId}/billing`;
  const response = await API.post(API_NAMES.PATIENTS_EXTERNAL, path, myInit);
  return response;
}


/**
 * Exchange Plaid public token for a stripe token.
 * @param practiceId the practiceID to get the prices from
 * @param patients the list of patients to get the
 */
export async function getBillingTiersForDOBs(practiceId: string, dobs: string[]) {
  const myInit = { body: { dobs } };
  const path = `/v1/billings/open/practices/${practiceId}/subscription/cost`;
  return await API.post(API_NAMES.BILLINGS, path, myInit);
}


// Interfaces

export interface PayingPatient {
  patientId: string;
  customerId: string;
  patientFirstName: string;
  patientLastName: string;
  patientFullName: string;
  subscriptionPaymentFrequency: string;
}

export interface IPatientInvoices {
  outstandingBalance: number;
  invoices: IPatientInvoice[];
}

export interface IPatientInvoice {
  id: string;
  amountDue: number;
  amountPaid: number;
  amountRemaining: number;
  hasBeenPaid: boolean;
  isDelinquent: boolean;
  paymentSourceId: string;
  invoicePdfUrl: string;
  stripeStatus: string;
  paymentSource: IPaymentSource;
}

export interface IPatientPaymentSource {
  customerName: string;
  paymentSources: IPaymentSource[];
}

export interface IPaymentSource {
  sourceType: PaymentSourceType;
  id: string;
}

export interface ICardSource extends IPaymentSource {
  brand: string;
  expMonth: number;
  expYear: number;
  addressZip: string;
  last4: string;
}

export interface IBankAccountSource extends IPaymentSource {
  last4: string;
  accountHolderName: string;
  accountHolderType: string;
  bankName: string;
  routingNumber: string;
}

export interface IPatientSubscription {
  id: string;
  collectionMethod: string;
  createdDate: number;
  currentPeriodEndDate: number;
  currentPeriodStartDate: number;
  customerId: string;
  subscriptionPaymentFrequency: PaymentFrequency;
  totalSubscriptionCost: SubscriptionPrice;
  status: string;
}

export interface IPatientInvoices {
  outstandingBalance: number;
  invoices: IPatientInvoice[];
}

export interface IPatientInvoice {
  id: string;
  amountDue: number;
  amountPaid: number;
  amountRemaining: number;
  hasBeenPaid: boolean;
  isDelinquent: boolean;
  paymentSourceId: string;
  invoicePdfUrl: string;
  stripeStatus: string;
  paymentSource: IPaymentSource;
  paidAtTimestamp: number;
  details?: IPatientInvoiceItem[]; // UI only
}

export interface ISalesItem {
  isInventoryItem?: boolean; //UI only
  id?: number; // UI only
  genericName?: string; // UI only
  inventoryId?: string;
  itemName: string;
  quantity: number;
  price: number;
  pricePerUnit?: number; // UI only
  quantityInStock?: number; // UI only
  priceOverridden?: boolean; // UI only
  nameIsValid?: boolean; // UI only
  quantityIsValid?: boolean; // UI only
  priceIsValid?: boolean; // UI only
}

export interface IPatientInvoiceItem {
  invoiceItemId: string;
  description: string;
  amount: number;
  timestamp: number;
  relatedChargeItemId?: string;
  isSubscriptionItem: boolean;
  isReversing: boolean; // UI only
}

// Enums

// Enum for the different payment source types.
export enum PaymentSourceType {
  BANKACCOUNT = 'BANKACCOUNT',
  CARD = 'CARD',
}



export const patientsBasePath = (pid: string) => `/v1/practices/${pid}/patients`;
export const medicalPath = '/medical';
export const familyPath = '/family';
export const socialPath = '/social';
export const historyPath = '/history';

export interface BasePatientInformation {
  practiceId: string;
  patientId: string;
  data: any;
}


/** PatientMedicalHistory **/
interface PatientMedicalHistoryData {
  diagnosis: string;
  description?: string;
  date?: Date;
}
export interface SavePatientMedicalHistoryRequest extends BasePatientInformation {
  data: {
    medicalHistory: PatientMedicalHistoryData[];
  }
}

/** PatientMedical */
export interface Allergy { allergy: string; description: string; isAllergic?: string }
interface Substance { inUse: boolean; description?: string; substance: string }
export interface SavePatientMedicalRequest extends BasePatientInformation {
  data: {
    allergies: Allergy[];
    currentMedications: string[];
    healthMaintenance: { name: string; date: string; }[];
    immunizations: { immunization: string; immunizationDate: string; }[];
    immunizationUpToDate: boolean;
  }
};

export interface SavePatientSocialHistoryRequest extends BasePatientInformation {
  data: {
    currentJob?: string;
    relationshipStatus: 'SINGLE' | 'MARRIED' | 'DIVORCED' | 'RELATIONSHIP'
    sexuallyActive?: boolean;
    sexualOrientation?: 'HETEROSEXUAL' | 'HOMOSEXUAL' | 'BISEXUAL' | 'OTHER'
    substances?: Substance[];
  }
};

export interface SavePatientFamilyHistoryRequest extends BasePatientInformation {
  data: {
    [key: string]: boolean | PatientMedicalHistoryData;
  };
};

export interface SavePatientProfileResponse {
  practiceId: string;
  sk: string;
  firstName: string;
  lastName: string;
  primaryPhoneNumber: string;
  email: string;
  gender: string;
  genderIdentity: string;
  preferredPharmacy: string;
  dob: string;
  address: string;
  preferredName?: string;
  primaryPhoneOwner?: string;
  emailOwner?: string;
};


export interface UpdatePatientProfileRequest {
  patientId: string;
  firstName?: string;
  lastName?: string;
  primaryPhoneNumber?: string;
  email?: string;
  gender?: string;
  preferredPharmacy?: string;
  dob?: string;
  address?: IAddress;
  preferredName?: string;
  primaryPhoneOwner?: string;
  emailOwner?: string;
  emergencyContact?: {
    name: string;
    phoneNumber: string;
  }
};


export async function savePatientProfile(practiceId: string, patientProfile: any) {
  return await API.post(API_NAMES.PATIENTS_EXTERNAL, patientsBasePath(practiceId), { body: patientProfile });
}

// convert to req allergies
export const convertAllergiesToReq = (allergies: Allergy[]): Allergy[] => {
  if (!allergies) return [];
  const data = allergies
    .map(({ allergy, isAllergic, description }) => isAllergic ? { allergy, description } : undefined)
    .filter(x => !!x);
  return data as any;
}

// remove duplicates
export const convertCurrentMedicationsToReq = (currentMedications: any[]) => Array
  .from(new Set<string>(currentMedications));

export const convertHealthMaintenanceToReq = (immunizations: any[]) => immunizations
  .map(({ healthMaintenance, date }) => date ? ({ name: healthMaintenance, date }) : undefined)
  .filter(x => !!x);

// save medical doesn't have the description in the health maintenance
export async function savePatientMedical({ practiceId, patientId, allergies, currentMedications, immunizationUpToDate, healthMaintenance }: any) {
  if ((!allergies || allergies.length === 0) &&
    (!currentMedications || currentMedications.length === 0) &&
    !immunizationUpToDate &&
    !healthMaintenance) {
    return;
  }
  const request = {
    practiceId,
    patientId,
    data: {
      immunizationUpToDate,
      allergies: convertAllergiesToReq(allergies),
      currentMedications: convertCurrentMedicationsToReq(currentMedications),
      healthMaintenance: healthMaintenance ? convertHealthMaintenanceToReq(healthMaintenance) : undefined,
    }
  };

  const path = `${patientsBasePath(practiceId)}/${patientId}${medicalPath}`;
  return await API.post(API_NAMES.PATIENTS_EXTERNAL, path, { body: request });
}

export async function savePatientMedicalHistory({ practiceId, patientId, patientMedicalHistory }: any) {
  const data = patientMedicalHistory
    ? patientMedicalHistory.filter((x: PatientMedicalHistoryData) => x && !!x.date)
    : [];

  if (data.length === 0) return;
  const request: SavePatientMedicalHistoryRequest = { practiceId, patientId, data: { medicalHistory: data } };

  const patientMedicalHistoryPath = `${patientsBasePath(practiceId)}/${patientId}${historyPath}${medicalPath}`;
  return await API.post(API_NAMES.PATIENTS_EXTERNAL, patientMedicalHistoryPath, { body: request });
}


export const convertSubstances = (substances: any): Substance[] => {
  if (!substances) return [];
  const result: Substance[] = [];
  Object.keys(substances).forEach((substance: any) => {
    if (substance === 'packsPerDay') {
      result.push({
        inUse: !!substances[substance],
        substance: substance,
        description: `${substances[substance]}`,
      });
    } else if (substance === 'quit') {
      result.push({
        inUse: !!substances[substance],
        substance: substance,
        description: substances[substance]?.toLocaleDateString?.(),
      });
    } else {
      result.push({
        inUse: !!substances[substance],
        substance: substance,
      });
    }
  });
  return result;
}

export const unConvertSubstances = (substances: Substance[]) => {
  console.log('Keen: unConvertSubstances -> substances', substances)
  if (!substances) return [];
  const result: any = {};
  substances.forEach(sub => {
    if (sub.inUse) {
      if (sub.substance === 'quit') {
        result[sub.substance] = sub.description
          ? sub.description.length === 7
            ? sub.description
            : new Date(sub.description).toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
          : null;
      } else if (sub.substance === 'packsPerDay') {
        result[sub.substance] = sub.description ? parseInt(sub.description) : null;
      } else result[sub.substance] = sub.inUse;
    }
  });
  console.log('Keen: unConvertSubstances -> result', result)
  return result;
}

export async function savePatientSocialHistory({ practiceId, patientId, patientSocialHistory }: any) {
  const request: SavePatientSocialHistoryRequest = {
    practiceId,
    patientId,
    data: {
      currentJob: patientSocialHistory?.occupation,
      relationshipStatus: patientSocialHistory?.relationshipStatus,
      substances: convertSubstances(patientSocialHistory?.substances),
    }
  }
  if (!request.data.currentJob && !request.data.relationshipStatus && request.data.substances?.length === 0) return;

  const patientSocialHistoryPath = `${patientsBasePath(practiceId)}/${patientId}${historyPath}${socialPath}`;
  return await API.post(API_NAMES.PATIENTS_EXTERNAL, patientSocialHistoryPath, { body: request });
}


const cleanMedicalHistory = (medHistory: any) => medHistory ? medHistory.map((mh: any) => {
  delete mh.editable;
  delete mh.diagnosis;
  if (Object.keys(mh).length > 1) return { ...mh }; // if there is a family member with the diagnosis
  return undefined;
}).filter((x: any) => !!x) : [];

export async function savePatientFamilyHistory({ practiceId, patientId, patientFamilyHistory }: any) {
  const data = cleanMedicalHistory(patientFamilyHistory);
  if (data.length === 0) return;
  const request: SavePatientFamilyHistoryRequest = {
    practiceId,
    patientId,
    data: {
      familyMedicalHistory: data,
    },
  }

  const patientFamilyHistoryPath = `${patientsBasePath(practiceId)}/${patientId}${historyPath}${familyPath}`;
  return await API.post(API_NAMES.PATIENTS_EXTERNAL, patientFamilyHistoryPath, { body: request });
}
