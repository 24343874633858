/**
 * This component is used to capture credit card information.
 * This had to be in its own component so that useElements doesn't need to be wrapped.
 */
import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { PatientBillingCardProps } from './PatientBillingCardProps.interface';
import './PatientBilling.scss';

const poweredBy = require('../../assets/powered_by_stripe.png');

export const PatientBillingCard = (props: PatientBillingCardProps) => {
  const elements = useElements();
  const stripe = useStripe();

  /**
   * Handles the card element change. It fires with each key stroke entered into the card
   * element. Once all information has been entered then complete will come back as true.
   * @param e the StripeCardElementChangeEvent
   */
  const handleCardElementOnChange = async (e: StripeCardElementChangeEvent) => {

    if (!stripe || !elements) return;

    // Get a reference to a mounted CardElement. Elements knows how
    // to find the CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    if (cardElement != null && e.complete) {
      const { token, error } = await stripe.createToken(cardElement);

      if (error) {
        props.onTokenFailed(error);
      } else if (token) {
        props.onTokenReceived(token.id);
      }
    }
  }

  return (
    <div>
      <CardElement
        onChange={handleCardElementOnChange}
        options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }}
      />
      <img className="poweredBy" src={poweredBy} alt="powered by stripe" />
    </div>
  );
}
