import * as React from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import PlaidACH from '../components/PlaidACH';
import { addPaymentSource } from '../services/billings-service';
import { getStripePublishableKey } from '../config';
import { ReactComponent as BankLogo } from '../assets/bank.svg';
import { ReactComponent as CreditCard } from '../assets/credit-card.svg';
import { Button } from '@progress/kendo-react-buttons';

const Stripe = (window as any).Stripe;

export type PatientPayProps = RouteComponentProps<{ practiceId: string }> & {}

/**
 * This expects 2 query params in the url.
 *   1. stripeAccount -- the practice's stripe account id
 *   2. the session id generated by the practice user when they clicked "send this user a link" button
 *   3. plaid token
 *   4. stripe customer id
 *   5. patient id
 */
const PatientPay = ({ location }: PatientPayProps) => {
  const [loading, setLoading] = useState(false);

  const params = new URLSearchParams(location?.search);

  const customerId = params.get('customerId') || '';
  const patientId = params.get('patientId') || '';
  const plaid = params.get('plaid') || '';
  const sessionId = params.get('sessionId');
  const stripeAccount = params.get('stripeAccount');

  const redirectToStripe = () => {
    try {
      // TODO pull these IDs used on the frontend out into their own packages.
      var stripe = Stripe(getStripePublishableKey(), {
        stripeAccount
      });
      stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error(error);
      toast.error('There was an error with the link, please try again, or contact your provider for a new link');
    }
  }

  const handleOnTokenReceived = async (response: any) => {
    // add the ACH payment source
    await addPaymentSource(patientId, customerId, response);
  }

  return (
    <Row className="h-100 pt-3">
      {loading && <Loader size="large" type="infinite-spinner" />}
      {!loading && <>
        <Col xs={12}>
          <h2 className="text-center">Update your payment method</h2>
        </Col>
        {plaid && <Col xs={12} md style={{ padding: 130, border: '2px white solid' }}>
          <div className="d-flex align-items-center justify-content-center flex-column">
            <BankLogo style={{ maxWidth: 300 }} />
            <PlaidACH onTokenReceived={handleOnTokenReceived} patientId={patientId} linkToken={plaid} loading={loading} setLoading={setLoading} />
          </div>
        </Col>}
        {sessionId && <Col xs={12} md style={{ padding: 130, border: '2px white solid' }}>
          <div className="d-flex align-items-center justify-content-center flex-column">
            <CreditCard style={{ maxWidth: 300 }} />
            <Button look="flat" onClick={redirectToStripe}>Pay with Credit Card</Button>
          </div>
        </Col>}
        {!plaid && !sessionId && <Col>
          <h4>There was an error with this link, please contact your provider for another one</h4>
        </Col>}
      </>}
    </Row>
  );
}

export default withRouter(PatientPay);
