import React from 'react';
import { SvgIconInterface } from '../AeroLogo';

const DownloadIcon = ({ fill, height, width}: SvgIconInterface) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <path fill={fill} d="M21 16a1 1 0 01.993.883L22 17v2a3 3 0 01-2.824 2.995L19 22H5a3 3 0 01-2.995-2.824L2 19v-2a1 1 0 011.993-.117L4 17v2a1 1 0 00.883.993L5 20h14a1 1 0 00.993-.883L20 19v-2a1 1 0 011-1zM12 2a1 1 0 011 1v9.585l1.293-1.292a1 1 0 011.32-.083l.094.083a1 1 0 01.083 1.32l-.083.094-3 3-.044.042-.068.055-.11.071-.114.054-.105.035-.149.03L12 16l-.075-.003-.126-.017-.111-.03-.111-.044-.098-.052-.096-.067a1.006 1.006 0 01-.09-.08l-3-3a1 1 0 011.32-1.497l.094.083L11 12.585V3a1 1 0 011-1z"/>
  </svg>
)

export default DownloadIcon;
