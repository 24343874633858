import { API, Auth } from 'aws-amplify';
import { log } from './logger';

/**
 * A helper function to get and or refresh the auth token.
 */
export async function getHeader() {
  try {
    const user = await Auth.currentSession();
    const token = user.getIdToken().getJwtToken();
    return { Authorization: token };
  } catch (error) {
    return {};
  }
}

const AeroApi = {
  get: (apiName: string, path: string, request?: any) => getHeader()
    .then(headers => API.get(apiName, path, { ...request, headers }))
    .catch(catchAndLog),
  post: (apiName: string, path: string, request?: any) => getHeader()
    .then(headers => API.post(apiName, path, { ...request, headers }))
    .catch(catchAndLog),
  put: (apiName: string, path: string, request?: any) => getHeader()
    .then(headers => API.put(apiName, path, { ...request, headers }))
    .catch(catchAndLog),
  del: (apiName: string, path: string, request?: any) => getHeader()
    .then(headers => API.del(apiName, path, { ...request, headers }))
    .catch(catchAndLog),
};

const catchAndLog = (e: any) => {
  // log the response if it is there, otherwise the whole error will do
  log.error(e?.response || e);
  throw e;
};

export default AeroApi;
