import { Loader, LoaderProps } from '@progress/kendo-react-indicators';
import React from 'react';
import { Row } from 'react-bootstrap';

export interface KeenSpinnerProps extends LoaderProps {
  outerClasses?: string;
}

const KeenSpinner: React.SFC<KeenSpinnerProps> = (props) => (
  <Row className="h-100 w-100 justify-content-center align-items-center">
    <Loader {...props} type={props.type || 'infinite-spinner'} size={props.size || 'large'} />
  </Row>
)

export default KeenSpinner;
