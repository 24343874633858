import { Button } from '@progress/kendo-react-buttons';
import { Cache } from 'aws-amplify';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Card, CardTitle, CardBody, CardActions } from '@progress/kendo-react-layout';

export const PATIENT_COMPANY_INFO = 'keenhp.new.patient-company-info';
export type PatientSignUpLandingProps = RouteComponentProps<{ practiceId: string }> & {}

const PatientSignUpLanding: React.SFC<PatientSignUpLandingProps> = ({ history, location, match }) => {
  const practiceId: string = match.params.practiceId;

  const params = new URLSearchParams(location?.search);
  const companyId = params.get('companyId') || '';
  const companyName = params.get('companyName') || '';
  const multiLink = `/${practiceId}/patients/new/signup`;

  useEffect(() => {
    if (!companyId) history.push(multiLink);
    // eslint-disable-next-line
  }, [companyId, multiLink]);

  Cache.setItem(PATIENT_COMPANY_INFO, { companyId, companyName });

  if (companyId && companyName) {
    return (
      <Container>
        <Row className="py-4">
          <Col>
            <Card className="w-100 h-100">
              <CardBody>
                <CardTitle>
                  Employee Registration
                </CardTitle>
                <p>It looks like you are trying to register with a practice as an employee of {companyName}.</p>
                <p>If that is that correct click the button below.</p>
              </CardBody>
              <CardActions>
                <Button className="w-100" onClick={() => history.push(multiLink + (companyId ? `?companyId=${companyId}&companyName=${companyName}` : ''))}>
                  Click here for employee Registration
                </Button>
              </CardActions>
            </Card>
          </Col>
          <Col>
            <Card className="w-100 h-100">
              <CardBody>
                <CardTitle>
                  Normal Registration
                </CardTitle>
                <p>
                  If you just want to register without being associated to a company click the red button below.
                </p>
              </CardBody>
              <CardActions>
                <Button className="w-100 btn btn-outline-danger" onClick={() => {
                  Cache.setItem(PATIENT_COMPANY_INFO, false);
                  history.push(multiLink);
                }}>
                  Click here for normal Registration
                </Button>
              </CardActions>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
  return null;
}

export default withRouter(PatientSignUpLanding);
