import React from 'react';
import { UseAsyncReturn } from 'react-async-hook';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PatientProfile } from '../components/patients/PatientProfile.interface';
import PatientBilling from './pages/PatientBilling';
import PatientChart from './pages/PatientChart';
import ViewPatient from './pages/ViewPatient';

export interface RoutesProps {
  patient: UseAsyncReturn<PatientProfile, never[]>
}

const Routes: React.SFC<RoutesProps> = (props) => {
  const patient = props.patient.result;

  return (
    <Switch>
      <Route exact path="/portal/chart">
        <PatientChart patient={patient} />
      </Route>
      <Route exact path="/portal/billing">
        <PatientBilling patient={patient} />
      </Route>
      <Route path="/portal/:tabName">
        <ViewPatient patient={patient}/>
      </Route>
      <Redirect to="/portal/profile" />
    </Switch>
  );
}

export default Routes;
