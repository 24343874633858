import { Form, FormElement } from '@progress/kendo-react-form';
import React from 'react';
import { useAsync } from 'react-async-hook';
import { Row } from 'react-bootstrap';
import AeroError from '../../../components/AeroError';
import KeenSpinner from '../../../components/KeenSpinner';
import { NewPatientSocialHistory } from '../../../components/patients/NewPatientSocialHistory';
import { getPatientSocialHistory } from '../../services/patients-service';
import { formatSocialHistory } from './converters';

export interface ViewMedicalProps {
  patientId?: string;
}

const ViewMedical: React.SFC<ViewMedicalProps> = (props) => {
  const req = useAsync(getPatientSocialHistory, [props.patientId]);

  if (req.loading) return <KeenSpinner />;
  if (req.error) return <AeroError error={req.error} />;

  return (
    <Row className="w-100 p-3 align-items-center justify-content-center">
      <Form
        initialValues={formatSocialHistory(req.result)}
        render={formRenderProps => (
          <FormElement>
            <NewPatientSocialHistory {...formRenderProps} disabled />
          </FormElement>
        )}
      />
    </Row>
  );
}

export default ViewMedical;
