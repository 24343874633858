import * as common from './common';
import * as contacts from './contacts';
import * as messages from './messages';
import * as navBar from './nav';
import * as notes from './notes';
import * as patients from './patients';
import * as settings from './settings';
import * as toast from './toast';
import * as templates from './templates';

export const selectors = {
  common,
  contacts,
  messages,
  navBar,
  notes,
  patients,
  settings,
  toast,
  templates,
};
