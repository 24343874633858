import React from 'react';
import { useAsync } from 'react-async-hook';
import AeroError from '../components/AeroError';
import KeenSpinner from '../components/KeenSpinner';
import NavBar from './NavBar';
import Routes from './Routes';
import { getPatient } from './services/patients-service';
import { getPatientName } from './util';

export interface AuthenticatedPortalProps {}

const AuthenticatedPortal: React.SFC<AuthenticatedPortalProps> = () => {
  const patient = useAsync(getPatient, []);

  if (patient.loading) return <KeenSpinner />;
  if (patient.error) return <AeroError error={patient.error} />;

  const patientName = getPatientName(patient.result);

  return (
    <div className="w-100 h-100">
      <NavBar name={patientName} />
      <div style={{height: 'calc(100% - 42px)'}}>
        <Routes patient={patient} />
      </div>
    </div>
  );
}

export default AuthenticatedPortal;
